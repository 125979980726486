import { useState } from "react";
import { useGIA } from "../../contexts/GIAContext";
import * as SC from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";
import { LiveConnection } from "../EnumsAndTypes/EnumsAndTypes";

export default function TalkLiveButton() {
  const { connected, handleConnect, clickGIA, visible } = useGIA();
  const [connect, setConnect] = useState(true);

  const showConnecting = () => {
    if (connected === LiveConnection.Connecting || connected === LiveConnection.Disconnecting)
      return true;
    return false;
  };

  const transition = {
    ease: "linear",
    repeatType: "loop" as any,
    repeat: Infinity,
    duration: 0.75,
  };

  const setConnectingText = () => {
    if (connected === LiveConnection.Connected) return "Connected";
    if (connected === LiveConnection.Connecting) return "Connecting ...";
    if (connected === LiveConnection.Disconnected) return "Talk to a live expert";
    if (connected === LiveConnection.Disconnecting) return "Disconnecting ...";
  };

  return (
    <SC.Wrapper
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => {
        if (!visible) clickGIA();
        setConnect(!connect);
        handleConnect(!connect);
      }}
    >
      <SC.IconWrapper
        $showConnecting={showConnecting()}
        animate={{ rotate: 360 }}
        transition={transition}
        whileHover={{ textDecoration: "underline" }}
      >
        <Icon name={IconNames.openCircle} size={24} stroke={1.5} theme={"blueButton"} />
      </SC.IconWrapper>
      {setConnectingText()}
      <div
        style={{
          height: 10,
          width: 1,
          background: "rgba(0,0,255,0.2)",
          display: connected === LiveConnection.Connected ? "block" : "none",
        }}
      />
      <SC.XIconWrapper $show={connected === LiveConnection.Connected}>
        <Icon name={IconNames.x} size={22} stroke={1.5} theme={"blueButton"} pointer={true} />
      </SC.XIconWrapper>
    </SC.Wrapper>
  );
}

//// *************
//// *************
interface Props {
  showGIA?: boolean;
  onClick?: () => void;
}

export function DrawerTalkLiveButton(props: Props) {
  const { onClick = () => null } = props;
  return (
    <SC.Wrapper whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.98 }} onClick={() => onClick()}>
      Talk to a live expert
    </SC.Wrapper>
  );
}
