import { Routes, Route } from "react-router-dom";
import ADPNoMSA from "./pages/ADPNoMSA/ADPNoMSA";
import ADPWithMSA from "./pages/ADPDashBoard/ADPWithMSA";
import ContractViewer from "./pages/Contract/Contract";

export function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ADPNoMSA />} />
        <Route path="/with-msa" element={<ADPWithMSA />} />
        <Route path="/no-msa" element={<ADPNoMSA />} />
        <Route path="/contract" element={<ContractViewer />} />
      </Routes>
    </>
  );
}

export default App;
