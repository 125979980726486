import * as Styled from "./Styles";
import { Product } from "../../../assets/images/Products";
import QuoteCard from "../../QuoteCard/QuoteCard";
import QuoteCards from "./QuoteData";
import MoreInfoButton from "../../MoreInfoButton/MoreInfoButton";

type Info = {
  tit?: string;
  text?: string;
};

enum CostType {
  Base,
  Optional,
  Other,
}

type CardInfo = {
  type?: CostType;
  name?: string;
  value?: string;
  valuetype?: string;
  valuequalifyer?: string;
  strikeout?: boolean;
  image?: Product;
  description?: string;
  valueprops?: string[];
  disclaimer?: string;
  maxvalueprop?: number;
  info?: Info;
};

export default function QuoteResult() {
  return (
    <Styled.CardsContainer>
      <Styled.Disclaimer>
        Note: Prices are approximate and include G-P fees only. Your total cost of employment will
        vary based on the compensation package you agree to with each individual plus costs related
        to compliant employment in each country.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Disclaimer>
      <Styled.CardsWrapper>
        <Styled.QuoteSection>
          <Styled.SectionTitle>Base Setup</Styled.SectionTitle>
          <Styled.SectionDescription>
            Based on your goals and specific crieteria, we propose the following G-P Meridian
            Products configuration:
          </Styled.SectionDescription>
        </Styled.QuoteSection>
        {QuoteCards.filter((card: CardInfo) => {
          return card.type === CostType.Base;
        }).map((card: CardInfo, i: number) => {
          return (
            <QuoteCard
              key={card.name + "_" + i}
              image={card.image}
              name={card.name}
              value={card.value}
              valuequalifyer={card.valuequalifyer}
              valuetype={card.valuetype}
              valueprops={card.valueprops}
              description={card.description}
              disclaimer={card.disclaimer}
              strikeout={card.strikeout}
              info={card.info}
              clickInfo={(info) => info}
            />
          );
        })}
      </Styled.CardsWrapper>
    </Styled.CardsContainer>
  );
}

// *** options add ins section removed for now
/*
<Styled.CardsWrapper>
<Styled.QuoteSection>
  <Styled.SectionTitle>Options / Add-ons</Styled.SectionTitle>
  <Styled.SectionDescription>
    We also suggest the following optional services and add-ons:
  </Styled.SectionDescription>
</Styled.QuoteSection>
{QuoteCards.filter((card: CardInfo) => {
  return card.type !== CostType.Base;
}).map((card: CardInfo, i: number) => {
  return (
    <QuoteCard
      key={card.name + "_" + i}
      image={card.image}
      name={card.name}
      value={card.value}
      valuequalifyer={card.valuequalifyer}
      valuetype={card.valuetype}
      valueprops={card.valueprops}
      description={card.description}
      disclaimer={card.disclaimer}
      strikeout={card.strikeout}
      info={card.info}
      clickInfo={(info) => null}
    />
  );
})}
</Styled.CardsWrapper>
*/
