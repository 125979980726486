import * as Styled from "./Styles";
import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import video_poster from "../../assets/images/video_poster@2x.png";

export default function GetStarted() {
  const { handleTriggerChat } = useNoMSADrawer();

  const infoMessage: Array<ChatMessage> = [
    {
      message: {
        title: "More about G-P and EOR",
        text: "An Employer of Record (EOR) is a third-party service that hires employees on behalf of your company in countries where you don't have a legal entity established. As your EOR, we are responsible for all legal and regulatory requirements, including employment contracts, payroll, taxes, benefits, and compliance with local labor laws.",
      },
      sender: MessageSender.Info,
    },
    {
      message: {
        title: null,
        text: "Do you have specific questions about G-P and EOR that I could help answer?",
      },
      sender: MessageSender.Info,
    },
  ];

  return (
    <Styled.Wrapper>
      <Styled.Title>Accelerate Your Global Expansion with EOR.</Styled.Title>
      <Styled.SubTitle>
        Effortlessly hire and manage international teams in countries where you have no legal
        entity.
      </Styled.SubTitle>
      <Styled.Description style={{ whiteSpace: "pre-wrap" }}>
        {
          "G-P's employer of record (EOR) services allows your business to hire and manage international employees without a legal entity in country.\n\nWith our EOR service, we act as your international employees' legal employer for tax, benefits, and compliance purposes. We'll handle the complexities of international employment payroll, taxes, and legal compliance, so you can focus on growing your business."
        }
        <MoreInfoButton triggerChat={() => handleTriggerChat(infoMessage)} />
      </Styled.Description>
    </Styled.Wrapper>
  );
}
