// break points
const formfactor = {
  phone: "480px",
  tablet: "768px",
  desktop: "1366px",
  large: "1488px",
  fix: { min: "768px", max: "980px" },
};
export const device = {
  phoneUp: `(min-width: ${formfactor.phone})`,
  tabletOnly: `(min-width: ${formfactor.phone}) and (max-width:${formfactor.tablet})`,
  tabletUp: `(min-width: ${formfactor.tablet})`,
  desktopUp: `(min-width: ${formfactor.desktop})`,
  largeUp: `(min-width: ${formfactor.large})`,
  fixSmallDesktop: `(min-width: ${formfactor.fix.min}) and (max-width:  ${formfactor.fix.max})`,
};

// type system
export const type = {
  h2: `font-family: "GPPolySans-Median";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.24px;
  padding: 0;
  margin: 0;`,
  h3: `font-family: "GPPolySans-Neutral";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.24px;
  padding: 0;
  margin: 0;`,
  pLarge: `font-family: "BasierCircle-Regular";
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pLargeBold: `font-family: "BasierCircle-Medium";
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pMedium: `font-family: "BasierCircle-Regular";
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pMediumBold: `font-family: "BasierCircle-Medium";
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pRegular: `font-family: "BasierCircle-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pRegularBold: `font-family: "BasierCircle-Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pSmall: `font-family: "BasierCircle-Regular";
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  caption: `font-family: "BasierCircle-Medium";
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  btnRegular: `
  font-family: GPPolySans-Neutral;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.42px;`,
  sectionTitle: `font-family: "BasierCircle-Medium";
  text-transform:uppercase;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 0;
  margin: 0;`,
  pGlyph: `font-family: GPIcon-Regular;`,
  pBoldFace: `font-family: "BasierCircle-Medium";`,
};

// color system
export const colors = {
  black: "rgba(0,0,0,1)",
  black85: "rgba(0,0,0,0.85)",
  graysSystem: "#202124",
  grays700: "#8A9AA8",
  grays600: "#A7B3BE",
  grays500: "#C2CCD6",
  grays400: "#dae0e7",
  grays300: "#E3E8ED",
  grays200: "#EDF0F3",
  grays100: "#F4F4F6",
  graysWhite: "#fff",
  graysWhite100: "rgba(225, 255, 255, 0)",
  graysWhite50: "rgba(225, 255, 255, 0.5)",
  graysWhite45: "rgba(225, 255, 255, 0.45)",
  graysWhite40: "rgba(225, 255, 255, 0.4)",
  graysWhite35: "rgba(225, 255, 255, 0.35)",
  graysWhite30: "rgba(225, 255, 255, 0.3)",
  graysWhite25: "rgba(225, 255, 255, 0.25)",
  graysWhite20: "rgba(225, 255, 255, 0.2)",
  graysWhite15: "rgba(225, 255, 255, 0.15)",
  graysWhite10: "rgba(225, 255, 255, 0.1)",
  graysTextPrimary: "#272e35",
  grayTextSecondary: "#3D4752",
  graysTextTertiary: "#52616F",
  graysTextDisabled: "#8A9AA8",
  graysButton400: "#66717F",
  primary600: "#001699",
  primary900: "#000F6A",
  primary600a30: "rgba(0,22,153,0.3)",
  primaryGPBlue: "#0000ff",
  primary400: "#4084ff",
  tertiary500: "rgba(0, 237, 255, 1)",
  warning300: "#CE9D00",
  warning300a30: "rgba(206,157,0,0.3)",
  warning300a20: "rgba(206,157,0,0.2)",
  warning300a10: "rgba(206,157,0,0.1)",
  warning200: "#FFC200",
  skyPrimary: "#00EDFF",
  green500: "#56C900",
  green100: "#D5F1BF",
  error: "#E61000",
  errorBG: "#FFE1DF",
  ADPRed: "rgb(238,39,34)",
};

// motion system
export const transitions = {
  spring: { type: "spring", stiffness: 500, damping: 24, mass: 1 },
  easeInOut: { ease: "easeInOut" },
  easIn: { ease: [0.95, 0.02, 0.56, 1] },
  themeChange: { ease: [0.95, 0.02, 0.56, 1], delay: 0.4, duration: 0.35 },
  linear: { ease: "linear" },
};
