import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import Icon, { IconNames } from "../../assets/icons/Icons";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import { Direction, NoMSADrawerAction } from "../EnumsAndTypes/EnumsAndTypes";

export default function ContactUs() {
  const { handleDrawerAction } = useNoMSADrawer();
  return (
    <Styled.Wrapper>
      <Styled.NavSection>
        <Styled.NavButton
          onClick={() => handleDrawerAction(NoMSADrawerAction.LoadGetStarted, Direction.back)}
        >
          <Icon name={IconNames.caretLeft} size={20} stroke={2} pointer={true} />
        </Styled.NavButton>
        Back
      </Styled.NavSection>
      <Styled.Title>Talk with a G-P expert about your needs.</Styled.Title>
      <Styled.SubTitle>
        Our team of global EOR experts is available to discuss your goals and unique needs.
      </Styled.SubTitle>
      <Styled.Description style={{ whiteSpace: "pre-wrap" }}>
        {
          "With over 1000 local legal, compliance, HR and Operations experts in more than 180 countries, we continue to be recognized by the industry as the gold standard for in market expertise and knowledge."
        }
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <div style={{ fontSize: 17, marginBottom: 6 }}>We'll work with you to:</div>
      <Styled.List>
        <Styled.ListItem>Understand your unique needs and objectives</Styled.ListItem>
        <Styled.ListItem>Configure and price the ideal solution</Styled.ListItem>
        <Styled.ListItem>Provide a live demo and walk-through</Styled.ListItem>
        <Styled.ListItem>Answer questions and clarify pricing</Styled.ListItem>
      </Styled.List>
    </Styled.Wrapper>
  );
}
