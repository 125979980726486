import styled from "styled-components";
import { colors, type } from "../../../assets/tokens/tokens";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 868px;
  width: 100%;
  gap: 88px;
`;

export const WeekTimes = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const WeekSelect = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextDisabled};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: min-content;
  padding: 0px;
  gap: 16px;
`;

export const WeekInfo = styled.h2`
  ${type.pRegular};
  color: ${colors.graysTextPrimary};
  font-size: 20px;
  ${type.pBoldFace};
  line-height: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 24px;
`;

export const WeekOf = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextPrimary};
  text-align: left;
`;

export const ButtonsWrapper = styled.h2`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin: 0;
`;

export const WeekDaysWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  gap: 32px;
  padding-top: 32px;
  width: 100%;
`;

export const WeekDayBlock = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const WeekDayTitle = styled.div<{ $isBefore: boolean }>`
  ${type.pMedium};
  color: ${(p) => (p.$isBefore ? colors.graysTextDisabled : colors.graysTextPrimary)};
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
`;

export const TimeSlots = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  flx-direction: row;
  gap: 8px;
  row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const TimeSlotButton = styled.div`
  ${type.pMedium};
  color: ${colors.primaryGPBlue};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  flex: 1;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 105px;
`;

export const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 0px;
`;

const setRadius = (isPrevious: boolean) => {
  if (isPrevious) return "8px 0px 0px 8px";
  return "0px 8px 8px 0px";
};

export const NavWeekButton = styled.div<{ $isPrevious: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: ${(p) => setRadius(p.$isPrevious)};
  border-width: 1px;
  border-style: solid;
  margin-right: ${(p) => (p.$isPrevious ? "-1px" : "0")};
  border-color: ${colors.grays400};
  cursor: pointer;
`;
