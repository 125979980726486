import { Country } from "../../assets/flags/Flag";
import { ProStatus } from "../EnumsAndTypes/EnumsAndTypes";

export const AllProfessionals = [
  {
    name: "Kyleigh Kub",
    email: "Elias_Wintheiser31@yahoo.com",
    salary: "234148.22",
    title: "Dynamic Group Consultant",
    country: Country.Canada,
    phone: "451.899.5001 x985",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Supervisor",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Yvette Bergstrom",
    email: "Stuart_McKenzie@yahoo.com",
    salary: "113953.89",
    title: "Chief Operations Technician",
    country: Country.Canada,
    phone: "(634) 271-1009 x82144",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Aubree Hamill",
    email: "Melba46@yahoo.com",
    salary: "190405.68",
    title: "Legacy Identity Agent",
    country: Country.Canada,
    phone: "839-660-5764 x8686",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Technician",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Santino Baumbach",
    email: "Kaci.Dibbert22@gmail.com",
    salary: "124339.84",
    title: "Legacy Research Analyst",
    country: Country.Canada,
    phone: "(820) 553-8297 x6821",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Director",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Olen Block",
    email: "Jamison_Howell77@hotmail.com",
    salary: "126735.15",
    title: "Dynamic Integration Executive",
    country: Country.Canada,
    phone: "284-309-3726 x3564",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Executive",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Mozelle Marvin",
    email: "Kyla_Mosciski-Durgan@hotmail.com",
    salary: "161092.61",
    title: "Global Usability Representative",
    country: Country.Canada,
    phone: "1-412-934-0255 x544",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Manager",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Cordell Lindgren",
    email: "Jace.Bernhard-Vandervort15@yahoo.com",
    salary: "139462.21",
    title: "Senior Functionality Developer",
    country: Country.Canada,
    phone: "1-770-980-7380 x13038",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Executive",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Natalia Larkin",
    email: "Vickie99@hotmail.com",
    salary: "120508.85",
    title: "Corporate Accounts Analyst",
    country: Country.Canada,
    phone: "527-704-3489 x824",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Associate",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Melissa Reynolds",
    email: "Rickie.Yundt@hotmail.com",
    salary: "165496.86",
    title: "Investor Brand Engineer",
    country: Country.Canada,
    phone: "607-268-9188",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Executive",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Bulah Collins",
    email: "Angela.Maggio33@yahoo.com",
    salary: "159872.48",
    title: "International Data Executive",
    country: Country.Canada,
    phone: "1-366-665-3460 x49823",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Director",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Roma Hudson",
    email: "Okey_Stark14@gmail.com",
    salary: "224176.15",
    title: "Dynamic Security Facilitator",
    country: Country.Canada,
    phone: "917-466-9257 x9629",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Executive",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Efrain Hane",
    email: "Verda_Bosco@yahoo.com",
    salary: "236710.85",
    title: "Product Data Facilitator",
    country: Country.Canada,
    phone: "925.419.8526 x077",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Kiana Cronin",
    email: "Leslie71@yahoo.com",
    salary: "121316.08",
    title: "Product Research Specialist",
    country: Country.Canada,
    phone: "(867) 678-6888 x5353",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Director",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Melody Crona",
    email: "Zelda.Kertzmann@hotmail.com",
    salary: "202384.41",
    title: "Senior Markets Coordinator",
    country: Country.Canada,
    phone: "(385) 517-8433 x70768",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Assistant",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Kasey Bergstrom",
    email: "Austin17@gmail.com",
    salary: "247323.47",
    title: "Lead Group Architect",
    country: Country.Canada,
    phone: "(416) 546-3069 x48107",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Assistant",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Jeanette Bednar",
    email: "Cleora_Reilly50@hotmail.com",
    salary: "189509.76",
    title: "Product Assurance Developer",
    country: Country.Canada,
    phone: "1-368-270-9357 x270",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Lilla Abshire-Schowalter",
    email: "Justus_Goyette30@yahoo.com",
    salary: "248598.80",
    title: "Legacy Intranet Director",
    country: Country.Canada,
    phone: "249.809.4131 x39357",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Architect",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Nathen Grant",
    email: "Theresia51@gmail.com",
    salary: "197801.58",
    title: "Central Division Supervisor",
    country: Country.Canada,
    phone: "788.744.8920 x1602",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Manager",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Elisa Kihn-Pfannerstill",
    email: "Shania.Pfannerstill17@yahoo.com",
    salary: "232991.79",
    title: "Investor Directives Producer",
    country: Country.Canada,
    phone: "265.443.0895 x4771",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Developer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Gonzalo Bogisich",
    email: "Destiny.Mertz@hotmail.com",
    salary: "243604.58",
    title: "District Marketing Consultant",
    country: Country.Canada,
    phone: "1-572-977-2424 x813",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Assistant",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Cheyanne Hilpert",
    email: "Anthony96@hotmail.com",
    salary: "133032.29",
    title: "Internal Brand Officer",
    country: Country.Canada,
    phone: "1-691-783-7760 x5397",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Director",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Annetta Cremin",
    email: "Novella55@gmail.com",
    salary: "134120.94",
    title: "Corporate Brand Orchestrator",
    country: Country.Canada,
    phone: "846-493-2024",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Adalberto Beahan",
    email: "adalberto.behan@gmail.com",
    salary: "105186.92",
    title: "Tester",
    country: Country.Canada,
    phone: "1-869-395-8695 x2383",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Coordinator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Harry Cummings-Deckow",
    email: "Liam19@gmail.com",
    salary: "207533.60",
    title: "Dynamic Factors Administrator",
    country: Country.Canada,
    phone: "1-808-482-0139",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Coordinator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Gene Parisian",
    email: "Matilde14@yahoo.com",
    salary: "117409.60",
    title: "District Functionality Associate",
    country: Country.Canada,
    phone: "1-617-812-2436 x5039",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Administrator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Glenna Corkery",
    email: "Damion_Fahey30@gmail.com",
    salary: "198254.55",
    title: "Dynamic Markets Analyst",
    country: Country.Canada,
    phone: "931.911.8233 x099",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Kian Beahan",
    email: "Caleigh.Doyle@hotmail.com",
    salary: "118873.56",
    title: "Central Markets Administrator",
    country: Country.Canada,
    phone: "896-418-0326 x24495",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Administrator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Henriette Corkery",
    email: "Emmett38@yahoo.com",
    salary: "123095.83",
    title: "National Identity Producer",
    country: Country.Canada,
    phone: "(599) 805-8324 x461",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Orchestrator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Verla Cummerata",
    email: "Cortney24@gmail.com",
    salary: "210708.74",
    title: "Central Assurance Consultant",
    country: Country.Canada,
    phone: "277.886.3866 x367",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Associate",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Jacky Kohler",
    email: "Robyn.Aufderhar94@yahoo.com",
    salary: "249955.04",
    title: "Product Mobility Representative",
    country: Country.Canada,
    phone: "647-350-6623 x9937",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Representative",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Aletha Rutherford",
    email: "Archibald.Mills@yahoo.com",
    salary: "188927.06",
    title: "Corporate Solutions Strategist",
    country: Country.Canada,
    phone: "1-275-493-7240",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Architect",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Alvah Wolf",
    email: "Sydnee.Wehner88@gmail.com",
    salary: "105788.18",
    title: "Forward Factors Representative",
    country: Country.Canada,
    phone: "1-986-328-4079",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Officer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Mekhi Haley",
    email: "Timmothy_Gerlach9@hotmail.com",
    salary: "240538.43",
    title: "Global Usability Facilitator",
    country: Country.Canada,
    phone: "1-519-512-4789 x8336",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Liaison",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Xander Luettgen",
    email: "Abdul30@yahoo.com",
    salary: "170543.99",
    title: "Central Group Liaison",
    country: Country.Canada,
    phone: "1-545-919-7450 x7397",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Facilitator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Stella Morar",
    email: "Mariam_Sawayn45@gmail.com",
    salary: "112061.38",
    title: "Legacy Group Agent",
    country: Country.Canada,
    phone: "660.257.3960",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Coordinator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Donald Morar",
    email: "Delphine_Swaniawski@gmail.com",
    salary: "208543.01",
    title: "Legacy Metrics Analyst",
    country: Country.Canada,
    phone: "1-665-489-0579 x89482",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Coordinator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Leonardo Grimes",
    email: "Garrett7@gmail.com",
    salary: "161003.42",
    title: "Principal Accounts Administrator",
    country: Country.Canada,
    phone: "1-352-530-3447 x3476",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Agent",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Effie Bayer",
    email: "Kolby.Armstrong84@yahoo.com",
    salary: "186354.65",
    title: "Future Directives Analyst",
    country: Country.Canada,
    phone: "(815) 915-7030 x4822",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Strategist",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Lonnie Bins",
    email: "Randy.Rolfson83@hotmail.com",
    salary: "179822.23",
    title: "Lead Web Producer",
    country: Country.Canada,
    phone: "209-241-8198",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Designer",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Eryn Batz",
    email: "Odell79@yahoo.com",
    salary: "132604.58",
    title: "Lead Functionality Officer",
    country: Country.Canada,
    phone: "1-733-900-6093",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Facilitator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Madilyn Leuschke",
    email: "Elian19@hotmail.com",
    salary: "247821.31",
    title: "Direct Creative Officer",
    country: Country.Canada,
    phone: "460-488-0410 x7533",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Technician",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Ignatius Bartoletti-Hammes",
    email: "Vivianne39@yahoo.com",
    salary: "146596.53",
    title: "Regional Program Consultant",
    country: Country.Canada,
    phone: "399.475.0792 x356",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Orchestrator",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Madyson Jacobson",
    email: "Janessa64@yahoo.com",
    salary: "107623.80",
    title: "District Interactions Executive",
    country: Country.Canada,
    phone: "(579) 969-3645 x3301",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Specialist",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Kristina Upton",
    email: "Jeffry.Baumbach52@yahoo.com",
    salary: "152804.11",
    title: "Principal Directives Engineer",
    country: Country.Canada,
    phone: "1-285-586-4471",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Architect",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Keyshawn Pfannerstill",
    email: "Antoinette97@hotmail.com",
    salary: "174346.83",
    title: "International Integration Manager",
    country: Country.Canada,
    phone: "(769) 766-3374 x16381",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Strategist",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Immanuel Vandervort",
    email: "Garth_Padberg@gmail.com",
    salary: "248036.65",
    title: "Corporate Identity Designer",
    country: Country.Canada,
    phone: "212-841-0109 x413",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Representative",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
  {
    name: "Daron Gerhold",
    email: "Sabina.Will@yahoo.com",
    salary: "226508.80",
    title: "Principal Intranet Representative",
    country: Country.Canada,
    phone: "1-719-519-1760 x1827",
    startDate: "2023-12-15T06:44:16.318Z",
    visa: false,
    jobduties: "Strategist",
    bonus: false,
    signon: false,
    comission: false,
    currency: "CAD",
    symbol: "$",
    morebenefits: false,
    allowance: false,
    covenants: false,
    status: ProStatus.editing,
  },
];
