import Australia from "./Australia.svg";
import Canada from "./Canada.svg";
import France from "./France.svg";
import Germany from "./Germany.svg";
import USA from "./USA.svg";
import India from "./India.svg";
import Mexico from "./Mexico.svg";
import Philippines from "./Philippines.svg";
import Spain from "./Spain.svg";
import UK from "./United Kingdom.svg";
import China from "./China.svg";
import Ireland from "./ireland.svg";

export enum Country {
  Australia = "Australia",
  USA = "USA",
  Canada = "Canada",
  Germany = "Germany",
  France = "France",
  China = "China",
  India = "India",
  Mexico = "Mexico",
  Philippines = "Philippines",
  UK = "UK",
  Spain = "Spain",
  Ireland = "Ireland",
}

interface Props {
  country: Country;
  size?: number;
}

export default function Flag(props: Props) {
  const { country = Country.USA, size = 24 } = props;

  const getCountry = () => {
    if (country === Country.USA) return USA;
    if (country === Country.Australia) return Australia;
    if (country === Country.Canada) return Canada;
    if (country === Country.Germany) return Germany;
    if (country === Country.France) return France;
    if (country === Country.China) return China;
    if (country === Country.India) return India;
    if (country === Country.Mexico) return Mexico;
    if (country === Country.Philippines) return Philippines;
    if (country === Country.UK) return UK;
    if (country === Country.Spain) return Spain;
    if (country === Country.Ireland) return Ireland;
    return USA;
  };

  return <img src={getCountry()} alt={country} width={size} height={size} />;
}
