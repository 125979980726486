import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import { NoMSADrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";

export default function SchedulingCall() {
  const { handleDrawerAction } = useNoMSADrawer();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 0 0",
      }}
    >
      <ProgressIndicator
        status={{ working: "Scheduling Call", done: "Scheduled Call Confirmed" }}
        type={"check"}
        loops={10}
        stroke={0.75}
        size={125}
        playing={true}
        fill={true}
        progressEnd={() => handleDrawerAction(NoMSADrawerAction.Next)}
      />
    </div>
  );
}
