import * as Styled from "../Styles";
import { useState } from "react";
import { ChatMessage, View } from "../../../EnumsAndTypes/EnumsAndTypes";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import DocUpload from "../../../../assets/images/docupload";
import ProgressBar from "../../../ProgressBar/ProgressBar";
import { colors } from "../../../../assets/tokens/tokens";
import { DrawerAction } from "../../../EnumsAndTypes/EnumsAndTypes";
import Icon, { IconNames } from "../../../../assets/icons/Icons";
import { useDrawer } from "../../../../contexts/DrawerContext";
import { MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";
import { usePartner } from "../../../../hooks/usePartner";

export default function UploadOrManual() {
  const {
    handleTriggerChat,
    handleDrawerAction,
    handleIsManualAdd,
    handleIsUploading,
    isUploading,
  } = useDrawer();
  const [trackColor, setTrackcolor] = useState("transparent");
  const { partnerName } = usePartner();

  const handleUplaod = () => {
    handleIsManualAdd(false);
    handleIsUploading(true);
    setTrackcolor(colors.grays100);
  };

  const infoMessage: Array<ChatMessage> = [
    {
      message: {
        title: "More on adding international hires",
        text: "Adding international hires where you have no entity is straight forward.",
      },
      sender: MessageSender.GIA,
    },
    {
      message: {
        text: "Once you've identified one or more candidates, you can add them here and, optionally, have G-P automatically generate their employment contract based on terms you agreed on (salary, bonus, etc.).",
      },
      sender: MessageSender.GIA,
    },
    {
      message: {
        text: "We'll review the contract and work with you to contact the candidate and formally secure their signature.",
      },
      sender: MessageSender.GIA,
    },
    {
      message: {
        text: `You can track progress right here on your ${partnerName} dashboard.`,
      },
      sender: MessageSender.GIA,
    },
  ];

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Add International Hires.</Styled.Title>
      <Styled.Description>
        Add details for professionals you want to hire in countries where you don't have a legal
        entity. You can add hires manually or upload a CSV/Excel file.
        <MoreInfoButton triggerChat={() => handleTriggerChat(infoMessage)} />
      </Styled.Description>
      <Styled.DragDropArea onClick={() => handleUplaod()}>
        <DocUpload height={56} />
        <span>
          Drag and drop you hires file here, or <Styled.TextLink>Browse</Styled.TextLink>
        </span>
        <Styled.Caption>File size limit: 20MB</Styled.Caption>
      </Styled.DragDropArea>
      <Styled.LinkButton onClick={() => handleTriggerChat(fileFormatMessage)}>
        <Icon name={IconNames.info} size={16} stroke={2} theme={"blueButton"} pointer={true} />
        File format tips
      </Styled.LinkButton>
      <ProgressBar
        trackcolor={trackColor}
        playing={isUploading}
        progressEnd={() => {
          handleIsUploading(false);
          handleDrawerAction(DrawerAction.UploadDone);
        }}
        progressStart={() => null}
      />
    </Styled.QuestionWrapper>
  );
}

const fileFormatMessage: Array<ChatMessage> = [
  {
    message: {
      title: "More on uploading CSV/Excel files",
      text: "The first line of your CSV file, or the first row of your excel file, should contain field names we can map to the information we need.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Required fields are: First name, Last Name, Job Title, Country of Employment, Salary, Personal Email, Personal Phone Number.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Optional fields include: First name, Last Name, Job Title, Country of Employment, Salary, Personal Email, Personal Phone Number.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Here's a sample file you can use to verify your file format or that you can also use as a template.",
    },
    sender: MessageSender.GIA,
    files: [
      { name: "AddPros.csv", type: "CSV", size: "4kb" },
      { name: "AddPros.xls", type: "Excel", size: "6kb" },
    ],
  },
];
