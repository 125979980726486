import styled from "styled-components";
import { motion } from "framer-motion";

export const ADPPage = styled.div<{ $scroll: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
  overflow: ${(p) => (p.$scroll ? "auto" : "hidden")};
`;
