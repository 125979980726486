import styled from "styled-components";
import { colors } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-directon: row;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 16px;
`;

export const Option = styled(motion.div)<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 12px 0px;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  color: ${(p) => (p.$selected ? colors.graysWhite : colors.graysTextDisabled)};
  background-color: ${(p) => (p.$selected ? colors.primaryGPBlue : colors.grays100)};
  border-right: 1px solid ${(p) => (p.$selected ? colors.graysWhite100 : colors.grays300)};
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  &:hover {
    background-color: ${(p) => (p.$selected ? colors.primaryGPBlue : colors.grays400)};
    color: ${(p) => (p.$selected ? colors.graysWhite : colors.graysTextPrimary)};
  }
  &:last-child {
    border-right: none;
  }
`;

export const Info = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  gap: 4px;
`;
export const Label = styled.div``;
export const Count = styled.div`
  display: flex;
  padding: 2px 4px;
  background-color: ${colors.warning300};
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.graysWhite};
`;
