import * as Styled from "./Styles";
import { usePros } from "../../contexts/ProfessionalsContext";
import { ProStatus } from "../EnumsAndTypes/EnumsAndTypes";

type PillBarOption = {
  label?: string;
  value?: string;
  count?: number;
  status?: ProStatus;
};

const defaultOptions = [
  { label: "Editing Contract", status: ProStatus.editing },
  { label: "Contract Ready", status: ProStatus.ready },
  { label: "Awaiting Signature", status: ProStatus.sent },
  { label: "Contract Signed", status: ProStatus.signed },
];

interface Props {
  options?: PillBarOption[];
}

export default function InputPillBar(props: Props) {
  const { options = defaultOptions } = props;
  const { status, handleStatusChange, proCounts } = usePros();

  const renderOptions = () => {
    return options.map((option: PillBarOption, i: number) => {
      return (
        <Styled.Option
          key={option.label + i}
          $selected={status === option.status}
          onClick={() => {
            handleStatusChange(status === option.status ? null : option.status);
          }}
        >
          <Info
            showCounts={proCounts.all > 0}
            count={proCounts[option.status.toString()]}
            label={option.label}
          />
        </Styled.Option>
      );
    });
  };

  return <Styled.Wrapper>{renderOptions()}</Styled.Wrapper>;
}

interface InfoProps {
  count: number;
  label: string;
  showCounts?: boolean;
}

const Info = (props: InfoProps) => {
  const { count, label, showCounts } = props;
  const setCount = () => {
    if (!showCounts) return;
    return <Styled.Count>{count}</Styled.Count>;
  };
  return (
    <Styled.Info>
      <Styled.Label>{label}</Styled.Label>
      {setCount()}
    </Styled.Info>
  );
};
