import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: -600px;
  text-align: center;
  left: 50%;
  height: 330px;
  transform: translateX(-50%);
  display: flex;
  flex-flow: column wrap;
  flex-direction: column;
  justify-content: felx-start;
  align-items: flex-start;
  border: 1px solid ${colors.grays200};
  padding: 16px;
  z-index: 1;
  width: 650px;
  background-color: ${colors.graysWhite};
`;

export const Option = styled.div`
  ${type.pSmall};
  box-sizing: border-box;
  display: flex;
  justify-content: felx-start;
  align-items: flex-start;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 8px;
  color: ${colors.primaryGPBlue};
  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
