import * as SC from "./Styles";
import GPLogo from "../../assets/images/gplogo";
import Gia from "../../assets/images/gia";
import { motion } from "framer-motion";
import { useDrawer } from "../../contexts/DrawerContext";
import { DrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import { DrawerTalkLiveButton } from "../TalkLiveButton/TalkLiveButton";
import { usePartner } from "../../hooks/usePartner";

export default function DrawerHeader() {
  const { GIA, handleDrawerAction } = useDrawer();
  const { partnerLogo } = usePartner();

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  return (
    <SC.Header>
      <SC.TitleArea>
        <GPLogo height={26} />+{partnerLogo}
      </SC.TitleArea>
      <SC.ActionsArea variants={variants} initial={"hide"} animate={GIA ? "hide" : "show"}>
        <DrawerTalkLiveButton
          onClick={() => {
            handleDrawerAction(DrawerAction.ConnectLiveAgent);
          }}
        />
        <motion.div variants={variants} initial={"hide"} animate={GIA ? "hide" : "show"}>
          <Gia height={28} onClick={() => handleDrawerAction(DrawerAction.ToggleGIA)} />
        </motion.div>
      </SC.ActionsArea>
    </SC.Header>
  );
}
