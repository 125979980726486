import * as SC from "./Styles";
import NavButton, { State } from "../NavButton/NavButton";
import { IconNames } from "../../assets/icons/Icons";
import { Direction, NoMSADrawerAction, NoMSAView } from "../EnumsAndTypes/EnumsAndTypes";
import { AnimatePresence } from "framer-motion";
import { useRef } from "react";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import StartButton from "../StartButton/StartButton";
import { colors } from "../../assets/tokens/tokens";

export default function DrawerFooter() {
  const { GPLoaded, viewstate, handleFooterChange } = useNoMSADrawer();
  const ref = useRef();
  const size = useResizeObserver(ref);

  const amt = () => {
    return size?.height ? size.height : 500;
  };

  const setFooter = () => {
    handleFooterChange(viewstate.view);
    switch (viewstate.view) {
      case NoMSAView.GetStarted:
        return <GetStarted />;
      case NoMSAView.Quote:
        return <QuoteFooter />;
      case NoMSAView.ContactUs:
        return <ContactUs />;
      case NoMSAView.CallbBack:
        return <CallBackFooter />;
      case NoMSAView.Schedule:
        return <ScheduleFooter />;
      default:
        return null;
    }
  };

  // place nothing is GP not loaded
  if (!GPLoaded) return null;
  return (
    <AnimatePresence initial={true}>
      <SC.ContainerView
        $isFooter={true}
        ref={ref}
        key={"footer_" + viewstate.view.toString()}
        initial={{ y: amt(), opacity: 1 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: amt(), opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        {setFooter()}
      </SC.ContainerView>
    </AnimatePresence>
  );
}

//*******
// footer get started
//*******
const GetStarted = () => {
  const { handleDrawerAction } = useNoMSADrawer();
  return (
    <SC.ColumnLayout $bgColor={colors.grays200}>
      <SC.Title $darkBG={false}>How would you like to get Started?</SC.Title>
      <StartButton
        type={"quote"}
        onClick={() => handleDrawerAction(NoMSADrawerAction.LoadQuote, Direction.forward)}
      />
      <StartButton
        type={"contact"}
        onClick={() => handleDrawerAction(NoMSADrawerAction.LoadContactUs, Direction.forward)}
      />
    </SC.ColumnLayout>
  );
};

//*******
// footer contact us
//*******
const ContactUs = () => {
  const { handleDrawerAction } = useNoMSADrawer();
  return (
    <SC.ColumnLayout $bgColor={colors.graysWhite}>
      <SC.Title $darkBG={false}>How would you like to connect?</SC.Title>
      <SC.AcceptActions>
        <NavButton
          iconpos={"left"}
          icon={IconNames.callBack}
          iconstyle={{ size: 24, stroke: 1.5 }}
          label={"Get a Call Back"}
          theme={"blue"}
          onClick={() => handleDrawerAction(NoMSADrawerAction.LoadCallBack, Direction.forward)}
        />
        or
        <NavButton
          iconpos={"left"}
          icon={IconNames.calendar}
          iconstyle={{ size: 24, stroke: 1.5 }}
          label={"Schedule a Call"}
          theme={"blue"}
          onClick={() => handleDrawerAction(NoMSADrawerAction.LoadSchedule, Direction.forward)}
        />
      </SC.AcceptActions>
    </SC.ColumnLayout>
  );
};

//*******
// footer for quote
//*******
const QuoteFooter = () => {
  const { handleDrawerAction, quotePageIndex, quotePageCount } = useNoMSADrawer();

  const cancelBtn = () => {
    return (
      <NavButton
        iconpos={"none"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={quotePageIndex === quotePageCount ? "Done" : "Cancel"}
        state={quotePageIndex == 4 ? State.disabled : State.active}
        onClick={() => handleDrawerAction(NoMSADrawerAction.Cancel, Direction.back)}
      />
    );
  };

  const backBtn = () => {
    if (quotePageIndex === quotePageCount) return null;
    const setAction = () => {
      if (quotePageIndex === 5) return NoMSADrawerAction.DoubleBack;
      return NoMSADrawerAction.Previous;
    };
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.carretUp}
        label={"Back"}
        state={quotePageIndex < 1 || quotePageIndex === 4 ? State.disabled : State.active}
        onClick={() => handleDrawerAction(setAction())}
      />
    );
  };

  const nextBtn = () => {
    if (quotePageIndex > quotePageCount) return null;
    const action =
      quotePageCount === quotePageIndex ? NoMSADrawerAction.LoadContactUs : NoMSADrawerAction.Next;
    return (
      <NavButton
        iconpos={quotePageIndex === 6 ? "left" : "none"}
        icon={IconNames.chatUser}
        iconstyle={{ size: 24, stroke: 1.5 }}
        state={quotePageIndex === 4 ? State.disabled : State.active}
        label={
          quotePageIndex === 5
            ? "View Quote"
            : quotePageIndex === 6
            ? "Review with an expert"
            : "Continue"
        }
        theme={quotePageIndex >= 5 ? "blue" : "white"}
        onClick={() => handleDrawerAction(action)}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>
        {backBtn()}
        {nextBtn()}
      </SC.PrevNext>
    </SC.Footer>
  );
};

//*******
// footer for callback
//*******
const CallBackFooter = () => {
  const { handleDrawerAction, callbackPageIndex, callBackPageCount } = useNoMSADrawer();
  const cancelBtn = () => {
    return (
      <NavButton
        iconpos={"none"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Back"}
        state={callbackPageIndex >= 1 ? State.disabled : State.active}
        onClick={() => handleDrawerAction(NoMSADrawerAction.LoadContactUs, Direction.back)}
      />
    );
  };

  const nextBtn = () => {
    const action =
      callBackPageCount === callbackPageIndex
        ? NoMSADrawerAction.LoadGetStarted
        : NoMSADrawerAction.Next;
    return (
      <NavButton
        state={callbackPageIndex === 1 ? State.disabled : State.active}
        label={callbackPageIndex === 2 ? "Done" : "Continue"}
        theme={callbackPageIndex === 2 ? "blue" : "white"}
        onClick={() => handleDrawerAction(action, Direction.back)}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>{nextBtn()}</SC.PrevNext>
    </SC.Footer>
  );
};

//*******
// footer Schedule
//*******
const ScheduleFooter = () => {
  const { handleDrawerAction, schedulePageIndex, schedulePageCount } = useNoMSADrawer();
  const cancelBtn = () => {
    const action =
      schedulePageIndex === 0 ? NoMSADrawerAction.LoadContactUs : NoMSADrawerAction.Previous;
    return (
      <NavButton
        iconpos={"none"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Back"}
        state={
          schedulePageIndex === 2 || schedulePageIndex === schedulePageCount
            ? State.disabled
            : State.active
        }
        onClick={() => handleDrawerAction(action, Direction.back)}
      />
    );
  };

  const nextBtn = () => {
    const action =
      schedulePageCount === schedulePageIndex
        ? NoMSADrawerAction.LoadGetStarted
        : NoMSADrawerAction.Next;
    return (
      <NavButton
        state={schedulePageIndex === 2 ? State.disabled : State.active}
        label={schedulePageIndex === 3 ? "Done" : "Continue"}
        theme={schedulePageIndex === 3 ? "blue" : "white"}
        onClick={() => handleDrawerAction(action, Direction.back)}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>{nextBtn()}</SC.PrevNext>
    </SC.Footer>
  );
};
