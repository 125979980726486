import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import video_poster from "../../assets/images/video_poster@2x.png";

export const initialMessagesOld: Array<ChatMessage> = [
  {
    sender: MessageSender.GIA,
    message: { title: null, text: "Hi. I'm GIA (Global Intelligence Assistant)." },
    display: true,
  },
  {
    sender: MessageSender.GIA,
    message: {
      text: "We can chat naturally and you can ask me anything by typing your message below. You can also talk to a human at any time.",
      title: null,
    },
    display: true,
  },
  {
    sender: MessageSender.GIA,
    message: { title: null, text: "How can I help you today?" },
    display: true,
  },
];

export const triggerPreMessage: Array<ChatMessage> = [
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Hi. I'm GIA (Global Intelligence Assistant). I can help answer any questions and get your started with global growth.",
    },
    display: true,
  },
];

export const initialMessages: Array<ChatMessage> = [
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Hi. I'm GIA (G-P's digital Global Intelligence Assistant).\nI can help answer any questions you have.",
    },
  },
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Meanwhile, learn everything you need to know about EOR in under 2 minutes: ",
    },
    files: [
      { name: "Introducing EOR by G-P", type: "video", size: "1:05 mins", image: video_poster },
    ],
    actions: [{ name: "More on G-P's website", type: "link" }],
  },
];
