import { createContext, useContext, useEffect, useState } from "react";
import { ADPScreens } from "../components/EnumsAndTypes/EnumsAndTypes";

const ADPContext = createContext(null);

interface Props {
  children?: any;
  adpScreen?: ADPScreens;
  onChange?: () => void;
}

export function ADPProvider(props: Props) {
  const { children, onChange = () => null, adpScreen = ADPScreens.MenuView } = props;
  const [currentView, setCurrentView] = useState(adpScreen);
  useEffect(() => setCurrentView(adpScreen), [adpScreen]);

  const handleClick = (screen: ADPScreens) => {
    if (screen === ADPScreens.MenuView) onChange();
    if (screen === ADPScreens.ListView) setCurrentView(ADPScreens.ListMenu);
    if (screen === ADPScreens.ListMenu) {
      setCurrentView(ADPScreens.ListView);
      onChange();
    }
  };

  return (
    <ADPContext.Provider
      value={{
        screenView: currentView,
        handleClick: handleClick,
      }}
    >
      {children}
    </ADPContext.Provider>
  );
}

export function useADP() {
  const context = useContext(ADPContext);
  return context;
}
