import * as Styled from "./Styles";
import InputButtonList from "../InputButtonList/InputButtonList";
import { ButtonType } from "../InputButton/InputButton";
import { useState } from "react";
import { recruitoptions, timeframe } from "./SurveyData";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";

interface Props {
  data?: boolean[];
  onChange?: (data: boolean[]) => void;
}

export default function TimeLine(props: Props) {
  const { data = null, onChange = () => null } = props;
  const [selectedValues, setSelectedValues] = useState(data);
  const handleChanges = (values: any) => {
    setSelectedValues(values);
    onChange(values);
  };
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>What's your projected timeline?</Styled.Title>
      <Styled.Description>
        How quickly you need to gain an operational presence may drive the need for more robust
        legal, HR and compliance support from our team.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <InputButtonList
        type={ButtonType.default}
        multiselect={true}
        options={timeframe}
        selected={selectedValues instanceof Array ? selectedValues : null}
        onChange={(values) => handleChanges(values)}
      />
    </Styled.QuestionWrapper>
  );
}
