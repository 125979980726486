import styled from "styled-components";
import { colors, type } from "../../../assets/tokens/tokens";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 24px 48px 40px 48px;
  flex: 1 0 0;
  width: 100%;
`;

export const NavSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
`;

export const NavButton = styled.div`
  ${type.pMedium}
  color:${colors.graysTextPrimary};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  background: ${colors.grays100};
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Name = styled.div`
  ${type.pRegularBold};
  color: ${colors.graysTextPrimary};
  line-height: 1em;
`;

export const JobTitle = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextTertiary};
`;

export const GroupTitle = styled.div`
  box-sizing: border-box;
  ${type.sectionTitle};
  color: ${colors.graysTextPrimary};
  display: flex;
  gap: 8px;
  width: 100%;
  padding-left: 24px;
  margin-bottom: 8px;
  margin-top: 40px;
`;

export const InfoGroupHead = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.grays400};
  border-radius: 8px;
  padding: 20px 24px;
  gap: 16px;
  width: 100%;
`;

export const AllDetailsButton = styled.div`
  ${type.pMedium}
  color:${colors.graysTextPrimary};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  margin-top: 16px;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  background: ${colors.grays100};
  width: 100%;
`;

export const ShareDetailsButton = styled.div`
  ${type.pMedium}
  color:${colors.graysWhite};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 0;
  margin-top: 40px;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  background: ${colors.primaryGPBlue};
  width: 100%;
`;

export const InfoGroup = styled.div`
  box-sizing: border-box;
  border: 1px solid ${colors.grays400};
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
`;

export const InfoItem = styled.div<{ $last: boolean; $first: boolean }>`
  box-sizing: border-box;
  border-bottom: ${(p) => (p.$last ? 0 : 1)}px solid ${colors.grays100};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${(p) => (p.$first ? 16 : 10)}px 0px ${(p) => (p.$last ? 16 : 10)}px 0;
  width: 100%;
`;

export const ItemSectionTitle = styled.div`
  ${type.pSmall};
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.graysTextDisabled};
`;

export const ItemPrimaryText = styled.div`
  ${type.pRegular};
  color: ${colors.graysTextPrimary};
  display: flex;
  gap: 8px;
`;

export const ItemSecondaryText = styled.div`
  ${type.pRegular};
  color: ${colors.graysTextPrimary};
  line-height: 1.2em;
  display: flex;
  gap: 16px;
  margin: 4px 0px;
`;

export const ItemComments = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextTertiary};
  line-height: 1.5em;
  display: flex;
  gap: 16px;
  margin: 4px 0px;
`;

export const LinkButton = styled.a`
  color: ${colors.primaryGPBlue};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ContractLink = styled(Link)`
  color: ${colors.primaryGPBlue};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Status = styled.div<{ $sent: boolean }>`
  box-sizing: border-box;
  ${type.caption};
  color: ${(p) => (p.$sent ? colors.green500 : colors.warning300)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border-radius: 4px;
  background-color: ${(p) => (p.$sent ? colors.green100 : colors.warning300a30)};
`;
