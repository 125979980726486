export default function ColabCursor(user: string) {
  const labelStyle = { bg: "#0000ff", color: "#ffffff," };
  // create a span to hold the cursor position as inline
  const colabCursor = document.createElement("span");
  colabCursor.classList.add("colab-cursor_root");
  colabCursor.setAttribute("style", `position:relative;z-index:100;user-select:none;`);
  colabCursor.setAttribute("id", `test_1234`);
  // create div to render the contents of the cursor pointer
  const cursorWrapper = document.createElement("div");
  cursorWrapper.classList.add("colab-cursor_wrapper");
  cursorWrapper.setAttribute(
    "style",
    `position:absolute; transform:translateY(-70%) translateX(-50%); top:-50%; left:-50%;user-select:none;`
  );
  // create div to render the contents with username and usercolor
  const cursorLabel = document.createElement("div");
  cursorLabel.classList.add("colab-cursor_label");
  cursorLabel.setAttribute(
    "style",
    `background-color: ${labelStyle.bg};color:#ffffff;position:realtive;display:flex;justify-content:center;align-items:center;padding:6px 10px;border-radius:4px;font-size:13px;line-height:1em;user-select:none;white-space:pre;`
  );
  // create simple text node with the user name
  const userName = document.createTextNode(user);
  // create div to render the cursor carret
  const cursorCaret = document.createElement("div");
  cursorCaret.classList.add("colab-cursor_caret");
  cursorCaret.setAttribute(
    "style",
    `background-color:${labelStyle.bg};color:${labelStyle.color};border-radius:100px;width:2px;height:20px;position:absolute;transform:translateX(-50%) translateY(50%); top:60%; left:50%;z-index:-1;user-select: none;`
  );
  cursorLabel.appendChild(userName);
  cursorWrapper.appendChild(cursorCaret);
  cursorWrapper.appendChild(cursorLabel);
  colabCursor.appendChild(cursorWrapper);
  return colabCursor;
}
