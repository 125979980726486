import styled from "styled-components";
import { colors, transitions, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const variants = {
  blue: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.grays400,
      borderColor: colors.grays400,
    },
    active: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextPrimary,
      borderColor: colors.grays400,
    },
    selected: {
      backgroundColor: colors.primaryGPBlue,
      color: colors.graysWhite,
      borderColor: colors.primaryGPBlue,
    },
    hover: {
      backgroundColor: colors.graysWhite,
      color: colors.primaryGPBlue,
      borderColor: colors.grays400,
    },
  },
  orange: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.grays400,
      borderColor: colors.grays400,
    },
    active: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextPrimary,
      borderColor: colors.grays400,
    },
    selected: {
      backgroundColor: colors.graysTextPrimary,
      color: colors.graysWhite,
      borderColor: colors.graysTextPrimary,
    },
    hover: {
      backgroundColor: colors.graysWhite,
      color: colors.primaryGPBlue,
      borderColor: colors.grays400,
    },
  },
  modal: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.grays400,
      borderColor: colors.grays400,
      borderWidth: 1,
    },
    active: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextPrimary,
      borderColor: colors.grays400,
      borderWidth: 1,
    },
    selected: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextPrimary,
      borderColor: colors.grays600,
      borderWidth: 2,
    },
    hover: {
      backgroundColor: colors.graysWhite,
      color: colors.primaryGPBlue,
      borderColor: colors.grays400,
      borderWidth: 1,
    },
  },
};

export const Default = styled(motion.div)`
  ${type.pMedium};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const setValue = (value: any) => {
  if (isNaN(+value)) return value;
  return value + "px";
};

type Size = {
  height?: number | string;
  width?: number | string;
};

export const MultiLine = styled(motion.div)<{ $size: Size }>`
  ${type.pMedium};
  color: ${colors.black85};
  box-sizing: border-box;
  position: relative;
  width: ${(p) => setValue(p.$size.width)};
  height: ${(p) => setValue(p.$size.height)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  padding: 0;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const MultiLineWrapper = styled.div`
  box-sizing: border-box;
  align-self: stretch;
  padding: 12px 24px 0px 16px;
  width: 100%;
`;

export const MultiLineSubtext = styled(motion.p)`
  ${type.pMedium};
  font-size: 15px;
  color: ${colors.graysTextDisabled};
  margin: 0;
  pading: 0;
`;

export const Info = styled(motion.div)<{ $show: boolean }>`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 100;
  display: inherit;
  opacity: ${(p) => (p.$show ? 1 : 0)};
`;

export const Country = styled(motion.div)<{ $pad: number }>`
  ${type.pSmall};
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  height: 50px;
  align-items: center;
  padding: ${(p) => (p.$pad ? p.$pad : "8")}px 12px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Flag = styled(motion.div)<{ $on: boolean; $isInput: boolean }>`
  opacity: ${(p) => (!p.$isInput ? 1 : p.$on ? 1 : 0.65)};
  filter: saturate(${(p) => (p.$on ? 1 : 1)});
  height: 24px;
`;
