import { AnimatePresence } from "framer-motion";
import { ChatMessage } from "../EnumsAndTypes/EnumsAndTypes";
import MessageBubble from "../MessageBubble/MessageBubble";
import { useGIA } from "../../contexts/GIAContext";
import { useEffect } from "react";
import { ThinkingBubble } from "../MessageBubble/ThinkingBubble";

interface Props {
  onChange?: () => void;
}
export default function GIAMessages(props: Props) {
  const { onChange = () => null } = props;
  const { messages, chatGTPThinking } = useGIA();
  useEffect(() => {
    onChange();
  }, [messages]);

  const renderMessages = () => {
    if (!messages) return null;
    return messages.map((msg: ChatMessage, i: number) => {
      return <MessageBubble key={"ChatMesssage_" + i} msg={msg} />;
    });
  };

  return (
    messages && (
      <AnimatePresence initial={true}>
        {renderMessages()}
        {chatGTPThinking && <ThinkingBubble />}
      </AnimatePresence>
    )
  );
}
