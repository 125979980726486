import * as SC from "./Styles";
import DrawerHeader from "./DrawerHeader";
import DrawerFooter from "./DrawerFooter";
import DrawerProgres from "./DrawerProgress";
import { NoMSAView } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import SideView from "../SideView/SideView";
import NoMSAConnecting from "../Connecting/NoMSAConnecting";
import GetStarted from "../GetStarted/GetStarted";
import QuoteView from "../Quote/QuoteView";
import ContactUs from "../ContactUs/ContactUsView";
import CallBackView from "../CallBack/CallBackView";
import ScheduleCallView from "../ScheduleCall/ScheduleCallView";

export function GPDrawerNoMSA() {
  // gste state from drawer context
  const { visible, GIA, footerHeight, viewstate } = useNoMSADrawer();
  // set width of drawdr
  const drawerWidth = 500;
  // set draw variants for open, closed and open with GIA / chat
  const DrawerVariants = {
    hidden: { right: -drawerWidth, opacity: 1, width: drawerWidth },
    visible: { right: 0, opacity: 1, width: drawerWidth },
    showgia: { right: 400, opacity: 1, width: drawerWidth },
  };
  // set the variant of the drawer to show
  const setVariant = () => {
    if (GIA) return visible ? "showgia" : "hidden";
    return visible ? "visible" : "hidden";
  };
  // return the drawer UI
  return (
    <SC.Wrapper
      $width={drawerWidth}
      variants={DrawerVariants}
      initial={"hidden"}
      transition={{ ease: "easeInOut", duration: 0.25 }}
      animate={setVariant()}
    >
      <DrawerHeader />
      <DrawerProgres />
      <SideView footerHeight={footerHeight} viewstate={viewstate}>
        <NoMSAConnecting key={NoMSAView.Connecting} />
        <GetStarted key={NoMSAView.GetStarted} />
        <QuoteView key={NoMSAView.Quote} />
        <ContactUs key={NoMSAView.ContactUs} />
        <CallBackView key={NoMSAView.CallbBack} />
        <ScheduleCallView key={NoMSAView.Schedule} />
      </SideView>
      <DrawerFooter />
    </SC.Wrapper>
  );
}

export default GPDrawerNoMSA;
