import styled from "styled-components";
import { colors, transitions, type } from "../../../assets/tokens/tokens";

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 24px 48px;
  flex: 1 0 0;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Responses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0px;
  padding: 0px;
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
`;

export const SubTitle = styled.div`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  margin-top: 40px;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -8px 0px 16px 0px;
  padding: 0px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export const LinkButton = styled.div`
  ${type.pSmall};
  color: ${colors.primaryGPBlue};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  gap: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormFieldsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const TableTitle = styled.div<{ $offered: boolean }>`
  ${type.sectionTitle};
  color: ${(p) => (p.$offered ? colors.green500 : colors.error)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  border-bottom: 0px solid ${colors.graysTextPrimary};
  box-sizing: border-box;
`;

export const TableItem = styled.div<{ $isLast: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  border: 1.5px solid ${colors.grays400};
  ${(p) => (p.$isLast ? "margin-bottom: 24px" : null)};
`;

export const DocItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2px;
  gap: 2px;
`;

export const BenefitType = styled.div`
  ${type.caption}
  color: ${colors.graysTextDisabled};
  text-transform: uppercase;
`;

export const BenefitName = styled.div`
  color: ${colors.graysTextPrimary};
  text-decoration: none;
`;

export const DragDropArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  gap: 10px;
  border: 2px dashed ${colors.grays400};
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 0px;
`;

export const Caption = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextDisabled};
`;

export const TextLink = styled.span`
  color: ${colors.primaryGPBlue};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
