import styled from "styled-components";
import { motion } from "framer-motion";

export const Overlay = styled(motion.div)<{ $bgcolor: string; $visible: boolean }>`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: ${(p) => (p.$visible ? "auto" : "none")};
  background-color: ${(p) => p.$bgcolor};
`;
