import * as Styled from "../Styles";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import InputButtonList from "../../../InputButtonList/InputButtonList";
import { ButtonType } from "../../../InputButton/InputButton";

const buttonOptions = [{ text: "Yes" }, { text: "No" }];

interface Props {
  firstName?: string;
  data?: any;
  onChange?: (fieldname: string, data: any) => void;
}

export default function Termination(props: Props) {
  const { firstName = "Daniel", data = null, onChange = () => null } = props;

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Termination option for {firstName}.</Styled.Title>
      <Styled.Description>
        We'll use this information to generate {firstName}'s employment contract.
      </Styled.Description>

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Would you like to include post-employment restrictive covenants?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.termination}
        onChange={(values) => onChange("termination", values)}
      />
    </Styled.QuestionWrapper>
  );
}
