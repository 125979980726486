import * as Styled from "../Styles";
import InputButtonList from "../../../InputButtonList/InputButtonList";
import { ButtonType } from "../../../InputButton/InputButton";
import { useState } from "react";
import InputCheckBox from "../../../InputCheckbox/InputCheckbox";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import { useDrawer } from "../../../../contexts/DrawerContext";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";

const shareOptions = [{ text: "Yes" }, { text: "No thanks" }, { text: "I'll do this later" }];
const contractOptions = [{ text: "Yes" }, { text: "No, thanks" }];

interface Props {
  data?: boolean[];
  firstName?: string;
  onChange?: (data: boolean[]) => void;
}

export default function EmploymentAgreement(props: Props) {
  const { data = null, firstName = "Daniel", onChange = () => null } = props;
  const { handleTriggerChat, handleDraftContracts } = useDrawer();

  const setName = () => {
    if (!firstName || firstName === "") return null;
    return ` with ${firstName}`;
  };

  const setNameText = () => {
    if (!firstName || firstName === "")
      return "agreements to job details before drafting formal employment contracts";
    return `${firstName}'s agreement to the job before drafting a formal employment contract`;
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Additional options.</Styled.Title>

      <Styled.SubTitle style={{ marginTop: 32 }}>
        Contact professional: Would you like us to share job details with the professional(s) on
        your behalf?
        <MoreInfoButton triggerChat={() => handleTriggerChat(contactInfo)} />
      </Styled.SubTitle>

      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={shareOptions}
        selected={null}
        onChange={(values) => null}
      />
      <Styled.SubTitle style={{ marginTop: 32 }}>
        Contract creation: Would you like us to draft an employee agreement for the professional(s)?
        <MoreInfoButton triggerChat={() => handleTriggerChat(contractInfo)} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={contractOptions}
        selected={null}
        onChange={(values) => handleDraftContracts(values[0] === true)}
      />
    </Styled.QuestionWrapper>
  );
}
/*
<Styled.SubTitle style={{ marginTop: 32 }}>
        <strong>Send employee info & contracts directly to G-P:</strong> We'll review each contract
        to make sure it's valid, then send it directly to the professional for signature.
        <MoreInfoButton triggerChat={() => handleTriggerChat(sendInfo)} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={sendToGP}
        selected={null}
        onChange={(values) => handleSendToGP(values[0] === true)}
      />
      */

const contactInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Contacting professionals",
      text: "Once you've reached a tentative agreement with each individual on the job details and terms, we can contact each individual on your behalf and share/discuss those details to help move the process along.",
    },
    sender: MessageSender.GIA,
  },
];

const contractInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Draft Contracts",
      text: "We can help accelerate the hiring process by automatically generating draft contracts for each individual. Don't worry, you'll have the opportunity to review these before hand.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "You can also opt to send the contracts to G-P for immediate review and we'll make sure every element is accounted for.",
    },
    sender: MessageSender.GIA,
  },
];

const sendInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Instantly sending contracts to G-P",
      text: "Our team is ready to handle the contact work for you - from creating the employee agreement all the way through to secufing their signature.",
    },
    sender: MessageSender.GIA,
  },
];
