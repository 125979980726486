import * as Styled from "./Styles.ts";
import Flag, { Country } from "../../../../assets/flags/Flag.tsx";
import { AllProfessionals } from "../../../ListProfessionals/BulkData.ts";
import { ChatMessage, MessageSender, MoreInfo } from "../../../EnumsAndTypes/EnumsAndTypes.ts";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton.tsx";
import { useState } from "react";
import Icon, { IconNames } from "../../../../assets/icons/Icons.tsx";
import { motion } from "framer-motion";
import { useDrawer } from "../../../../contexts/DrawerContext.tsx";
import { ButtonTertiary } from "../../../ButtonTertiary/ButtonTertiary.tsx";
import { colors } from "../../../../assets/tokens/tokens.ts";

interface Props {
  clickInfo?: (moreInfo: MoreInfo) => void;
  clickEmployee?: (id: number) => void;
}

export default function ConfirmUlpoadedData(props: Props) {
  const { clickInfo = () => null, clickEmployee = () => null } = props;
  const { handleTriggerChat } = useDrawer();
  const [index, setIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [edit, setEdit] = useState(false);
  const count = AllProfessionals.length;
  const renderCards = () => {
    const compare = (a: any, b: any) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    };
    const sorted = AllProfessionals.sort(compare);
    const item = sorted[index];
    return (
      <ItemCard
        key={"hires_" + index}
        last={false}
        flag={item.country}
        name={item.name}
        salary={item.salary}
        title={item.title}
        country={item.country}
        currency={item.currency}
        phone={item.phone}
        email={item.email}
        symbol={item.symbol}
        visa={item.visa}
        jobduties={item.jobduties}
        bonus={item.bonus}
        signon={item.signon}
        comission={item.comission}
        morebenefits={item.morebenefits}
        allowance={item.allowance}
        covenants={item.covenants}
        status={item.status}
        startDate={item.startDate}
        showMore={showMore}
        onClick={() => clickEmployee(index)}
        onChange={() => setShowMore(!showMore)}
        onEdit={(value) => setEdit(value)}
        edit={edit}
      />
    );
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Confirm upload details.</Styled.Title>

      <Styled.Description>
        Please review and confirm the imported information is correct.
        <MoreInfoButton triggerChat={() => handleTriggerChat(infoMessage)} />
      </Styled.Description>

      {renderCards()}

      <Styled.Pagging>
        <Icon
          name={IconNames.arrowLeft}
          onClick={() => setIndex(index > 0 ? index - 1 : 0)}
          pointer={true}
          theme={"blueButton"}
        />
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          {`Record: ${index + 1} of ${count}`}
        </div>
        <Icon
          name={IconNames.arrowRight}
          onClick={() => setIndex(index + 1 < count ? index + 1 : count)}
          pointer={true}
          theme={"blueButton"}
        />
      </Styled.Pagging>
    </Styled.QuestionWrapper>
  );
}

interface Props {
  flag?: Country;
  name?: string;
  title?: string;
  last?: boolean;
  salary?: string;
  country?: string;
  email?: string;
  phone?: string;
  currency?: string;
  symbol?: string;
  visa?: boolean;
  jobduties?: string;
  bonus?: boolean;
  signon?: boolean;
  comission?: boolean;
  morebenefits?: boolean;
  allowance?: boolean;
  covenants?: boolean;
  status?: string;
  startDate?: string;
  showMore?: boolean;
  edit?: boolean;
  onClick?: () => void;
  onChange?: () => void;
  onEdit?: (value: boolean) => void;
}
const ItemCard = (props: Props) => {
  const {
    edit,
    flag,
    name,
    title,
    last,
    salary,
    country,
    email,
    phone,
    currency,
    symbol,
    visa = false,
    jobduties = "Agent",
    bonus = false,
    signon = false,
    comission = false,
    morebenefits = false,
    allowance = false,
    covenants = false,
    status = "ready",
    startDate = "2023-12-15T06:44:16.314Z",
    showMore = false,
    onChange = () => null,
    onClick = () => null,
    onEdit = () => null,
  } = props;

  const usa = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
  const germany = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
  const ireland = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
  const dateoptions = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  const formatSalary = (value: any, country: Country) => {
    if (country === Country.Germany) return `${germany.format(parseInt(salary))} ${currency}`;
    if (country === Country.Ireland) return `${ireland.format(parseInt(salary))} ${currency}`;
    return `${usa.format(parseInt(salary))} ${currency}`;
  };

  return (
    <Styled.TableItem $isLast={last} onClick={() => onClick()}>
      <ButtonTertiary
        iconpos="left"
        iconstyle={{ theme: edit ? "ok" : "blueButton" }}
        bgcolor={edit ? colors.green100 : colors.grays200}
        color={edit ? colors.green500 : colors.primaryGPBlue}
        iconname={edit ? IconNames.checkInCircle : IconNames.editCard}
        label={edit ? "done" : "edit"}
        onClick={() => onEdit(!edit)}
        style={{ marginBottom: 16 }}
      />
      <Styled.TableItemHeader>
        <Flag country={flag} size={32} />
        <Styled.Details $isLeft={true} style={{ flexShrink: 1, overflow: "hidden" }}>
          <Styled.Name contentEditable={edit} $edit={edit}>
            {name}
          </Styled.Name>
        </Styled.Details>
        <Styled.Details $isLeft={false}>
          <Styled.Compensation
            contentEditable={edit}
            $edit={edit}
            style={{ flexShrink: 0, whiteSpace: "nowrap" }}
          >
            {formatSalary(salary, flag)}
          </Styled.Compensation>
        </Styled.Details>
      </Styled.TableItemHeader>
      <Styled.LineItem>
        Job Title:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {title}
        </Styled.Value>
      </Styled.LineItem>
      <Styled.LineItem>
        Country:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {country}
        </Styled.Value>
      </Styled.LineItem>
      <Styled.LineItem>
        Currency:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {currency} {symbol}
        </Styled.Value>
      </Styled.LineItem>
      <Styled.LineItem>
        Target Start:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {new Date(startDate).toLocaleDateString("en-US", dateoptions as any)}
        </Styled.Value>
      </Styled.LineItem>
      <Styled.LineItem>
        Email:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {email}
        </Styled.Value>
      </Styled.LineItem>
      <Styled.LineItem>
        Phone:
        <Styled.Value contentEditable={edit} $edit={edit}>
          {phone}
        </Styled.Value>
      </Styled.LineItem>
      <motion.div
        style={{ width: "100%", zIndex: -1, overflow: "hidden" }}
        initial={{ height: showMore ? "auto" : 0, opacity: showMore ? 1 : 0 }}
        animate={{ height: showMore ? "auto" : 0, opacity: showMore ? 1 : 0 }}
      >
        <Styled.LineItem>
          Receives bonus:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!bonus ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{bonus ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Sign on bonus:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!signon ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{signon ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Earns comissions:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!comission ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{comission ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Addtional Benefits:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!morebenefits ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{morebenefits ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Has Allowances:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!allowance ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{allowance ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Added Convenants:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!covenants ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{covenants ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
        <Styled.LineItem>
          Visa support:
          <Styled.LineItemCollapse $edit={edit}>
            <Styled.Value $edit={edit} $selected={false}>
              {!visa ? "Yes" : "No"}
            </Styled.Value>
            <Styled.Value $edit={edit}>{visa ? "Yes" : "No"}</Styled.Value>
          </Styled.LineItemCollapse>
        </Styled.LineItem>
      </motion.div>
      <div
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        onClick={() => onChange()}
      >
        <Styled.LinkButton>{`${showMore ? "less details" : "more details"}`}</Styled.LinkButton>
      </div>
    </Styled.TableItem>
  );
};

const infoMessage: Array<ChatMessage> = [
  {
    message: {
      title: "Confirming uploaded professionals",
      text: "Before continuing, it's important to make sure the information for each professional is accurate.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Scan through different records to make sure the information is correct. If it's note use the modify button to correct the information and the fields mapped from the upload.",
    },
    sender: MessageSender.GIA,
  },
];
