import { useEffect, useState } from "react";
import ADPLogo from "../assets/images/adplogo";
import TrinetLogo from "../assets/images/trinetlogo";
import IpeLogo from "../assets/images/ipelogo";

export function usePartner() {
  const [partnerName, setPartnerName] = useState<string>("ipe");
  const [partnerLogo, setPartnerLogo] = useState(<ADPLogo />);

  useEffect(() => {
    const sub = window.location.host.split(".")[0];
    let name = "ADP";
    if (sub === "adpdemo") name = "ADP";
    if (sub === "trinet") name = "Trinet";
    setPartnerName(name);
  }, []);

  useEffect(() => {
    if (partnerName === "IPE") setPartnerLogo(<IpeLogo height={20} />);
    if (partnerName === "ADP") setPartnerLogo(<ADPLogo height={20} />);
    if (partnerName === "Trinet") setPartnerLogo(<TrinetLogo height={20} />);
  }, [partnerName]);

  return { partnerName, partnerLogo };
}
