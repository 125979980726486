import * as Styled from "../Styles";
import InputFormField from "../../../InputFormField/InputFormField";
import usePageStore from "../../../../callbacks/usePageStore";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";

const mockData = {
  jobTitle: "Front-End Developer",
  startDate: "01-02-2024",
  annualSalary: "85,000",
};

interface Props {
  firstName?: string;
  data?: any;
  onChange?: (data: any) => void;
}

export default function JobDetails(props: Props) {
  const { firstName = "Daniel", data = null, onChange = () => null } = props;
  const [jobData, setJobData] = usePageStore(data);

  const handleChange = (field: string, value: any) => {
    let newData = jobData;
    newData[field] = value;
    setJobData({ ...newData });
    onChange(newData);
  };

  const handleFocus = () => {
    let value = jobData.jobTitle;
    if (!value || value === "") {
      setJobData({ ...mockData });
      onChange(mockData);
    }
  };

  const handleDidValidate = (field: string, state: boolean) => {
    return null;
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Tell us the key details about {firstName}'s job.</Styled.Title>
      <Styled.Description>
        We'll be capturing other details about the job (E.g. bonus, leaves, and benefits) when we
        reach the stage of generating the employment contract.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <Styled.FormFieldsWrapper>
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Job title"
          fieldname="jobTitle"
          type={"text"}
          value={jobData.jobTitle}
          didFocus={() => handleFocus()}
          onChange={(value) => handleChange("jobTitle", value)}
          didValidate={(state) => handleDidValidate("jobTitle", state)}
          errormessage={"Please enter a valid job title."}
        />
        <InputFormField
          button={"left"}
          buttontype={"datepicker"}
          required={false}
          autocomplete={"off"}
          label="Start date"
          fieldname="startDate"
          type={"text"}
          value={jobData.startDate}
          onChange={(value) => handleChange("startDate", value)}
          didValidate={(state) => handleDidValidate("startDate", state)}
          errormessage={"Please enter a valid start date."}
        />
        <InputFormField
          button={"left"}
          buttontype={"currency"}
          required={false}
          autocomplete={"off"}
          label="Annual salary"
          fieldname="annualSalary"
          type={"text"}
          value={jobData.annualSalary}
          onChange={(value) => handleChange("annualSalary", value)}
          didValidate={(state) => handleDidValidate("annualSalary", state)}
          errormessage={"Please enter a valid annual salary amount."}
        />
      </Styled.FormFieldsWrapper>
    </Styled.QuestionWrapper>
  );
}
