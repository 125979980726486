import styled from "styled-components";
import { colors, device, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";
import imgQuoteLrg from "../../assets/images/Thumbnail_Quote_Lrg@3x.png";
import imgQuoteSml from "../../assets/images/Thumbnail_Quote_Sml@3x.png";
import imgContactLrg from "../../assets/images/Thumbnail_Contact_Lrg@3x.png";
import imgContactSml from "../../assets/images/Thumbnail_Contact_Sml@3x.png";

interface Props {
  type: "quote" | "contact";
}

const smallImage = (type: string) => {
  return type === "quote" ? imgQuoteSml : imgContactSml;
};

const largeImage = (type: string) => {
  return type === "quote" ? imgQuoteLrg : imgContactLrg;
};

export const MotionButton = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grays400};
  background-color: ${colors.graysWhite};
  cursor: pointer;
`;

export const Image = styled.div<Props>`
  width: 100px;
  height: 75px;
  display: block;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => smallImage(props.type)});
`;

export const TextWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.graysTextPrimary};
`;

export const Text = styled.p`
  ${type.pMedium};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Glyph = styled.span`
  ${type.pGlyph};
  color: ${colors.primaryGPBlue};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
