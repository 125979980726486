import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import check from "../../assets/images/done_full@24.svg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 24px 48px 40px 48px;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
`;

export const NavSection = styled.div`
  ${type.pMedium};
  line-height: 1.8em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
`;

export const NavButton = styled.div`
  ${type.pMedium}
  color:${colors.graysTextPrimary};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  background: ${colors.grays100};
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  margin-top: 24px;
  max-width: 550px;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const SubTitle = styled.p`
  ${type.h3};
  color: ${colors.graysTextPrimary};
  display: flex;
  line-height: 1.5em;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const List = styled.ul`
  display: flex;
  ${type.pRegular}
  flex-direction: column;
  margin: 0;
  padding: 0;
  opacity: 0.8;
`;

export const ListItem = styled.li`
  ${type.pRegular};
  color:${colors.graysTextPrimary}
  list-style-position: outside;
  list-style-type: none;
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  line-height: 1.2em;
  padding: 0;
  background-image: url(${check});
  background-repeat: no-repeat;
  line-height: 24px;
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
