import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, type } from "../../assets/tokens/tokens";

export const variants = {
  framed: {
    blurred: { borderColor: colors.grays100 },
    focused: { borderColor: colors.primaryGPBlue },
    disabled: { borderColor: colors.grays100 },
    invalid: { borderColor: colors.error },
  },
  unframed: {
    blurred: { borderColor: colors.grays100 },
    focused: { borderColor: colors.primaryGPBlue },
    disabled: { borderColor: colors.grays100 },
    invalid: { borderColor: colors.error },
  },
  white: {
    blurred: { borderColor: colors.grays100 },
    focused: { borderColor: colors.primaryGPBlue },
    disabled: { borderColor: colors.grays100 },
    invalid: { borderColor: colors.error },
  },
};

const setPadding = (theme: string, showicon: boolean) => {
  const framed = theme === "framed" || theme === "white";
  if (framed && showicon) return "padding: 0px 8px 0px 8px";
  if (framed && !showicon) return "padding: 0px 8px 0px 16px";
  return "padding: 0";
};

const setBG = (theme: string) => {
  if (theme === "unframed") return "transparent";
  if (theme === "framed") return colors.grays100;
  return colors.graysWhite;
};

export const FieldWrapper = styled(motion.div)<{
  $showicon: boolean;
  $theme: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 48px;
  width: 100%;
  gap: 4px;
  ${(p) => setPadding(p.$theme, p.$showicon)};
  border-radius: ${(p) => (p.$theme === "unframed" ? "0px" : "8px")};
  background-color: ${(p) => setBG(p.$theme)};
  border: ${(p) => (p.$theme === "unframed" ? "0px" : "1px")} solid ${colors.graysWhite};
`;

export const RightIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LeftIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: default;
`;

export const ErrorIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputField = styled.input`
  ${type.pMedium};
  color: ${colors.graysTextTertiary};
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  &::placeholder {
    color: ${colors.graysTextDisabled};
  }
`;
