import { AnimatePresence, motion, useDragControls } from "framer-motion";
import InputMessage from "../InputMessage/InputMessage";
import { ChatMessage } from "../EnumsAndTypes/EnumsAndTypes";
import * as Styled from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";
import profilepic from "../../assets/images/camila@2x.png";
import { useContract } from "../../contexts/ContractContext";

type InputComment = {
  position?: { left: number | string; top: number | string };
  selection?: { text?: string; from: number; to: number };
  author?: string;
  timestamp?: string;
};

interface Props {
  constraints?: any;
  onSend?: (comment: string) => void;
}

export function InputFloatingMessageBox(props: Props) {
  const { onSend = () => null, constraints } = props;
  const {
    showCommentPopUp,
    handleShowCommentPopUp,
    inputInfo,
    handleSetComment,
    handleCancelComment,
  } = useContract();
  const controls = useDragControls();
  const startDrag = (event: any) => {
    controls.start(event);
  };
  return (
    <AnimatePresence initial={false}>
      {showCommentPopUp && (
        <Styled.Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            left: inputInfo.position.left,
            top: inputInfo.position.top,
          }}
          drag={true}
          dragConstraints={constraints}
          dragControls={controls}
          dragListener={false}
          dragMomentum={false}
          dragElastic={0}
        >
          <Styled.Header className="noselect" onPointerDown={startDrag}>
            <Styled.Author>
              <img src={profilepic} width={32} />
              {inputInfo.author}
            </Styled.Author>
            <Icon
              name={IconNames.x}
              pointer={true}
              onClick={() => {
                handleCancelComment(inputInfo);
                handleShowCommentPopUp(false);
              }}
            />
          </Styled.Header>
          <Styled.Text className="noselect" onPointerDown={startDrag}>
            "{inputInfo.selection.text}"
          </Styled.Text>
          <InputMessage
            size={"small"}
            height={"21px"}
            placeholder="Enter comment"
            onSendMessage={(message: ChatMessage) =>
              handleSetComment({ info: inputInfo, text: message.message.text })
            }
          />
        </Styled.Container>
      )}
    </AnimatePresence>
  );
}
