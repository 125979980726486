import * as Styled from "../Styles";
import InputFormField from "../../../InputFormField/InputFormField";
import usePageStore from "../../../../callbacks/usePageStore";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";

const mockData = {
  firstName: "Daniel",
  lastName: "Smith",
  email: "danielsmith22@gmail.com",
  phone: "7911 123456",
};

interface Props {
  data?: {};
  onChange?: (data: {}) => void;
}

export default function PersonalInfo(props: Props) {
  const { data = null, onChange = () => null } = props;
  const [personalInfo, setPersonalInfo] = usePageStore(data);
  const handleChange = (field: string, value: any) => {
    let newData = personalInfo;
    newData[field] = value;
    setPersonalInfo({ ...newData });
    onChange(newData);
  };
  const handleDidValidate = (field: string, state: boolean) => {
    return null;
  };
  const handleFocus = () => {
    let value = personalInfo.firstName;
    if (!value || value === "") {
      setPersonalInfo({ ...mockData });
      onChange(mockData);
    }
  };
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Tell us about the professional.</Styled.Title>
      <Styled.Description>
        We'll use this information to pull together an employee agreement and the contact info. to
        communicate with the professional.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <Styled.FormFieldsWrapper>
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Legal first name"
          fieldname="firstName"
          type={"text"}
          value={personalInfo.firstName}
          onChange={(value) => handleChange("firstName", value)}
          didValidate={(state) => handleDidValidate("firstName", state)}
          didFocus={() => handleFocus()}
          errormessage={"Please enter a valid first name."}
        />
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Legal last name"
          fieldname="lastName"
          type={"text"}
          value={personalInfo.lastName}
          onChange={(value) => handleChange("lastName", value)}
          didValidate={(state) => handleDidValidate("lastName", state)}
          errormessage={"Please enter a valid last name."}
        />
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Personal email"
          fieldname="email"
          type={"email"}
          value={personalInfo.email}
          onChange={(value) => handleChange("email", value)}
          didValidate={(state) => handleDidValidate("email", state)}
          errormessage={"Please enter a email."}
        />
        <InputFormField
          button={"left"}
          buttontype={"area code"}
          required={false}
          autocomplete={"off"}
          label="Personal mobile number"
          fieldname="phone"
          type={"phone"}
          value={personalInfo.phone}
          onChange={(value) => handleChange("phone", value)}
          didValidate={(state) => handleDidValidate("phone", state)}
          errormessage={"Please enter a valid phone number."}
        />
      </Styled.FormFieldsWrapper>
    </Styled.QuestionWrapper>
  );
}
