import Icon, { IconNames } from "../../assets/icons/Icons";
import { colors } from "../../assets/tokens/tokens";
import * as Styled from "./Styles";

interface Props {
  bgcolor?: string;
  color?: string;
  label?: string;
  iconpos?: "left" | "right" | "none";
  iconname?: IconNames;
  iconstyle?: { size?: number; stroke?: number; theme?: string };
  onClick?: () => void;
  style?: any;
}

export function ButtonTertiary(props: Props) {
  const {
    bgcolor = colors.grays100,
    color = colors.primaryGPBlue,
    label = "label",
    iconpos = "left",
    iconname = IconNames.arrowRight,
    iconstyle = { size: 18, stroke: 1.5, theme: "blueButton" },
    onClick = () => null,
    style = null,
  } = props;

  return (
    <Styled.Wrapper
      style={style}
      $color={color}
      $bgcolor={bgcolor}
      $iconpos={iconpos}
      onClick={() => onClick()}
    >
      {iconpos === "left" && (
        <Icon
          name={iconname}
          stroke={iconstyle.stroke}
          size={iconstyle.size}
          theme={iconstyle.theme}
        />
      )}
      {label}
      {iconpos === "right" && (
        <Icon
          name={iconname}
          stroke={iconstyle.stroke}
          size={iconstyle.size}
          theme={iconstyle.theme}
        />
      )}
    </Styled.Wrapper>
  );
}
