import { Product } from "../../../assets/images/Products";

type Info = {
  title?: string;
  text?: string;
};

enum CostType {
  Base,
  Optional,
  Other,
}

type CardInfo = {
  type?: CostType;
  name?: string;
  value?: string;
  valuetype?: string;
  valuequalifyer?: string;
  strikeout?: boolean;
  image?: Product;
  description?: string;
  valueprops?: string[];
  disclaimer?: string;
  maxvalueprop?: number;
  info?: Info;
};

const QuoteCards = [
  {
    type: CostType.Base,
    name: "G-P Meridian Core™",
    value: "$499",
    valuequalifyer: "$799",
    strikeout: true,
    valuetype: "p/team member/month",
    image: Product.Core,
    description:
      "Our base EOR Platform with all the capabilities you need to expand your business.",
    disclaimer:
      "* Pricing applies to agreements signed before the 31st of January, 2024. Please review with a G-P expert for promotional details.",
    valueprops: [
      "End-to-end digitized onboarding",
      "Locally-compliant employment",
      "Contract management",
      "Statutory and supplemental benefits guidance",
      "Payroll management including annual tax filings",
      "Time and expense management",
      "HCM integrations and API access",
    ],
    maxvalueprop: 5,
    info: {
      title: "G-P Meridian Core™",
      text: "With G-P Meridian Core, you'll find all the essential tools you need to, hire, and manage your global workforce, all with unmatched global and local compliance, and unbeatable speed to any market you choose.",
    },
  },
  {
    type: CostType.Base,
    name: "One Time Setup\nG-P Meridian Core™",
    value: "$0",
    valuequalifyer: null,

    valuetype: "at account creation",
    image: null,
    description:
      "With our one-time setup, you'll get a G-P account with guidance on key entitlements and access to our knowledge base.",

    valueprops: null,
    maxvalueprop: 5,
    info: {
      title: "On Time Setup",
      text: "With our one-time setup, you'll get a G-P account with guidance on key entitlements and access to our knowledge base.",
    },
  },
  {
    type: CostType.Base,
    name: "Off Boarding",
    value: "$0",
    valuequalifyer: "$850",
    strikeout: true,
    valuetype: "p/team member",
    disclaimer:
      "* Pricing applies to agreements signed before the 31st of January, 2024. Please review with a G-P expert for promotional details.",
    image: null,
    description:
      "Every time a team member is off boarded or transitioned off the G-P platform, we ensure all legal, compliance and operational requirements are met.",
    valueprops: null,
    maxvalueprop: 5,
    info: {
      title: "Employee Off Boarding",
      text: "Every time a team member is off boarded or transitioned off the G-P platform, we ensure all legal, compliance and operational requirements are met.",
    },
  },
] as CardInfo[];

export default QuoteCards;

// ** removed for drop in focus
const Recruit = [
  {
    type: CostType.Optional,
    name: "G-P Recruit™",
    value: "20%",
    valuequalifyer: "starts at",
    valuetype: "on hire, % base comp",
    image: Product.Recruit,
    description:
      "G-P Recruit enables you to hire the right candidate in the right country, at the right cost with end-to-end guidance from our local recruitment specialists.",
    valueprops: null,
    maxvalueprop: 5,
    info: {
      title: "G-P Recruit™",
      text: "Find, attract, and recruit the best talent at the right cost globally, through our all-in-one recruitment platform. Access local expertise, insights, and talent pools through our network of recruitment specialists. Easily manage the entire HR process from recruitment and a locally compliant employment contract through to onboarding, without having to navigate multiple platforms.",
    },
  },
  {
    type: CostType.Optional,
    name: "Background Checks",
    value: "$150",
    valuequalifyer: "starts at",
    valuetype: "p/check",
    image: null,
    description:
      "Background check costs vary by complexity and country. The price quoted is for a basic UK background check.",
    valueprops: null,
    maxvalueprop: 5,
    info: {
      title: "Background Checks",
      text: "Get visibility on locally permissible background checks. Verify the accuracy of an applicant's assertions and ensure they are who they say they are. Optimize your recruitment and selection process with a single solution by requesting background checks without leaving the G-P Meridian platform.",
    },
  },
];
