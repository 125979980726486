import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const themeStyles = {
  white: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextDisabled,
      borderColor: colors.grays200,
    },
    active: {
      backgroundColor: colors.grays400,
      color: colors.graysSystem,
      borderColor: colors.grays400,
    },
    pressed: {
      scale: 0.98,
    },
    hover: {
      scale: 1.04,
    },
  },
  link: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextDisabled,
      borderColor: colors.grays200,
      textDecoration: "none",
    },
    active: {
      backgroundColor: colors.graysWhite,
      color: colors.primaryGPBlue,
      borderColor: colors.graysWhite,
      textDecoration: "none",
    },
    pressed: {
      scale: 0.98,
    },
    hover: {
      scale: 1.04,
      textDecoration: "underline",
    },
  },
  blue: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextDisabled,
      borderColor: colors.grays300,
    },
    active: {
      backgroundColor: colors.primaryGPBlue,
      color: colors.graysWhite,
      borderColor: colors.primaryGPBlue,
    },
    pressed: {
      scale: 0.98,
    },
    hover: {
      scale: 1.04,
    },
  },
  orange: {
    disabled: {
      backgroundColor: colors.graysWhite,
      color: colors.graysTextDisabled,
      borderColor: colors.grays300,
    },
    active: {
      backgroundColor: colors.warning200,
      color: colors.graysTextPrimary,
      borderColor: colors.warning200,
    },
    pressed: {
      scale: 0.98,
    },
    hover: {
      scale: 1.04,
    },
  },
};

const setPadding = (iconpos: "left" | "right" | "none") => {
  if (iconpos === "left") return "6px 20px 4px 14px";
  if (iconpos === "right") return "6px 14px 4px 20px";
  return "6px 20px 4px 20px";
};

export const Button = styled(motion.div)<{
  $state: string;
  $theme: string;
  $icononly: boolean;
  $label: string;
  $iconpos: "left" | "right" | "none";
  $length: "auto" | number;
  $size: "regular" | "small";
}>`
  ${(p) => (p.$size === "regular" ? type.btnRegular : type.pSmall)};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: ${(p) => (p.$size === "regular" ? 46 : 32)}px;
  width: ${(p) => (p.$icononly ? "72px" : p.$length !== "auto" ? p.$length + "px" : "auto")};
  padding: ${(p) => setPadding(p.$iconpos)};
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: ${(p) => (p.$state === "disabled" ? "default" : "pointer")};
`;
