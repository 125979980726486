// onctract
export type Reply = {
  author?: string;
  text?: string;
  datetime?: string;
  parent?: number;
};
export type Comment = {
  fragment?: string;
  selection?: { from?: number; to?: number };
  resolved?: boolean;
  text?: string;
  timestamp?: string;
  author?: string;
};
export type InputInfo = {
  position?: { left: number | string; top: number | string };
  selection?: { text?: string; from: number; to: number };
  author?: string;
};

// ***PROS LIST
export const enum ProStatus {
  editing = "editing",
  ready = "ready",
  sent = "sent",
  signed = "signed",
  active = " active",
}

// ***NO MSA
export enum NoMSADrawerAction {
  Cancel,
  Next,
  Previous,
  DoubleBack,
  Done,
  Restart,
  ToggleGIA,
  Close,
  GPLoaded,
  LoadQuote,
  LoadCallBack,
  LoadSchedule,
  LoadContactUs,
  ConnectLiveAgent,
  LoadGetStarted,
}
export const enum NoMSAView {
  Connecting,
  GetStarted,
  ContactUs,
  Quote,
  Schedule,
  CallbBack,
}

// ADP screens
export const enum ADPScreens {
  HomeView,
  MenuView,
  ListView,
  ListMenu,
}

// ** professional data store
export type ProfDataStore = {
  country: any;
  firstName: any;
  lastName: any;
  phone: any;
  email: any;
  jobTitle: any;
  annualSalary: any;
  startDate: any;
  shareOption: any;
};

// ** more info */
export type MoreInfo = {
  title: string;
  text: string;
};

// ** core navigation view */
export const enum View {
  Connecting,
  AddSingle,
  BulkAdd,
  ListPros,
  AcceptTerms,
  SubmitContracts,
  WhatHappensNext,
}

export const enum Direction {
  forward = 1,
  back = -1,
}

export const enum NavType {
  Goto,
  Next,
  Back,
}

// ** accept terms pages * //
export const enum ServiceTerms {
  Overview = 0,
  ServiceAndPricing = 1,
  CurrentAndPayment = 2,
  PrivacyPolicy = 3,
  TermsOfUse = 4,
}

//** Drawer footer */
export enum DrawerAction {
  Cancel,
  Next,
  Previous,
  Done,
  Restart,
  ToggleGIA,
  AddMore,
  AddLater,
  Close,
  ListPros,
  AcceptTerms,
  UploadDone,
  AddProProgress,
  AddProProgressBulk,
  ADPList,
  GPLoaded,
  DidAddProfessional,
  BulkEdit,
  SubmitContracts,
  BackListPros,
  BackToUpload,
  SendContracts,
  CreateContract,
  WhatHappensNext,
  ConnectLiveAgent,
}

//** GIA Chat */
export const enum MessageSender {
  GIA = 0,
  Info = 1,
  System = 2,
  Function = 3,
  User = 4,
}

export type ChatMessage = {
  isResponding?: boolean;
  message?: { title?: string; text: string };
  sender?: MessageSender;
  display?: boolean;
  files?: MessageFile[];
  actions?: MessageAction[];
  function?: string;
};

export type MessageFile = {
  name?: string;
  size?: string;
  type?: string;
  image?: any;
};

export type MessageAction = {
  name?: string;
  type?: "nav" | "other" | "link" | "audio" | string;
};

export const enum LiveConnection {
  Connected,
  Disconnected,
  Connecting,
  Disconnecting,
}
