import { MessageSender } from "../EnumsAndTypes/EnumsAndTypes";

// ** SCHEDULE PHONE CALL FUNCTION **//
export const fResponse = (name: string, params: any) => {
  if (name == "schedule_sales_call") return schedule_sales_call(params);
};
export function want_talk_to_sales(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "Ok. I can help you schedule a call with a globalization guide. Would you like me to bring that up?",
  };
  return response;
}
export function schedule_sales_call(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "Sure. It looks like the earliest we can do is tomorrow. Pick a day an time that works for you",
  };
  return response;
}
export function goto_learn_more_EOR(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content: "Ok. Here are some great resources about EOR for you.",
  };
  return response;
}
export function want_to_learn_more_EOR(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "If you're curious about EOR I suggest you look at our learn more resources - they'll give you everyting you need. Do you want me to bring this up for you?",
  };
  return response;
}
export function want_to_learn_the_price_options_EOR(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "If you're trying to get a sense for pricing, our configurator can help determine the best setup for you and corresponding pricing estimates. Did you want me to pull that up for you?",
  };
  return response;
}
export function goto_EOR_quote(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "Great. We'll ask you a few questions to recommend the right setup and give you a sense of pricing. Shouldn't take more than a few minutes.",
  };
  return response;
}
export function want_to_try_or_demo(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "We do have a limited trial or demo of our EOR you can sign up for. That way you can get a better sense for how it all works. Do you want me to bring that up for you?",
  };
  return response;
}
export function goto_try_or_demo(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "Ok. We'll create a company account for you to test drive the EOR platform and solution for yourself.",
  };
  return response;
}
export function start_live_session(parameters) {
  let response = {
    name: null,
    role: MessageSender.GIA,
    content:
      "Ok. I'm avaliable to connect with you live. Click the button below to start a live session with me.",
  };
  return response;
}

//** LIST OF CHATGPT FUNCTIONS **//
export const functionList = [
  {
    name: "schedule_sales_call",
    description:
      "If the user says 'yes' to talk to sales, customer support or live person, run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "want_talk_to_sales",
    description:
      "If the user asks to talk to sales, customer support or any live person, run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "goto_learn_more_EOR",
    description:
      "If the user says 'yes' to wanting to goto to the learn more section for EOR then run this function",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "want_to_learn_more_EOR",
    description:
      "If the user is curious about EOR and/or asks to learn more about EOR run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "want_to_learn_the_price_options_EOR",
    description:
      "If the user is curious about the price and options we have for EOR solutions, run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "goto_EOR_quote",
    description:
      "If the user says 'yes' to wanting to get a sense for the options and prices for our EOR solutions, run this function.",
    parameters: { type: "object", properties: {} },
  },

  {
    name: "want_to_try_or_demo",
    description:
      "If the user is looking for or asking about a way to try or demo the solution for EOR, run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "goto_try_or_demo",
    description:
      "If the user says 'yes' to wanting to try or demo the EOR solution or platform, run this function.",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "start_live_session",
    description:
      "If the user says requests to talk in a human being, person or wants to talk to you live, run this function.",
    parameters: { type: "object", properties: {} },
  },
];

function cleanParams(parameters) {
  // parse parameters from GTP (these are not well formatted)
  let params = parameters.replace(/\s+/g, ""); // remove spaces
  params = params.replace(/['"]+/g, ""); // remove quote characters
  params = params.replace(/\\n/g, ""); // remove new lines characters
  params = params.replace("{", ""); // remove curly braces
  params = params.replace("}", ""); // remove curly braces
  params = params.toLowerCase(); // set all characters to lowercase
  return params;
}
