import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Container = styled(motion.div)<{ $active: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  overflow: hidden;
  padding: 8px 16px;
  maring: 0;
  border-bottom: 1px solid ${colors.grays300};
  background-color: ${colors.graysWhite};
  gap: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Comment = styled(motion.div)<{ $isParent: boolean }>`
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: ${(p) => (p.$isParent ? 0 : "16px")} 0 16px 0;
  maring: 0;
  ${(p) => (!p.$isParent ? "border-top: 1px solid " + colors.grays200 : null)};
`;

export const Header = styled.div`
  ${type.pSmall};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  maring: 0;
`;

export const Text = styled.div`
  ${type.pSmall};
  box-sixing: border-box;
  font-size: 14px;
  line-height: 1.4em;
  letter-spacing: 0.015em;
  padding: 0;
  margin: 0;
`;

export const Fragment = styled.div`
  ${type.pSmall};
  box-sixing: border-box;
  font-size: 12px;
  text-overflow: ellipsis;
  line-height: 1.4em;
  letter-spacing: 0.015em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${colors.graysTextDisabled};
  padding: 0;
  margin: 0;
`;

export const Author = styled.div`
  ${type.pSmall};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 8px 0;
  maring: 0;
  gap: 8px;
`;

export const Link = styled.div`
  ${type.pSmall};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0 0 0;
  maring: 0;
  color: ${colors.primaryGPBlue};
  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SmallText = styled.span`
  ${type.caption};
  line-height: 1.2em;
  color: ${colors.graysTextDisabled};
`;

export const Input = styled.div<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? "block" : "none")};
  box-sizing: border-box;
  padding: 0px 0 8px 0;
  width: 100%;
  border-top: 1px solid ${colors.graysWhite100};
`;
