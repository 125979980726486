import { useEffect, useRef, useState } from "react";
import Flag, { Country } from "../../assets/flags/Flag";
import Icon, { IconNames } from "../../assets/icons/Icons";
import GPLogo from "../../assets/images/gplogo";
import { colors } from "../../assets/tokens/tokens";
import CollaborativeEditor from "../../components/Editor/Editor";
import NavButton, { State } from "../../components/NavButton/NavButton";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { ProsProvider } from "../../contexts/ProfessionalsContext";
import * as Styled from "./Styles";
import { ContractMenu } from "../../components/ContractMenu/ContractMenu";
import { ContractProvider, useContract } from "../../contexts/ContractContext";
import { AnimatePresence, motion } from "framer-motion";
import Overlay from "../../components/Overlay/Overlay";
import { CommentsList } from "../../components/CommentsList/CommentsList";
import { Modal } from "../../components/Modal/Modal";
import { AnimatedOverlay } from "../../components/Overlay/AnimatedOverlay";
import { useLocalStore } from "../../hooks/useLocalStore";
import ProgressIndicator from "../../components/ProgressIndicator/ProgressIndicator";
import { usePartner } from "../../hooks/usePartner";

export default function ContactViewer() {
  const [contractSent, setContractSent] = useLocalStore("contractSent");
  const [didSend, setDidSend] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const contractBox = useRef(null);
  const [ref, setRef] = useState(null);
  const [confirmSend, SetConfirmSend] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => setRef(contractBox), [contractBox]);

  const getID = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");
    if (email !== "") return email;
    return "default_contract";
  };

  const setName = () => {
    const name = urlParams.get("name")?.split(" ")[0];
    return name ? name : "Daniele";
  };

  const setFullName = () => {
    const name = urlParams.get("name");
    return name ? name : "Adalberto Beahan";
  };

  const setEmail = () => {
    const email = urlParams.get("email");
    return email ? email : "danielsmith22@gmail.com";
  };

  const setSalary = () => {
    const salary = urlParams.get("salary");
    return salary ? salary : "$100,000.00";
  };

  const setRole = () => {
    const role = urlParams.get("role");
    return role ? role : "Tester";
  };

  const handleSendContract = () => {
    const payload = {
      name: setName(),
      email: setEmail(),
      state: true,
    };
    setContractSent(payload);
  };

  return (
    <ProsProvider>
      <DrawerProvider>
        <ContractProvider>
          <Styled.Page>
            <AnimatedOverlay
              show={confirmSend}
              zIndex={999}
              onClick={() => SetConfirmSend(false)}
            />
            <Modal size={{ width: 325, height: 325 }} visible={confirmSend}>
              <Styled.ModalTitle>
                {didSend
                  ? "Contract Sent"
                  : isSending
                  ? "Sending Contract"
                  : `Send contract to ${setName()}?`}
              </Styled.ModalTitle>
              <AnimatePresence>
                {isSending && (
                  <ProgressIndicator
                    size={64}
                    stroke={1}
                    status={{ working: "Sending contract", done: "Done" }}
                    playing={true}
                    loops={8}
                    progressEnd={() => setDidSend(true)}
                    type={"check"}
                    nocaption={true}
                  />
                )}
                {!isSending && (
                  <Styled.ModalText>
                    {`We'll email ${setName()} at ${setEmail()} with this work contract. ${setName()} will be asked to login in to G-P's platform to review and sign the contract.`}
                  </Styled.ModalText>
                )}
              </AnimatePresence>
              <Styled.ButtonGroup>
                {!didSend && (
                  <NavButton
                    label={"Cancel"}
                    state={isSending ? State.disabled : State.active}
                    onClick={() => {
                      setContractSent(null);
                      setDidSend(false);
                      SetConfirmSend(!confirmSend);
                    }}
                  />
                )}
                {!didSend && (
                  <NavButton
                    iconpos={"left"}
                    iconstyle={{ size: 22, stroke: 1.5 }}
                    icon={IconNames.arrowRight}
                    label={"Send Contract"}
                    state={isSending ? State.disabled : State.active}
                    theme={"blue"}
                    onClick={() => {
                      setIsSending(true);
                      handleSendContract();
                    }}
                  />
                )}
                {didSend && (
                  <NavButton
                    label={"Done"}
                    theme={"blue"}
                    onClick={() => {
                      SetConfirmSend(false);
                    }}
                  />
                )}
              </Styled.ButtonGroup>
            </Modal>
            <Header />
            <ContractMenu />
            <Styled.DocumentWrapper>
              <Styled.Contract ref={contractBox}>
                <Legal />
                <CollaborativeEditor
                  contractBox={ref}
                  contractID={getID()}
                  data={{ name: setFullName(), role: setRole(), salary: setSalary() }}
                />
              </Styled.Contract>
              <CommentsList />
            </Styled.DocumentWrapper>
            <Warning didSend={didSend} />
            <BotomBar sendClick={() => SetConfirmSend(!confirmSend)} contractSent={didSend} />
            <MenuOverlay />
          </Styled.Page>
        </ContractProvider>
      </DrawerProvider>
    </ProsProvider>
  );
}

export const MenuOverlay = () => {
  const { showMenu, handleShowMenu } = useContract();
  return <Overlay color={"rgba(0,0,0,0)"} show={showMenu} onClick={() => handleShowMenu(false)} />;
};

export const Legal = () => {
  return (
    <Styled.Legal>
      This employment contract is being provided in draft form for review purposes only.
      Globalization Partners will not accept types or handwritten changes made to this draft as
      final terms, and such changes will not form a part of the terms and conditions of employment
      with globalization partners unless globalization partners specifically agrees to such changes
      in writing.
    </Styled.Legal>
  );
};

export const Header = () => {
  const { handleSetFocus, handleShowMenu, showMenu } = useContract();
  const { partnerLogo } = usePartner();
  return (
    <Styled.Header onClick={() => handleSetFocus()}>
      <Styled.Logo>
        <GPLogo height={24} /> + {partnerLogo}
      </Styled.Logo>
      <Styled.Sections onClick={() => handleShowMenu(!showMenu)}>
        Contract Sections
        <Caret size={18} stroke={2} show={showMenu} />
      </Styled.Sections>
      <Icon name={IconNames.x} size={32} onClick={() => window.close()} pointer={true} />
    </Styled.Header>
  );
};

export const Warning = (props: { didSend: boolean }) => {
  return (
    props.didSend && <Styled.Warning>Contract Is Pending Professional's Singature</Styled.Warning>
  );
};

interface BottomBarProps {
  sendClick?: () => void;
  contractSent?: boolean;
}

export const BotomBar = (props: BottomBarProps) => {
  const { sendClick = () => null, contractSent = false } = props;
  const { viewComments, handleSetViewComments } = useContract();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return (
    <Styled.BottomBar>
      <Styled.ProfessionalID>
        <Flag country={Country.Canada} size={32} />
        {urlParams.get("name")} | Employment Contract
      </Styled.ProfessionalID>
      <Styled.ButtonGroup>
        <NavButton
          iconpos={"left"}
          iconstyle={{ size: 20, stroke: 2 }}
          icon={IconNames.comment}
          label={viewComments ? "Hide Comments" : "View Comments"}
          onClick={() => handleSetViewComments(!viewComments)}
        />
        <NavButton
          iconpos={"left"}
          iconstyle={{ size: 22, stroke: 1.5 }}
          icon={contractSent ? IconNames.checkInCircle : IconNames.sendEmail}
          label={contractSent ? "Done" : `Send to ${urlParams.get("name")?.split(" ")[0]}`}
          state={urlParams.get("edit") === "disabled" ? State.disabled : State.active}
          theme={"blue"}
          onClick={() => {
            if (contractSent) window.close();
            else sendClick();
          }}
        />
      </Styled.ButtonGroup>
    </Styled.BottomBar>
  );
};

const Caret = (props: { show: boolean; size: number; stroke: number }) => {
  const variants = {
    on: { rotate: 180 },
    off: { rotate: 0 },
  };
  return (
    <motion.div
      style={{
        display: "flex",
        width: props.size,
        height: props.size,
        justifyContent: "center",
        alignItems: "center",
      }}
      initial={"off"}
      animate={props.show ? "on" : "off"}
      variants={variants}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox="0 0 24 24"
      >
        <path
          d="M 12 6 L 6 0 L 0 6"
          transform="translate(6 9) rotate(180 6 3)"
          fill="transparent"
          strokeWidth={props.stroke}
          stroke={colors.graysTextPrimary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray=""
        />
      </svg>
    </motion.div>
  );
};
