import * as Styled from "./Styles";
import InputFormField from "../InputFormField/InputFormField";
import usePageStore from "../../callbacks/usePageStore";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import { useEffect } from "react";
import { Country } from "../../assets/flags/Flag";

const mockData = {
  name: "Janice Jenkins",
  email: "janicej@acme.com",
  phone: "(617) 671-5587",
};

interface Props {
  data?: {};
  onChange?: (data: {}) => void;
}

export default function Contacts(props: Props) {
  const { data = null, onChange = () => null } = props;
  const [personalInfo, setPersonalInfo] = usePageStore(mockData);
  const handleChange = (field: string, value: any) => {
    let newData = personalInfo;
    newData[field] = value;
    setPersonalInfo({ ...newData });
    onChange(newData);
  };
  const handleDidValidate = (field: string, state: boolean) => {
    return null;
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Get a Call Back</Styled.Title>
      <Styled.Description>
        During business hours, we'll call you back within 2 minutes. Call requests outside business
        hours are scheduled for the next business day.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <Styled.FormFieldsWrapper>
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Name"
          fieldname="name"
          type={"text"}
          value={personalInfo.name}
          onChange={(value) => handleChange("name", value)}
          didValidate={(state) => handleDidValidate("name", state)}
          errormessage={"Please enter a valid name."}
        />
        <InputFormField
          required={false}
          autocomplete={"off"}
          label="Company Email"
          fieldname="email"
          type={"email"}
          value={personalInfo.email}
          onChange={(value) => handleChange("email", value)}
          didValidate={(state) => handleDidValidate("email", state)}
          errormessage={"Please enter a email."}
        />
        <InputFormField
          button={"left"}
          buttontype={"area code"}
          flag={Country.USA}
          buttontext={"+1"}
          required={false}
          autocomplete={"off"}
          label="Phone number"
          fieldname="phone"
          type={"phone"}
          value={personalInfo.phone}
          onChange={(value) => handleChange("phone", value)}
          didValidate={(state) => handleDidValidate("phone", state)}
          errormessage={"Please enter a valid phone number."}
        />
      </Styled.FormFieldsWrapper>
    </Styled.QuestionWrapper>
  );
}
