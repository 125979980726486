import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";

export const Wrapper = styled.div`
  ${type.pMedium};
  display: flex;
  flex-directtion: row;
  justfy-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  line-height: 1.6em;
  user-select: none;
  gap: 8px;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Text = styled.span`
  display: inline;
  margin-right: 6px;
`;

export const Link = styled.a`
  display: inline;
  margin-right: 6px;
  text-decoration: none;
  color: ${colors.primaryGPBlue};
  &:hover {
    text-decoration: underline;
  }
`;
