import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";

export const FieldContainer = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0px;
`;

export const ErrorMessage = styled.div<{ $show: boolean }>`
  box-sizing: border-box;
  ${type.pSmall};
  color: ${colors.error};
  position: absolute;
  bottom: -38px;
  left: 0px;
  right: 0px;
  display: ${(p) => (p.$show ? "block" : "none")};
  z-index: ${(p) => (p.$show ? 10 : 0)};
  background-color: ${colors.errorBG};
  margin: 0;
  height: 34px;
  padding: 8px 12px;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FieldName = styled.p`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
