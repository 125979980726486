import styled from "styled-components";
import { EditorContent, BubbleMenu } from "@tiptap/react";
import { colors } from "../../assets/tokens/tokens";

export const CommentMenu = styled(BubbleMenu)`
  transform: translateY(50%);
`;

export const Editor = styled(EditorContent)`
  .tiptap {
    padding: 40px 80px 80px 80px;
    box-sizing: border-box;
    font-family: "IBMPlexMono-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
    font-style: normal;
    background-color: white;
    font-size: 15px;
    color: black;
    letter-spacing: 0.01em;
    margin: 0 0 48px 0;
    width: 100%;
    max-width: 1024px;
    border: 1px solid #edf0f3;
    outline: none;
    border-radius: 8px;
  }
  .tiptap ::selection {
    background-color: ${colors.grays400};
  }

  .tiptap strong {
    font-family: "IBMPlexMono-Bold";
    font-weight: normal;
    font-style: normal;
  }

  .tiptap em {
    font-family: "IBMPlexMono-RegularItalic";
    font-weight: normal;
    font-style: normal;
  }

  .tiptap strong em,
  .tiptap em strong {
    font-family: "IBMPlexMono-SemiBoldItalic";
    font-weight: normal;
    font-style: normal;
  }

  .tiptap p {
    line-height: 2em;
    margin-bottom: 1.5em;
    margin-top: 0;
  }
  .tiptap ul {
    list-style-position: outside;
    margin: 0;
    padding: 0;
  }
  .tiptap ol {
    list-style-position: outside;
    margin: 0;
    padding: 0;
  }

  .tiptap ul {
    margin-left: 1em;
  }

  .tiptap ol li {
    margin-left: 1.5em;
  }

  .tiptap ol li p,
  .tiptap ul li p {
    margin: 0;
  }
  span span.collaboration-cursor__caret {
    font-size: 10px;
  }
  .tiptap img {
    display: block;
    margin: 0 auto 24px auto;
  }
`;
