import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useRef, useState, useEffect } from "react";
import { transitions } from "../../assets/tokens/tokens";
import usePageStore from "../../callbacks/usePageStore";
import { Direction } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import Contacts from "./Contacts";
import RequestingCallBack from "./RequestingCallBack";
import CallBackConfirmed from "./CallBackConfirmed";

const dataDefault = {
  name: null,
  phone: null,
  email: null,
  accept: null,
};

export default function CallBackView() {
  const { callbackPageIndex, handleDrawerAction, handlePageChange } = useNoMSADrawer();
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const [direction, setDirection] = useState(Direction.forward);
  const [page, setPage] = useState(callbackPageIndex);
  const [pageData, setPageData] = usePageStore(dataDefault);

  useEffect(() => {
    setDirection(callbackPageIndex >= page ? 1 : -1);
    setPage(callbackPageIndex);
  }, [callbackPageIndex]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  const setContent = () => {
    if (page === 0)
      return (
        <Contacts
          data={{ name: pageData.name, phone: pageData.phone, email: pageData.email }}
          onChange={(data: any) => setPageData({ ...data })}
        />
      );
    if (page === 1) return <RequestingCallBack />;
    if (page === 2) return <CallBackConfirmed />;
    return <CallBackConfirmed />;
  };

  return (
    <SC.Wrapper ref={ref}>
      <AnimatePresence initial={false} custom={direction}>
        <SC.Content
          custom={direction}
          transition={{ ...transitions.easIn, duration: 0.85 }}
          variants={scrollVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"page_" + page}
        >
          {setContent()}
        </SC.Content>
      </AnimatePresence>
    </SC.Wrapper>
  );
}
