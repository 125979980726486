import * as SC from "./Styles";
import NavButton, { State } from "../NavButton/NavButton";
import { IconNames } from "../../assets/icons/Icons";
import { DrawerAction, ProStatus, View } from "../EnumsAndTypes/EnumsAndTypes";
import { useDrawer } from "../../contexts/DrawerContext";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { usePros } from "../../contexts/ProfessionalsContext";

export default function DrawerFooter() {
  const { viewstate } = useDrawer();
  const ref = useRef();
  const size = useResizeObserver(ref);

  const amt = () => {
    return size?.height ? size.height : 500;
  };

  const setFooter = () => {
    switch (viewstate.view) {
      case View.ListPros:
        return <ListPros />;
      case View.AddSingle:
        return <SingleAddFooter />;
      case View.SubmitContracts:
        return <SubmitContracts />;
      case View.WhatHappensNext:
        return <WhatHappensNext />;
      case View.Connecting:

      case View.BulkAdd:
      default:
        return null;
    }
  };

  return (
    <AnimatePresence initial={true}>
      <SC.ContainerView
        $isFooter={true}
        ref={ref}
        key={"footer_" + viewstate.view.toString()}
        initial={{ y: amt(), opacity: 1 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: amt(), opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
      >
        {setFooter()}
      </SC.ContainerView>
    </AnimatePresence>
  );
}

//*******
// footer for list of pros
//*******
const ListPros = () => {
  const { bulkEdit, handleDrawerAction, pageIndex, handleViewDetails } = useDrawer();
  const { status, proCounts } = usePros();
  const AddProsBtn = () => {
    if (bulkEdit || pageIndex !== 0) return;
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.plusCircle}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Add Hire(s)"}
        theme={"blue"}
        onClick={() => handleDrawerAction(DrawerAction.AddMore)}
      />
    );
  };
  const SendContracts = () => {
    if (!bulkEdit || pageIndex !== 0) return;
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.share}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Send Contracts"}
        theme={"blue"}
        onClick={() => handleDrawerAction(DrawerAction.SubmitContracts)}
      />
    );
  };
  const BulkEdit = () => {
    if (proCounts.all === 0 || bulkEdit || pageIndex == 1 || status === null) return;
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.editCard}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Bulk Edit"}
        state={
          status === ProStatus.ready || status === ProStatus.editing ? State.active : State.disabled
        }
        onClick={() => handleDrawerAction(DrawerAction.BulkEdit)}
      />
    );
  };
  const CancelBulkEdit = () => {
    if (!bulkEdit) return;
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.xCircle}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Cancel"}
        onClick={() => handleDrawerAction(DrawerAction.BulkEdit)}
      />
    );
  };
  const CancelBtn = () => {
    if (status === null || proCounts === 0 || pageIndex === 1) {
      return (
        <NavButton
          iconpos={"none"}
          label={"Cancel"}
          onClick={() => handleDrawerAction(DrawerAction.Cancel)}
        />
      );
    }
    return null;
  };
  const EditDetails = () => {
    if (pageIndex !== 1) return;
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.editCard}
        iconstyle={{ size: 24, stroke: 1.5 }}
        label={"Edit Detials"}
        theme={"blue"}
      />
    );
  };

  return (
    <SC.Footer>
      {CancelBtn()}
      {CancelBulkEdit()}
      {BulkEdit()}
      <SC.PrevNext>
        {SendContracts()}
        {AddProsBtn()}
        {EditDetails()}
      </SC.PrevNext>
    </SC.Footer>
  );
};

//*******
// footer for add single pro / bulk
//*******
const SingleAddFooter = () => {
  const { handleDrawerAction, pageIndex, pageCount, isUploading } = useDrawer();
  const cancelBtn = () => {
    return (
      <NavButton
        iconpos={"none"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        state={pageIndex === 12 || isUploading ? State.disabled : State.active}
        label={"Cancel"}
        onClick={() => handleDrawerAction(DrawerAction.BackListPros)}
      />
    );
  };

  const nextBtn = () => {
    if (pageIndex > pageCount) return null;
    const setAction = () => {
      if (pageIndex === 9) return DrawerAction.AddProProgress;
      if (pageIndex === 10) return DrawerAction.AddProProgressBulk;
      //if (pageIndex === 5 && createContract) return DrawerAction.CreateContract;
      return DrawerAction.Next;
    };
    return (
      <NavButton
        iconpos={"none"}
        label={pageIndex === 0 ? "Add Manually" : "Continue"}
        state={pageIndex === 12 || isUploading ? State.disabled : State.active}
        onClick={() => handleDrawerAction(setAction())}
      />
    );
  };

  const backBtn = () => {
    if (pageIndex === 0) return null;
    const setBack = () => {
      //if (pageIndex === 5 && !isManualAdd) return DrawerAction.BackToUpload;
      if (pageIndex === 10) return DrawerAction.BackToUpload;
      return DrawerAction.Previous;
    };
    return (
      <NavButton
        iconpos={"left"}
        icon={IconNames.carretUp}
        label={"Back"}
        state={pageIndex < 1 || pageIndex === 12 ? State.disabled : State.active}
        onClick={() => handleDrawerAction(setBack())}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>
        {backBtn()}
        {nextBtn()}
      </SC.PrevNext>
    </SC.Footer>
  );
};

//*******
// footer for submite contracts
//*******
const SubmitContracts = () => {
  const { handleDrawerAction, submitComplete } = useDrawer();
  const cancelBtn = () => {
    return (
      <NavButton
        iconpos={"none"}
        label={"Cancel"}
        state={submitComplete ? State.disabled : State.active}
        onClick={() => handleDrawerAction(DrawerAction.BackListPros)}
      />
    );
  };

  const SendBtn = () => {
    return (
      <NavButton
        iconpos={"left"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        icon={IconNames.checkInCircle}
        label={"Confirm Send Contracts"}
        theme={"blue"}
        state={submitComplete ? State.disabled : State.active}
        onClick={() => handleDrawerAction(DrawerAction.SendContracts)}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>{SendBtn()}</SC.PrevNext>
    </SC.Footer>
  );
};

//*******
// footer for submite contracts
//*******
const WhatHappensNext = () => {
  const { handleDrawerAction } = useDrawer();
  const cancelBtn = () => {
    return (
      <NavButton
        iconpos={"left"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        icon={IconNames.caretLeft}
        label={"Professionals list"}
        onClick={() => handleDrawerAction(DrawerAction.BackListPros)}
      />
    );
  };

  const DoneButton = () => {
    return (
      <NavButton
        iconpos={"left"}
        iconstyle={{ size: 24, stroke: 1.5 }}
        icon={IconNames.checkInCircle}
        label={"Done"}
        theme={"blue"}
        onClick={() => handleDrawerAction(DrawerAction.ADPList)}
      />
    );
  };

  return (
    <SC.Footer>
      {cancelBtn()}
      <SC.PrevNext>{DoneButton()}</SC.PrevNext>
    </SC.Footer>
  );
};
