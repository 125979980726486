import { Children, useEffect, useState } from "react";
import { Direction, View } from "../EnumsAndTypes/EnumsAndTypes";
import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";

interface Props {
  children?: any;
  footerHeight?: number;
  drawerWidth?: number;
  viewstate?: { view: View; direction: Direction };
}

export default function SideView(props: Props) {
  const {
    footerHeight = 0,
    children,
    drawerWidth = 500,
    viewstate = { view: View.Connecting, direction: Direction.forward },
  } = props;

  const DrawerViewVariants = {
    enter: (view: { view: View; direction: Direction }) => {
      let x = view.direction === Direction.forward ? drawerWidth : -drawerWidth;
      return { x: x, opacity: 0 };
    },
    animate: { x: 0, opacity: 1 },
    exit: (view: { view: View; direction: Direction }) => {
      let x = view.direction === Direction.forward ? -drawerWidth : drawerWidth;
      return { x: x, opacity: 0 };
    },
  };

  const setChild = () => {
    return Children.map(children, (child, i) => {
      if (parseInt(child.key) === viewstate.view) return child;
    });
  };

  return (
    <SC.Body $footerHeight={footerHeight}>
      <AnimatePresence initial={true} custom={viewstate}>
        <SC.ContainerView
          custom={viewstate}
          key={"view_" + viewstate.view.toString()}
          variants={DrawerViewVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          transition={{ ease: "easeInOut", duration: 0.75 }}
        >
          {setChild()}
        </SC.ContainerView>
      </AnimatePresence>
    </SC.Body>
  );
}
