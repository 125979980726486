import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useRef, useState, useEffect } from "react";
import { transitions } from "../../assets/tokens/tokens";
import PersonalInfo from "./Steps/PersonalInfo/PersonalInfo";
import JobDetails from "./Steps/JobDetails/JobDetails";
import EnterCountry from "./Steps/Country/Country";
import Benefits from "./Steps/Benefits/Benefits";
import EmploymentAgreement from "./Steps/Agreement/EmploymentAgreement";
import AddComplete from "./Steps/AddComplete/AddComplete";
import usePageStore from "../../callbacks/usePageStore";
import { Direction, DrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import UploadOrManual from "./Steps/UploadOrManual/UploadOrManual";
import ConfirmUlpoadedData from "./Steps/ConfirmUploadList/ConfirmUploadData";
import { useDrawer } from "../../contexts/DrawerContext";
import DraftContract from "./Steps/DraftContract/DraftContract";
import CompDetails from "./Steps/CompDetails/CompDetails";
import BenefitDetails from "./Steps/BenefitDetails/BenefitDetails";
import Termination from "./Steps/Termination/Termination";
import ReviewInfo from "./Steps/ReviewInfo/ReviewInfo";

const dataDefault = {
  country: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  jobTitle: null,
  annualSalary: null,
  startDate: null,
  shareOption: null,
  draftContract: [false, false],
  signBonus: [false, false],
  annualBonus: [false, false],
  comissions: [false, false],
  medical: [false, false],
  allowance: [false, false],
  termination: [false, false],
  visa: [false, false],
};

export default function AddProfessional() {
  const { pageIndex, handleDrawerAction, isManualAdd } = useDrawer();
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const [direction, setDirection] = useState(Direction.forward);
  const [page, setPage] = useState(pageIndex);
  const [pageData, setPageData] = usePageStore(dataDefault);

  useEffect(() => {
    setDirection(pageIndex >= page ? 1 : -1);
    setPage(pageIndex);
  }, [pageIndex]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  const setContent = () => {
    if (page === 0) return <UploadOrManual />;
    if (page === 1)
      return (
        <EnterCountry
          data={pageData.country}
          onChange={(data: any) => setPageData({ country: data })}
        />
      );
    if (page === 2)
      return (
        <PersonalInfo
          data={{
            firstName: pageData.firstName,
            lastName: pageData.lastName,
            email: pageData.email,
            phone: pageData.phone,
          }}
          onChange={(data: any) => setPageData({ ...data })}
        />
      );
    if (page === 3)
      return (
        <JobDetails
          firstName={pageData.firstName}
          data={{
            jobTitle: pageData.jobTitle,
            startDate: pageData.startDate,
            annualSalary: pageData.annualSalary,
          }}
          onChange={(data: any) => setPageData({ ...data })}
        />
      );
    if (page === 4) return <Benefits country={pageData.country} />;
    if (page === 5)
      return (
        <DraftContract
          firstName={pageData.firstName}
          data={pageData.draftContract}
          onChange={(data: any) => setPageData({ draftContract: data })}
        />
      );
    if (page === 6)
      return (
        <CompDetails
          firstName={pageData.firstName}
          data={{
            annualSalary: pageData.annualSalary,
            signBonus: pageData.signBonus,
            annualBonus: pageData.annualBonus,
            comissions: pageData.comissions,
          }}
          onChange={(fieldname: string, data: any) => setPageData({ [fieldname]: data })}
        />
      );
    if (page === 7)
      return (
        <BenefitDetails
          firstName={pageData.firstName}
          data={{ medical: pageData.medical, allowance: pageData.allowance }}
          onChange={(fieldname: string, data: any) => setPageData({ [fieldname]: data })}
        />
      );
    if (page === 8)
      return (
        <Termination
          firstName={pageData.firstName}
          data={{ termination: pageData.termination }}
          onChange={(fieldname: string, data: any) => setPageData({ [fieldname]: data })}
        />
      );
    if (page === 9) return <ReviewInfo data={pageData} />;
    if (page === 10) return <ConfirmUlpoadedData />;
    if (page === 11)
      return (
        <EmploymentAgreement
          firstName={pageData.firstName}
          data={pageData.shareOption}
          onChange={(data: any) => setPageData({ shareOption: data })}
        />
      );
    if (page === 12) return <AddComplete />;
    return (
      <EnterCountry
        data={pageData.country}
        onChange={(data: any) => setPageData({ country: data })}
      />
    );
  };

  return (
    <SC.Wrapper ref={ref}>
      <AnimatePresence initial={false} custom={direction}>
        <SC.Content
          custom={direction}
          transition={{ ...transitions.easIn, duration: 0.85 }}
          variants={scrollVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"page_" + page}
        >
          {setContent()}
        </SC.Content>
      </AnimatePresence>
    </SC.Wrapper>
  );
}
