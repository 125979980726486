import { useContract } from "../../contexts/ContractContext";
import { CommentBubble } from "../Comment/Comment";
import * as Styled from "./Styles";
import { Comment, Reply } from "../EnumsAndTypes/EnumsAndTypes";

export const CommentsList = () => {
  const { viewComments, comments, replies } = useContract();

  const renderComments = () => {
    let showComments = false;
    if (comments) {
      showComments = comments.filter((comment: Comment) => !comment.resolved).length > 0;
    }
    if (!showComments) return <NoComments />;
    return comments.map((comment: Comment, i: number) => {
      const responses = replies?.filter((reply: Reply) => reply.parent === i);
      return (
        <CommentBubble key={"comment_group_" + i} index={i} comment={comment} replies={responses} />
      );
    });
  };

  return (
    <Styled.CommentsBar animate={{ width: viewComments ? 250 : 0 }}>
      {renderComments()}
    </Styled.CommentsBar>
  );
};

const NoComments = () => {
  return (
    <Styled.NoComments>
      There are no active comments for this contract.
      <br />
      <br />
      To add comments, select the relevant section of the contract and use the "+" hilight button to
      create a new one.{" "}
    </Styled.NoComments>
  );
};
