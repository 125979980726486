import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import { Direction, NoMSADrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";

export default function NoMSAConnecting() {
  const { visible, handleDrawerAction } = useNoMSADrawer();

  return (
    <ProgressIndicator
      status={{ working: "Connecting", done: "Connected" }}
      loops={5}
      size={125}
      stroke={0.75}
      playing={visible}
      showGP={true}
      progressEndDelay={1000}
      progressEnd={() => handleDrawerAction(NoMSADrawerAction.GPLoaded, Direction.forward)}
    />
  );
}
