import * as Styled from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";
import { transitions } from "../../assets/tokens/tokens";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export enum State {
  hover = "hovered",
  active = "active",
  disabled = "disabled",
  pressed = "pressed",
}

interface Props {
  label?: string;
  icon?: IconNames;
  iconpos?: "left" | "right" | "none";
  iconstyle?: { stroke?: number; size?: number };
  theme?: "white" | "blue" | "orange" | "link" | string;
  onClick?: () => void;
  state?: State;
  transition?: any;
  navigate?: string | number;
  length?: "auto" | number;
  size?: "regular" | "small";
}

export default function NavButton(props: Props) {
  const {
    label = null,
    icon = IconNames.carretUp,
    state = State.active,
    theme = "white",
    transition = transitions.spring,
    navigate = null,
    length = "auto",
    iconpos = "none",
    iconstyle = { stroke: 2.5, size: 18 },
    size = "regular",
    onClick = () => null,
  } = props;
  const doNavigation = useNavigate();
  const [btnState, setBtnState] = useState(state);
  useEffect(() => setBtnState(state), [state]);

  const setIcon = (pos: string) => {
    if (iconpos === "none") return null;
    let iconTheme = theme;
    if (state === State.disabled) iconTheme = "system";
    return (
      iconpos === pos && (
        <Icon name={icon} size={iconstyle.size} stroke={iconstyle.stroke} theme={iconTheme} />
      )
    );
  };

  const setLabel = () => {
    return label ? label : null;
  };

  const handleClick = () => {
    if (btnState === "disabled") return;
    if (navigate) {
      if (isNaN(+navigate)) doNavigation("/" + navigate);
      doNavigation(-Math.abs(+navigate));
      return;
    }
    onClick();
  };

  const iconOnly = () => {
    if (icon && !label) return true;
    return false;
  };

  return (
    <Styled.Button
      $size={size}
      $theme={theme}
      $state={btnState}
      $icononly={iconOnly()}
      $length={length}
      $label={label}
      $iconpos={iconpos}
      variants={Styled.themeStyles[theme]}
      initial={Styled.themeStyles[theme][btnState]}
      animate={Styled.themeStyles[theme][btnState]}
      transition={transition}
      whileHover={Styled.themeStyles[theme].hover}
      whileTap={Styled.themeStyles[theme].pressed}
      onClick={() => handleClick()}
    >
      {setIcon("left")}
      {setLabel()}
      {setIcon("right")}
    </Styled.Button>
  );
}
