import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import profilepic from "../../assets/images/profilepic2.png";

export default function CallBackConfirmed() {
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>You're all set Janice.</Styled.Title>
      <Styled.Description>
        A G-P expert will use the contact information below to reach out and connect with you. You
        should be hearing from us momentarily.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>

      <Styled.TableItem $isLast={true}>
        <Styled.TableItemHeader>
          <img src={profilepic} width={40} alt={"Janice's profile"} />
          <Styled.Details $isLeft={true}>
            <Styled.Name>Janice Jenkins</Styled.Name>
          </Styled.Details>
          <Styled.Details $isLeft={false}>
            <Styled.Compensation>Acme Inc.</Styled.Compensation>
          </Styled.Details>
        </Styled.TableItemHeader>
        <Styled.LineItem>
          Role:<div>HR</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Email:<div>janicej@acme.com</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Phone:<div>+1 (617) 671-5587</div>
        </Styled.LineItem>
        <Styled.LineItem>
          <Styled.List>
            <Styled.ListItem>Call back request confirmed</Styled.ListItem>
          </Styled.List>
        </Styled.LineItem>
      </Styled.TableItem>
    </Styled.QuestionWrapper>
  );
}
