import * as Styled from "./Styles.ts";
import Flag, { Country } from "../../assets/flags/Flag.tsx";
import { AllProfessionals } from "../ListProfessionals/BulkData.ts";
import {
  ChatMessage,
  DrawerAction,
  MessageSender,
  MoreInfo,
} from "../EnumsAndTypes/EnumsAndTypes.ts";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton.tsx";
import { useEffect, useState } from "react";
import Icon, { IconNames } from "../../assets/icons/Icons.tsx";
import { motion } from "framer-motion";
import { useDrawer } from "../../contexts/DrawerContext.tsx";
import Doc from "../../assets/images/doc.tsx";
import ProgressBar from "../ProgressBar/ProgressBar.tsx";
import { colors } from "../../assets/tokens/tokens.ts";
import { usePartner } from "../../hooks/usePartner.tsx";

interface Props {
  clickInfo?: (moreInfo: MoreInfo) => void;
  clickEmployee?: (id: number) => void;
}

export function SubmitContracts(props: Props) {
  const { clickInfo = () => null, clickEmployee = () => null } = props;
  const { handleTriggerChat, handleDrawerAction, submitComplete } = useDrawer();
  const { partnerName } = usePartner();
  const [index, setIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [trackColor, setTrackcolor] = useState("transparent");
  useEffect(() => {
    if (submitComplete) {
      setUploading(true);
      setTrackcolor(colors.grays100);
    }
  }, [submitComplete]);

  const count = AllProfessionals.length;
  const renderCards = () => {
    const compare = (a: any, b: any) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    };
    const sorted = AllProfessionals.sort(compare);
    const item = sorted[index];
    return (
      <ItemCard
        key={"hires_" + index}
        last={false}
        flag={item.country}
        name={item.name}
        salary={item.salary}
        title={item.title}
        country={item.country}
        currency={item.currency}
        phone={item.phone}
        email={item.email}
        symbol={item.symbol}
        visa={item.visa}
        jobduties={item.jobduties}
        bonus={item.bonus}
        signon={item.signon}
        comission={item.comission}
        morebenefits={item.morebenefits}
        allowance={item.allowance}
        covenants={item.covenants}
        status={item.status}
        startDate={item.startDate}
        showMore={showMore}
        onClick={() => clickEmployee(index)}
        onChange={() => setShowMore(!showMore)}
      />
    );
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Send contract(s) to professionals?</Styled.Title>

      <Styled.Description>
        After you send out the contract(s):
        <Styled.SubTitle>
          1. Professionals will be invited on to our platform to finalize contract details.
        </Styled.SubTitle>
        <Styled.SubTitle>
          2. You can track and manage the process directly from your {partnerName} dashboard.
          <MoreInfoButton triggerChat={() => handleTriggerChat(inviteInfo)} />
        </Styled.SubTitle>
      </Styled.Description>

      {renderCards()}

      <Styled.Pagging>
        <Icon
          name={IconNames.arrowLeft}
          onClick={() => setIndex(index > 0 ? index - 1 : 0)}
          pointer={true}
          theme={"blueButton"}
        />
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          {`Contract: ${index + 1} of ${count}`}
        </div>
        <Icon
          name={IconNames.arrowRight}
          onClick={() => setIndex(index + 1 < count ? index + 1 : count)}
          pointer={true}
          theme={"blueButton"}
        />
      </Styled.Pagging>

      <ProgressBar
        trackcolor={trackColor}
        playing={uploading}
        progressEnd={() => handleDrawerAction(DrawerAction.WhatHappensNext)}
        progressStart={() => null}
      />
    </Styled.QuestionWrapper>
  );
}

interface Props {
  flag?: Country;
  name?: string;
  title?: string;
  last?: boolean;
  salary?: string;
  country?: string;
  email?: string;
  phone?: string;
  currency?: string;
  symbol?: string;
  visa?: boolean;
  jobduties?: string;
  bonus?: boolean;
  signon?: boolean;
  comission?: boolean;
  morebenefits?: boolean;
  allowance?: boolean;
  covenants?: boolean;
  status?: string;
  startDate?: string;
  showMore?: boolean;
  onClick?: () => void;
  onChange?: () => void;
}
const ItemCard = (props: Props) => {
  const {
    flag,
    name,
    title,
    last,
    salary,
    country,
    email,
    phone,
    currency,
    symbol,
    visa = false,
    jobduties = "Agent",
    bonus = false,
    signon = false,
    comission = false,
    morebenefits = false,
    allowance = false,
    covenants = false,
    status = "ready",
    startDate = "2023-12-15T06:44:16.314Z",
    showMore = false,
    onChange = () => null,
    onClick = () => null,
  } = props;

  const usa = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
  const germany = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
  const ireland = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
  const dateoptions = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  const formatSalary = (value: any, country: Country) => {
    if (country === Country.Germany) return `${germany.format(parseInt(salary))} ${currency}`;
    if (country === Country.Ireland) return `${ireland.format(parseInt(salary))} ${currency}`;
    return `${usa.format(parseInt(salary))} ${currency}`;
  };

  const CanadaDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const contractLink = (
    name: string,
    email: string,
    edit: boolean,
    role: string,
    salary: string
  ) => {
    const state = edit ? "enabled" : "disabled";
    const formatsalary = `${CanadaDollar.format(parseInt(salary))}+CAD`;
    return (
      "/contract?name=" + name + "&email=" + email + "&role=" + role + "&salary=" + formatsalary
    );
  };

  return (
    <Styled.TableItem $isLast={last} onClick={() => onClick()}>
      <Styled.TableItemHeader>
        <Flag country={flag} size={32} />
        <Styled.Details $isLeft={true} style={{ flexShrink: 1, overflow: "hidden" }}>
          <Styled.Name>{name}</Styled.Name>
        </Styled.Details>
        <Styled.Details $isLeft={false}>
          <Styled.Compensation style={{ flexShrink: 0, whiteSpace: "nowrap" }}>
            {formatSalary(salary, flag)}
          </Styled.Compensation>
        </Styled.Details>
      </Styled.TableItemHeader>
      <Styled.LineItem>
        Job Title:<div>{title}</div>
      </Styled.LineItem>
      <Styled.LineItem>
        Country:<div>{country}</div>
      </Styled.LineItem>
      <Styled.LineItem>
        Target Start:
        <div>{new Date(startDate).toLocaleDateString("en-US", dateoptions as any)}</div>
      </Styled.LineItem>
      <Styled.ContractLink
        to={contractLink(name, email, false, title, salary)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Doc />
        {name} - Work Contract
      </Styled.ContractLink>
      <motion.div
        style={{ width: "100%", zIndex: -1, overflow: "hidden" }}
        initial={{ height: showMore ? "auto" : 0, opacity: showMore ? 1 : 0 }}
        animate={{ height: showMore ? "auto" : 0, opacity: showMore ? 1 : 0 }}
      >
        <Styled.LineItem>
          Email:<div>{email}</div>
        </Styled.LineItem>
        <Styled.LineItem style={{ marginTop: 8 }}>
          Phone:<div>{phone}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Currency:
          <div>
            {currency} {symbol}
          </div>
        </Styled.LineItem>
        <Styled.LineItem>
          Receives bonus:<div>{bonus ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Sign on bonus:<div>{signon ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Earns comissions:<div>{comission ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Addtional Benefits:<div>{morebenefits ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Has Allowances:<div>{allowance ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Added Convenants:<div>{covenants ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Visa support:<div>{visa ? "Yes" : "No"}</div>
        </Styled.LineItem>
      </motion.div>
      <div
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        onClick={() => onChange()}
      ></div>
    </Styled.TableItem>
  );
};

//<Styled.LinkButton>{`${showMore ? "less detail" : "more detail"}`}</Styled.LinkButton>

const reviewInfo: Array<ChatMessage> = [
  {
    message: {
      title: "About G-P's Contract Review",
      text: "When you send us a contract we will ensure that it's compliant with all local legal and regulatory requirements, and that it matches the corresponding employee information",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "During this time, you will not be able to edit / modify the contract details.",
    },
    sender: MessageSender.GIA,
  },
];

const inviteInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Inviting the Professional to G-P",
      text: "Once we successfully review and validate a contract, we'lll reach out to the professional(s) using the contact information provided and ask them for any additional information required.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Until all the information necessary for the contract is entered, it will not be shared with the professional(s).",
    },
    sender: MessageSender.GIA,
  },
];
