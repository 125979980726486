import styled from "styled-components";
import { type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

const getWidth = (value: any) => {
  if (isNaN(value)) return value;
  return `${value}px`;
};

export const Wrapper = styled.div<{ $width: number | string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(p) => getWidth(p.$width)};
  margin-top: 16px;
`;

export const Track = styled.div<{ $height: number; $color: string }>`
  background: ${(p) => p.$color};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${(p) => p.$height}px;
  border-radius: 500px;
  overflow: hidden;
`;

export const ProgressBar = styled.div<{ $color: string }>`
  width: 0px;
  background: ${(p) => p.$color};
  height: 100%;
`;

export const Caption = styled(motion.div)`
  ${type.pSmall};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
`;
