import * as Styled from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";

interface InfoProps {
  triggerChat?: () => void;
}
export default function MoreInfoButton(props: InfoProps) {
  const { triggerChat = () => null } = props;
  return (
    <Styled.InfoButton>
      <Icon
        name={IconNames.info}
        onClick={() => triggerChat()}
        theme={"blueButton"}
        pointer={true}
      />
    </Styled.InfoButton>
  );
}
