import * as SC from "./Styles";
import {
  MessageAction,
  MessageSender,
  MessageFile,
  ChatMessage,
} from "../EnumsAndTypes/EnumsAndTypes";
import Icon, { IconNames } from "../../assets/icons/Icons";
import Doc from "../../assets/images/doc";
import NavButton from "../NavButton/NavButton";

interface Props {
  msg?: ChatMessage;
}

export default function MessageBubble(props: Props) {
  const { msg } = props;

  const variants = {
    initial: { opacity: 0, y: 50 },
    exit: { opacity: 0, transition: { duration: 0.15 } },
    animate: { opacity: 1, y: 0 },
  };

  const setTitle = (title: string) => {
    if (!title) return null;
    const setIcon = () => {
      if (msg?.sender === MessageSender.Info)
        return <Icon name={IconNames.info} size={16} stroke={2} />;
      return null;
    };
    return (
      <SC.Title>
        {setIcon()}
        {title}
      </SC.Title>
    );
  };

  const setFiles = (files: MessageFile[]) => {
    if (!files) return null;
    return files.map((file: MessageFile, i: number) => {
      if (file.type === "video")
        return (
          <SC.VideoWrapper key={file?.name + i.toString()}>
            <SC.Video controls poster={file.image ? file.image : null}>
              <source
                src="https://player.vimeo.com/progressive_redirect/playback/850520332/rendition/1080p/file.mp4?loc=external&log_user=0&signature=d614c5c1ce742a121c78a5b17b01ef7f38777d7c64b6975e291ee729271ec3da"
                type="video/mp4"
              />
              Your browser does not support HTML video.
            </SC.Video>
            <SC.VideoLength>{file?.size}</SC.VideoLength>
          </SC.VideoWrapper>
        );
      return (
        <SC.File
          whileTap={{ scale: 0.98 }}
          whileHover={{ scale: 1.04 }}
          key={file?.name + i.toString()}
        >
          <Doc height={32} />
          <SC.FileInfo>
            <SC.FileName>{file.name}</SC.FileName>
            <SC.FileType>
              {file.type}: {file.size}
            </SC.FileType>
          </SC.FileInfo>
        </SC.File>
      );
    });
  };

  const setActions = (actions: MessageAction[]) => {
    if (!actions) return null;
    return actions.map((action: MessageFile, i: number) => {
      return (
        <NavButton
          iconpos={action?.type === "link" || action?.type === "audio" ? "left" : "none"}
          icon={action?.type === "audio" ? IconNames.speaker : IconNames.share}
          iconstyle={{ size: 24, stroke: 1.5 }}
          label={action.name}
          theme={action?.type === "link" ? "link" : "blue"}
          key={action?.name + i.toString()}
        />
      );
    });
  };

  return (
    <SC.Wrapper variants={variants} initial={"initial"} animate={"animate"} exit={"exit"}>
      <SC.Bubble $sender={msg?.sender ? msg.sender : MessageSender.GIA}>
        {setTitle(msg?.message?.title)}
        {msg?.message.text}
        {setFiles(msg?.files)}
        {setActions(msg?.actions)}
      </SC.Bubble>
    </SC.Wrapper>
  );
}
