import styled from "styled-components";
import { colors, type } from "../../../assets/tokens/tokens";

export const CardsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 48px;
  gap: 24px;
  width: 100%;
`;

export const Disclaimer = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextTertiary};
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background-color: ${colors.grays100};
  border-radius: 8px;
`;

export const CardsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const QuoteSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 0px 16px;
  gap: 0px;
`;

export const SectionTitle = styled.div`
  ${type.pMedium};
  ${type.pBoldFace};
  color: ${colors.graysTextPrimary};
  height: auto;
  margin: 0px;
  padding: 0px;
`;

export const SectionDescription = styled.div`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
`;
