import { createContext, useContext, useState } from "react";
import {
  ADPScreens,
  ChatMessage,
  Direction,
  ProStatus,
} from "../components/EnumsAndTypes/EnumsAndTypes";
import { DrawerAction, View } from "../components/EnumsAndTypes/EnumsAndTypes";
import { usePros } from "./ProfessionalsContext";
import { AllProfessionals } from "../components/ListProfessionals/BulkData";
import { singleProDaniel } from "../components/ListProfessionals/SingleData";

const DrawerContext = createContext(null);

interface Props {
  children?: any;
  showDrawer?: boolean;
  showGIA?: boolean;
  onTriggerChat?: (messages: ChatMessage[]) => void;
  onChangeGIA?: (state: boolean) => void;
  onChangeDrawer?: (state: boolean) => void;
  onChangeADP?: (screen: ADPScreens) => void;
  connectLiveAgent?: (connect: boolean) => void;
}

export function DrawerProvider(props: Props) {
  const {
    children,
    showDrawer = false,
    showGIA = false,
    onTriggerChat = () => null,
    onChangeDrawer = () => null,
    onChangeGIA = () => null,
    onChangeADP = () => null,
    connectLiveAgent = () => null,
  } = props;
  const { proCounts, allPros, handleAddPros, handleStatusChange, handleBulkChangeStatus } =
    usePros();

  const [GPLoaded, setGPLoaded] = useState(false);
  //const [view, setView] = useState(View.Connecting);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [proListPage, setProListPage] = useState(0);
  const [termsPage, setTermsPage] = useState(0);
  const [pageCount] = useState(12);
  const [isUploading, setIsUploading] = useState(false);
  const [isManualAdd, setIsManualAdd] = useState(true);
  const [addComplete, setAddComplete] = useState(false);
  const [footerHeight, setFooterHeight] = useState(79);
  const [bulkEdit, setBulkEdit] = useState(false);
  const [group, setGroup] = useState("none");
  const [draftContracts, setDraftContract] = useState(false);
  const [sendToGP, setSendToGP] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);
  const [employeeID, setEmployeeID] = useState(0);
  const [createContract, setCreateContract] = useState(false);
  const [viewstate, setViewState] = useState({
    view: View.Connecting,
    direction: Direction.forward,
  });

  const handleIsUploading = (state: boolean) => {
    setIsUploading(state);
  };

  const handleCreateContract = (state: boolean) => {
    setCreateContract(state);
  };

  const handleViewDetails = (pageIndex: number, employeeID: number) => {
    setEmployeeID(employeeID);
    setPageIndex(pageIndex);
  };

  const handleSubmitComplete = (state: boolean) => {
    setSubmitComplete(state);
  };

  const handleDraftContracts = (value: boolean) => {
    if (value) {
      handleBulkChangeStatus({ status: ProStatus.ready });
      handleStatusChange(ProStatus.ready);
    } else {
      handleBulkChangeStatus(ProStatus.editing);
      handleStatusChange(ProStatus.editing);
    }
  };

  const handleSendToGP = (value: boolean) => {
    setSendToGP(value);
    if (value) setGroup("review");
  };

  const handleGroupChange = (group: string) => {
    setGroup(group);
  };

  const handleIsManualAdd = (state: boolean) => {
    setIsManualAdd(state);
  };

  const handleTriggerChat = (messages: ChatMessage[]) =>
    messages ? onTriggerChat(messages) : null;

  const handleFooterChange = (current: View) => {
    setFooterHeight(current === View.AcceptTerms ? 143 : 79);
  };

  const handleBulkEdit = (state: boolean) => {
    setBulkEdit(state);
  };

  const handlePageChange = (view: View, page: number) => {
    if (view === View.AcceptTerms) setTermsPage(page);
    if (view === View.AddSingle) setPageIndex(page);
    if (view === View.ListPros) setProListPage(page);
  };

  const closeDrawer = (reset: boolean) => {
    onChangeDrawer(false);
    onChangeGIA(false);
    setTimeout(() => resetProgress(reset), 500);
  };

  const resetProgress = (reset: boolean) => {
    setViewState({
      view: reset || !acceptTerms ? View.Connecting : viewstate.view,
      direction: Direction.forward,
    });
    setPageIndex(reset ? 0 : pageIndex);
    setGPLoaded(reset ? false : GPLoaded);
    setAddComplete(reset ? false : addComplete);
  };

  const scrollPage = (amount: number) => {
    if (amount > 0 && pageIndex === pageCount) return;
    if (amount < 1 && pageIndex === 0) return;
    setPageIndex(pageIndex + amount);
  };

  const handleDrawerAction = (action: DrawerAction) => {
    switch (action) {
      case DrawerAction.GPLoaded:
        setGPLoaded(true);
        setViewState({ view: View.ListPros, direction: Direction.forward });
        return;
      case DrawerAction.Done:
      case DrawerAction.Cancel:
        closeDrawer(false);
        return;
      case DrawerAction.Close:
        closeDrawer(false);
        return;
      case DrawerAction.AddLater:
        closeDrawer(false);
        setViewState({
          view: addComplete ? View.ListPros : View.AddSingle,
          direction: Direction.back,
        });
        return;
      case DrawerAction.Next:
        scrollPage(1);
        return;
      case DrawerAction.Previous:
        scrollPage(-1);
        return;
      case DrawerAction.AddMore:
        setIsManualAdd(true);
        setViewState({ view: View.AddSingle, direction: Direction.forward });
        setAddComplete(false);
        setPageIndex(0);
        return;
      case DrawerAction.Restart:
        setPageIndex(0);
        return;
      case DrawerAction.ToggleGIA:
        onChangeGIA(!showGIA);
        return;
      case DrawerAction.BackToUpload:
        setIsManualAdd(true);
        setPageIndex(0);
        return;
      case DrawerAction.UploadDone:
        if (proCounts.all < 10) handleAddPros(AllProfessionals);
        setPageIndex(10);
        return;
      case DrawerAction.AddProProgress:
        handleAddPros(singleProDaniel);
        handleStatusChange(ProStatus.ready);
        setPageIndex(12);
        return;
      case DrawerAction.AddProProgressBulk:
        handleDraftContracts(true);
        handleStatusChange(ProStatus.ready);
        setPageIndex(12);
        return;
      case DrawerAction.ConnectLiveAgent:
        onChangeGIA(true);
        connectLiveAgent(true);
        return;
      case DrawerAction.ADPList:
        closeDrawer(false);
        onChangeADP(ADPScreens.ListView);
        setProListPage(0);
        return;
      case DrawerAction.DidAddProfessional:
        setPageIndex(0);
        setAddComplete(true);
        setViewState({ view: View.ListPros, direction: Direction.forward });
        return;
      case DrawerAction.BulkEdit:
        setBulkEdit(!bulkEdit);
        return;
      case DrawerAction.BackListPros:
        setPageIndex(0);
        setViewState({ view: View.ListPros, direction: Direction.back });
        return;
      case DrawerAction.CreateContract:
        setPageIndex(6);
        return;
      case DrawerAction.WhatHappensNext:
        setPageIndex(0);
        setViewState({ view: View.WhatHappensNext, direction: Direction.forward });
        return;
      case DrawerAction.SendContracts:
        handleBulkChangeStatus({ status: ProStatus.sent });
        handleStatusChange(ProStatus.sent);
        setBulkEdit(false);
        setSubmitComplete(true);
        return;
      case DrawerAction.SubmitContracts:
        setViewState({ view: View.SubmitContracts, direction: Direction.forward });
        setPageIndex(0);
        return;
      case DrawerAction.ListPros:
      default:
        setPageIndex(0);
        return;
    }
  };

  return (
    <DrawerContext.Provider
      value={{
        visible: showDrawer,
        GIA: showGIA,
        GPLoaded,
        addComplete,
        pageIndex,
        proListPage,
        termsPage,
        pageCount,
        submitComplete,
        acceptTerms,
        footerHeight,
        bulkEdit,
        group,
        viewstate,
        isManualAdd,
        draftContracts,
        sendToGP,
        employeeID,
        createContract,
        isUploading,
        handleIsUploading,
        handleCreateContract,
        handleViewDetails,
        handleSubmitComplete,
        handleDraftContracts,
        handleSendToGP,
        handleGroupChange,
        handleDrawerAction,
        handleTriggerChat,
        handleFooterChange,
        handlePageChange,
        handleBulkEdit,
        handleIsManualAdd,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export function useDrawer() {
  const context = useContext(DrawerContext);
  return context;
}
