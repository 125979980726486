import styled from "styled-components";
import { colors } from "../../assets/tokens/tokens";

export const colorThemes = {
  default: {
    strokeColor: colors.graysTextPrimary,
    backgroundColor: colors.graysWhite,
    borderColor: colors.grays400,
    stroke: 2,
  },
  checked: {
    strokeColor: colors.graysWhite,
    backgroundColor: colors.primaryGPBlue,
    borderColor: colors.primaryGPBlue,
    stroke: 2,
  },
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
