import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";

export const Wrapper = styled.div<{
  $bgcolor: string;
  $color: string;
  $iconpos: "left" | "right" | "none";
}>`
  ${type.pSmall};
  color: ${(p) => p.$color};
  background-color: ${(p) => p.$bgcolor};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px ${(p) => (p.$iconpos === "right" ? 8 : 16)}px 4px
    ${(p) => (p.$iconpos === "left" ? 8 : 16)}px;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  gap: 4px;
  width: min-content;
  align-self: center;
`;
