import { useEffect, useState } from "react";

export function useLocalStore(key: string, initialValue: any = null) {
  const [value, setValue] = useState(() => {
    const stored = JSON.parse(localStorage.getItem(key));
    return stored || initialValue;
  });

  useEffect(() => {
    console.log("hi");
    localStorage.setItem(key, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
}
