import * as Styled from "../Styles";
import Icon, { IconNames } from "../../../../assets/icons/Icons";
import PDFDoc from "../../../../assets/images/pdf.tsx";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton.tsx";

const topCountries = [
  { country: "Canada" },
  { country: "China" },
  { country: "Germany" },
  { country: "India" },
  { country: "Mexico" },
  { country: "Philippines" },
  { country: "Spain" },
  { country: "UK" },
];

interface Props {
  country?: boolean[];
}

export default function Benefits(props: Props) {
  const { country = null } = props;

  const setCountry = () => {
    if (!country) return "UK";
    let index = country.indexOf(true);
    return index !== -1 ? topCountries[index].country : "UK";
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Review {setCountry()} benefits.</Styled.Title>
      <Styled.Description>
        The following benefits apply to the country of employment. You'll need to work with G-P to
        make changes to the offered benefits.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <Styled.TableTitle $offered={true}>Offering</Styled.TableTitle>
      <Styled.TableItem $isLast={false}>
        <PDFDoc />
        <Styled.DocItem>
          <Styled.BenefitType>Pension (Mandatory)</Styled.BenefitType>
          <Styled.BenefitName>G-P Team Member 2022 Plan Zurich Life</Styled.BenefitName>
        </Styled.DocItem>
      </Styled.TableItem>
      <Styled.TableItem $isLast={true}>
        <PDFDoc />
        <Styled.DocItem>
          <Styled.BenefitType> Medical (Supplemental)</Styled.BenefitType>
          <Styled.BenefitName>Irish Life Gold Standard 2022</Styled.BenefitName>
        </Styled.DocItem>
      </Styled.TableItem>
      <Styled.TableTitle $offered={false}>Not Offered</Styled.TableTitle>
      <Styled.TableItem $isLast={true}>
        <PDFDoc />
        <Styled.DocItem>
          <Styled.BenefitType>Dental (Supplemental)</Styled.BenefitType>
          <Styled.BenefitName>G-P Team Member 2022 Plan</Styled.BenefitName>
        </Styled.DocItem>
      </Styled.TableItem>
    </Styled.QuestionWrapper>
  );
}
