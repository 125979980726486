import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, type } from "../../assets/tokens/tokens";

export const CommentsBar = styled(motion.div)`
box-sizing:border-box;
  padding: 0;
  font-family: -apple-system; BlinkMacSystemFont; "Segoe UI"; "Roboto"; "Oxygen"; "Ubuntu";
    "Cantarell"; "Fira Sans"; "Droid Sans"; "Helvetica Neue"; sans-serif;
  font-weight: normal;
  font-style: normal;
  background-color: ${colors.grays200};
  border-left:1px solid ${colors.grays300};
  font-size: 12px;
  color: rgb(148; 148; 148);
  line-height: 2em;
  letter-spacing: 0.01em;
  margin:0;
  width: 250px;
  overflow-y:auto;
  overflow-x:hidden;
  height:100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: ${colors.graysWhite};
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grays400};
    border-radius: 20px;
    border: 4px solid ${colors.graysWhite};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.grays400};
    border-radius: 20px;
    border: 4px solid ${colors.graysWhite};
    cursor: default;
  }
`;

export const NoComments = styled.div`
  ${type.pSmall};
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  color: ${colors.graysTextDisabled};
  padding: 24px;
`;
