import { createContext, useContext, useState } from "react";
import { ADPScreens, ChatMessage, Direction } from "../components/EnumsAndTypes/EnumsAndTypes";
import { NoMSADrawerAction, NoMSAView } from "../components/EnumsAndTypes/EnumsAndTypes";

const NoMSADrawerContext = createContext(null);

interface Props {
  children?: any;
  showDrawer?: boolean;
  showGIA?: boolean;
  onTriggerChat?: (messages: ChatMessage[]) => void;
  onChangeGIA?: (state: boolean) => void;
  onChangeDrawer?: (state: boolean) => void;
  onChangeADP?: (screen: ADPScreens) => void;
  connectLiveAgent?: (connect: boolean) => void;
}

export function NoMSADrawerProvider(props: Props) {
  const {
    children,
    showDrawer = false,
    showGIA = false,
    onTriggerChat = () => null,
    onChangeDrawer = () => null,
    onChangeGIA = () => null,
    onChangeADP = () => null,
    connectLiveAgent = () => null,
  } = props;

  const [GPLoaded, setGPLoaded] = useState(false);
  const [view, setView] = useState(NoMSAView.Connecting);
  const [quotePageIndex, setQuotePageIndex] = useState(0);
  const [schedulePageIndex, setSchedulePageIndex] = useState(0);
  const [callbackPageIndex, setCallbackPageIndex] = useState(0);
  const [quotePageCount] = useState(6);
  const [schedulePageCount] = useState(3);
  const [callBackPageCount] = useState(2);
  const [footerHeight, setFooterHeight] = useState(0);
  const [direction, setDirection] = useState(Direction.forward);

  const handleTriggerChat = (messages: ChatMessage[]) =>
    messages ? onTriggerChat(messages) : null;

  const handleFooterChange = (view: NoMSAView) => {
    const updateHeight = () => {
      switch (view) {
        case NoMSAView.GetStarted:
          setFooterHeight(329);
          return;
        case NoMSAView.Connecting:
          setFooterHeight(0);
          return;
        default:
          setFooterHeight(79);
          return;
      }
    };
    setTimeout(() => updateHeight(), 500);
  };

  const handlePageChange = (view: NoMSAView, page: number) => {
    if (view === NoMSAView.Quote) setQuotePageIndex(page);
    if (view === NoMSAView.Schedule) setSchedulePageIndex(page);
    if (view === NoMSAView.CallbBack) setCallbackPageIndex(page);
  };

  const scrollPage = (amount: number) => {
    if (!view) return;
    let index: number, count: number;
    if (view === NoMSAView.Quote) {
      index = quotePageIndex + amount;
      count = quotePageCount;
    }
    if (view === NoMSAView.Schedule) {
      index = schedulePageIndex + amount;
      count = schedulePageCount;
    }
    if (view === NoMSAView.CallbBack) {
      index = callbackPageIndex + amount;
      count = callBackPageCount;
    }
    if (amount > 0 && index > count) return;
    if (amount < 1 && index < 0) return;
    if (view === NoMSAView.Quote) setQuotePageIndex(index);
    if (view === NoMSAView.Schedule) setSchedulePageIndex(index);
    if (view === NoMSAView.CallbBack) setCallbackPageIndex(index);
  };

  const handleDrawerAction = (action: NoMSADrawerAction, dir?: Direction) => {
    setDirection(dir ? dir : direction);
    switch (action) {
      case NoMSADrawerAction.Cancel:
      case NoMSADrawerAction.GPLoaded:
        setView(NoMSAView.GetStarted);
        setGPLoaded(true);
        setFooterHeight(79); // hack becuase of headr pushing exiting div down
        handleFooterChange(NoMSAView.GetStarted);
        return;
      case NoMSADrawerAction.ToggleGIA:
        onChangeGIA(!showGIA);
        return;
      case NoMSADrawerAction.Next:
        scrollPage(1);
        return;
      case NoMSADrawerAction.Previous:
        scrollPage(-1);
        return;
      case NoMSADrawerAction.DoubleBack:
        scrollPage(-2);
        return;
      case NoMSADrawerAction.ConnectLiveAgent:
        onChangeGIA(true);
        connectLiveAgent(true);
        return;
      case NoMSADrawerAction.LoadQuote:
        setView(NoMSAView.Quote);
        setQuotePageIndex(0);
        return;
      case NoMSADrawerAction.LoadCallBack:
        setView(NoMSAView.CallbBack);
        setCallbackPageIndex(0);
        return;
      case NoMSADrawerAction.LoadSchedule:
        setView(NoMSAView.Schedule);
        setSchedulePageIndex(0);
        return;
      case NoMSADrawerAction.LoadContactUs:
        setView(NoMSAView.ContactUs);
        setSchedulePageIndex(0);
        setCallbackPageIndex(0);
        return;
      case NoMSADrawerAction.LoadGetStarted:
        setView(NoMSAView.GetStarted);
        return;
      default:
        //nothing for now
        return;
    }
  };

  const pageStates = {
    quote: { pageIndex: quotePageIndex, pageCount: quotePageCount },
    schedule: { pageIndex: schedulePageIndex, pageCount: schedulePageCount },
    callback: { pageIndex: callbackPageIndex, pageCount: callBackPageCount },
  };

  return (
    <NoMSADrawerContext.Provider
      value={{
        visible: showDrawer,
        GIA: showGIA,
        GPLoaded,
        quotePageIndex,
        schedulePageIndex,
        callbackPageIndex,
        quotePageCount,
        schedulePageCount,
        callBackPageCount,
        footerHeight,
        pageStates,
        viewstate: { view: view, direction: direction },
        handleDrawerAction,
        handleTriggerChat,
        handleFooterChange,
        handlePageChange,
      }}
    >
      {children}
    </NoMSADrawerContext.Provider>
  );
}

export function useNoMSADrawer() {
  const context = useContext(NoMSADrawerContext);
  return context;
}
