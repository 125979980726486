import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  gap: 10px;
  color: #0000ff;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  padding: 0 12px;
  height: 36px;
  background-color: rgba(0, 0, 255, 0.05);
`;

export const IconWrapper = styled(motion.div)<{ $showConnecting: boolean }>`
  width: 24px;
  height: 24px;
  display: ${(p) => (p.$showConnecting ? "block" : "none")};
`;

export const XIconWrapper = styled(motion.div)<{ $show: boolean }>`
  height: 24px;
  width: 24px;
  display: ${(p) => (p.$show ? "block" : "none")};
`;
