import styled from "styled-components";
import { motion } from "framer-motion";

export const Body = styled.div<{ $footerHeight: number }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  overflow: hidden;
  margin-bottom: ${(p) => p.$footerHeight}px;
`;

export const ContainerView = styled(motion.div)`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;
