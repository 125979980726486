import { createContext, useContext, useEffect, useState } from "react";
import {
  ChatMessage,
  LiveConnection,
  MessageSender,
} from "../components/EnumsAndTypes/EnumsAndTypes";
import { initialMessages, triggerPreMessage } from "../components/GIADrawer/GIAData";
import { fetchChatGPTResponse } from "../components/ChatGPT/ChatGPT";
import { usePartner } from "../hooks/usePartner";

const greetingMessage: Array<ChatMessage> = [
  {
    message: {
      title: "Camila:",
      text: "Hi Janice 👋. I'm Camila from G-P. How can I help you today?",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "FYI, if you'd like to connect with audio as well just click the button below.",
    },
    actions: [{ name: "Connect audio", type: "audio" }],
    sender: MessageSender.GIA,
  },
];
const disconnectMessage: Array<ChatMessage> = [
  {
    message: { text: "Disconnected from Camila.", title: null },
    sender: MessageSender.GIA,
  },
];

const GIAContext = createContext(null);

interface Props {
  children?: any;
  showDrawer?: boolean;
  showMessages?: boolean;
  triggerMessages?: ChatMessage[];
  toggleGIA?: () => void;
  onChangeMessages?: (messages: ChatMessage[]) => void;
  onChangeLiveAgent?: (connected: boolean) => void;
  connectLiveAgent?: boolean;
}

export function GIAProvider(props: Props) {
  const {
    children,
    showDrawer = false,
    showMessages = false,
    triggerMessages = null,
    toggleGIA = () => null,
    onChangeMessages = () => null,
    onChangeLiveAgent = () => null,
    connectLiveAgent = false,
  } = props;
  const [visible, setVisible] = useState(showDrawer);
  const [messages, setMessages] = useState(null);
  const [connected, setConnected] = useState(LiveConnection.Disconnected);
  const [lastMessages, setLastMessages] = useState(null);
  const [chatGTPThinking, setChatGTPThinking] = useState(false);
  const { partnerName } = usePartner();
  useEffect(() => {
    setVisible(showDrawer);
  }, [showDrawer]);
  useEffect(() => {
    onChangeMessages(messages);
  }, [messages]);
  useEffect(() => {
    handleConnect(connectLiveAgent);
  }, [connectLiveAgent]);

  const clickGIA = () => toggleGIA();

  const handleConnect = (connect: boolean) => {
    let timer = null;
    if (connect && connected !== LiveConnection.Connected) {
      setConnected(LiveConnection.Connecting);
      timer = setTimeout(() => {
        setLastMessages(messages);
        setMessages(greetingMessage);
        setConnected(LiveConnection.Connected);
      }, 4000);
    }
    if (!connect && connected !== LiveConnection.Disconnected) {
      setConnected(LiveConnection.Disconnecting);
      timer = setTimeout(() => {
        if (lastMessages) {
          setMessages([...lastMessages, ...disconnectMessage]);
        } else {
          setMessages(initialMessages);
        }
        setConnected(LiveConnection.Disconnected);
        onChangeLiveAgent(false);
      }, 2000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  };

  const handleSendMessage = async (newMessage: ChatMessage) => {
    //create array of old messages adding new one
    const newMessages = [...messages, newMessage];
    // dislplay the new message in the chat UI
    setMessages(newMessages);
    // set thinking state will getting response from GTP
    setChatGTPThinking(true);
    // send the new message and other messages to chatGPT and wait response
    const { message, type } = await fetchChatGPTResponse(newMessages, partnerName);
    // process the mesage into the Chat UI
    const responseMessage: ChatMessage = {
      message: {
        text: message ? message.content : "Hmm... Something went wrong. What was that you asked?",
      },
      sender: MessageSender.GIA,
      display: true,
    };
    // set the new message list and display it
    setChatGTPThinking(false);
    const messagesWithResponse = [...newMessages, responseMessage];
    setMessages(messagesWithResponse);
  };

  useEffect(() => {
    if (showMessages && !messages) setMessages(initialMessages);
  }, [showMessages]);

  useEffect(() => {
    if (triggerMessages) addToMessages(triggerMessages);
  }, [triggerMessages]);

  const addToMessages = (msgs: ChatMessage[]) => {
    let newMessages = [];
    if (!messages) {
      newMessages = [...triggerPreMessage, ...msgs];
    } else {
      const lastmessage = messages[messages.length - 1].message;
      const lastTriggerMessage = msgs[msgs.length - 1].message;
      if (lastmessage.text === lastTriggerMessage.text) return;
      newMessages = [...messages, ...msgs];
    }
    setMessages(newMessages);
  };

  return (
    <GIAContext.Provider
      value={{
        connected,
        visible,
        showDrawer,
        messages,
        chatGTPThinking,
        handleSendMessage,
        clickGIA,
        handleConnect,
      }}
    >
      {children}
    </GIAContext.Provider>
  );
}

export function useGIA() {
  const context = useContext(GIAContext);
  return context;
}
