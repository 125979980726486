import * as Styled from "../Styles";
import InputFormField from "../../../InputFormField/InputFormField";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import InputButtonList from "../../../InputButtonList/InputButtonList";
import { ButtonType } from "../../../InputButton/InputButton";

const buttonOptions = [{ text: "Yes" }, { text: "No" }];

interface Props {
  firstName?: string;
  data?: any;
  onChange?: (fieldname: string, data: any) => void;
}

export default function CompDetails(props: Props) {
  const { firstName = "Daniel", data = null, onChange = () => null } = props;

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Tell us more details about {firstName}'s compensation.</Styled.Title>
      <Styled.Description>
        We'll use this information to generate {firstName}'s employment contract.
      </Styled.Description>
      <Styled.FormFieldsWrapper>
        <InputFormField
          button={"left"}
          buttontype={"currency"}
          required={false}
          autocomplete={"off"}
          label="Annual salary"
          fieldname="annualSalary"
          type={"text"}
          value={data.annualSalary}
          onChange={(value) => onChange("annualSalary", value)}
          errormessage={"Please enter a valid annual salary amount."}
        />
      </Styled.FormFieldsWrapper>

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Is eligible for sign-on bonus?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.signBonus}
        onChange={(values) => onChange("signBonus", values)}
      />

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Is eligible for an annual bonus?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.annualBonus}
        onChange={(values) => onChange("annualBonus", values)}
      />

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Is eligible for comissions/incentive programs?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.comissions}
        onChange={(values) => onChange("comissions", values)}
      />
    </Styled.QuestionWrapper>
  );
}
