import { colors } from "../tokens/tokens";

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

export function ADPLogo(props: Props) {
  const { color = colors.ADPRed, width = 120, height = null } = props;
  const dimensions = () => {
    if (height) return { height: height };
    return { width: width };
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...dimensions()} viewBox={"0 0 220 100"}>
      <g transform="translate(0.001 0.001)">
        <path
          d="M 180.755 21.402 L 168.273 21.402 L 168.273 33.873 L 180.755 33.873 C 182.175 33.874 183.537 34.44 184.54 35.446 C 185.543 36.451 186.105 37.814 186.103 39.234 C 186.103 42.199 183.717 44.582 180.755 44.582 L 168.273 44.582 L 168.273 57.064 L 180.755 57.064 C 190.516 56.942 198.365 48.995 198.365 39.233 C 198.365 29.471 190.516 21.524 180.755 21.402 Z M 109.609 87.394 C 124.554 87.394 137.432 78.641 143.448 65.976 L 159.364 65.976 L 159.364 87.394 L 168.27 87.394 L 168.27 65.976 L 180.755 65.976 C 195.539 65.976 207.513 54.002 207.513 39.231 C 207.513 24.461 195.539 12.482 180.755 12.482 L 159.364 12.482 L 159.364 44.582 L 146.666 44.582 C 144.078 26.442 128.47 12.482 109.609 12.482 L 65.709 12.482 L 22.037 87.394 L 32.455 87.394 L 44.937 65.976 L 89.816 65.976 L 89.816 87.394 L 109.612 87.394 Z M 219.998 39.231 C 219.998 60.897 202.426 78.457 180.755 78.457 L 180.755 99.854 L 146.885 99.854 L 146.885 83.162 C 137.721 93.408 124.429 99.854 109.609 99.854 L 77.331 99.854 L 77.331 78.457 L 52.104 78.457 L 39.623 99.854 L -0 99.854 L 58.24 0 L 109.612 0 C 124.432 0 137.724 6.463 146.885 16.709 L 146.885 0 L 180.758 0 C 202.429 0 220 17.574 220 39.234 Z M 77.329 21.402 L 77.329 44.582 L 57.416 44.582 L 50.143 57.064 L 89.813 57.064 L 89.813 21.402 L 77.331 21.402 Z M 138.148 49.927 C 138.148 65.687 125.367 78.457 109.609 78.457 L 98.728 78.457 L 98.728 65.976 L 109.609 65.976 C 113.868 65.979 117.953 64.29 120.965 61.28 C 123.978 58.27 125.67 54.186 125.669 49.927 C 125.664 41.06 118.476 33.874 109.609 33.873 L 98.728 33.873 L 98.728 21.402 L 109.609 21.402 C 125.364 21.402 138.151 34.175 138.151 49.927"
          fill={color}
        ></path>
        <path
          d="M 180.755 21.402 L 168.273 21.402 L 168.273 33.873 L 180.755 33.873 C 182.175 33.874 183.537 34.44 184.54 35.446 C 185.543 36.451 186.105 37.814 186.103 39.234 C 186.103 42.199 183.717 44.582 180.755 44.582 L 168.273 44.582 L 168.273 57.064 L 180.755 57.064 C 190.516 56.942 198.365 48.995 198.365 39.233 C 198.365 29.471 190.516 21.524 180.755 21.402 Z M 109.609 87.394 C 124.554 87.394 137.432 78.641 143.448 65.976 L 159.364 65.976 L 159.364 87.394 L 168.27 87.394 L 168.27 65.976 L 180.755 65.976 C 195.539 65.976 207.513 54.002 207.513 39.231 C 207.513 24.461 195.539 12.482 180.755 12.482 L 159.364 12.482 L 159.364 44.582 L 146.666 44.582 C 144.078 26.442 128.47 12.482 109.609 12.482 L 65.709 12.482 L 22.037 87.394 L 32.455 87.394 L 44.937 65.976 L 89.816 65.976 L 89.816 87.394 L 109.612 87.394 Z M 219.998 39.231 C 219.998 60.897 202.426 78.457 180.755 78.457 L 180.755 99.854 L 146.885 99.854 L 146.885 83.162 C 137.721 93.408 124.429 99.854 109.609 99.854 L 77.331 99.854 L 77.331 78.457 L 52.104 78.457 L 39.623 99.854 L -0 99.854 L 58.24 0 L 109.612 0 C 124.432 0 137.724 6.463 146.885 16.709 L 146.885 0 L 180.758 0 C 202.429 0 220 17.574 220 39.234 Z M 77.329 21.402 L 77.329 44.582 L 57.416 44.582 L 50.143 57.064 L 89.813 57.064 L 89.813 21.402 L 77.331 21.402 Z M 138.148 49.927 C 138.148 65.687 125.367 78.457 109.609 78.457 L 98.728 78.457 L 98.728 65.976 L 109.609 65.976 C 113.868 65.979 117.953 64.29 120.965 61.28 C 123.978 58.27 125.67 54.186 125.669 49.927 C 125.664 41.06 118.476 33.874 109.609 33.873 L 98.728 33.873 L 98.728 21.402 L 109.609 21.402 C 125.364 21.402 138.151 34.175 138.151 49.927"
          fill={color}
        ></path>
        <path
          d="M 180.755 21.402 L 168.273 21.402 L 168.273 33.873 L 180.755 33.873 C 182.175 33.874 183.537 34.44 184.54 35.446 C 185.543 36.451 186.105 37.814 186.103 39.234 C 186.103 42.199 183.717 44.582 180.755 44.582 L 168.273 44.582 L 168.273 57.064 L 180.755 57.064 C 190.516 56.942 198.365 48.995 198.365 39.233 C 198.365 29.471 190.516 21.524 180.755 21.402 Z M 109.609 87.394 C 124.554 87.394 137.432 78.641 143.448 65.976 L 159.364 65.976 L 159.364 87.394 L 168.27 87.394 L 168.27 65.976 L 180.755 65.976 C 195.539 65.976 207.513 54.002 207.513 39.231 C 207.513 24.461 195.539 12.482 180.755 12.482 L 159.364 12.482 L 159.364 44.582 L 146.666 44.582 C 144.078 26.442 128.47 12.482 109.609 12.482 L 65.709 12.482 L 22.037 87.394 L 32.455 87.394 L 44.937 65.976 L 89.816 65.976 L 89.816 87.394 L 109.612 87.394 Z M 219.998 39.231 C 219.998 60.897 202.426 78.457 180.755 78.457 L 180.755 99.854 L 146.885 99.854 L 146.885 83.162 C 137.721 93.408 124.429 99.854 109.609 99.854 L 77.331 99.854 L 77.331 78.457 L 52.104 78.457 L 39.623 99.854 L -0 99.854 L 58.24 0 L 109.612 0 C 124.432 0 137.724 6.463 146.885 16.709 L 146.885 0 L 180.758 0 C 202.429 0 220 17.574 220 39.234 Z M 77.329 21.402 L 77.329 44.582 L 57.416 44.582 L 50.143 57.064 L 89.813 57.064 L 89.813 21.402 L 77.331 21.402 Z M 138.148 49.927 C 138.148 65.687 125.367 78.457 109.609 78.457 L 98.728 78.457 L 98.728 65.976 L 109.609 65.976 C 113.868 65.979 117.953 64.29 120.965 61.28 C 123.978 58.27 125.67 54.186 125.669 49.927 C 125.664 41.06 118.476 33.874 109.609 33.873 L 98.728 33.873 L 98.728 21.402 L 109.609 21.402 C 125.364 21.402 138.151 34.175 138.151 49.927"
          fill={color}
        ></path>
      </g>
    </svg>
  );
}

export default ADPLogo;
