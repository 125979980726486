import * as Styled from "./Styles";
import { useEffect, useState } from "react";
import { usePresence, useAnimate, useMotionValue, motion } from "framer-motion";

interface Props {
  checked?: boolean;
  size?: number;
  stroke?: number;
  bgcolor?: string;
  strokecolor?: string;
  bordercolor?: string;
  theme?: "default";
  onChange?: (state) => void;
}

export default function InputCheckBox(props: Props) {
  const {
    checked = false,
    size = 24,
    stroke = null,
    bgcolor = null,
    strokecolor = null,
    bordercolor = null,
    onChange = () => null,
  } = props;
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => setIsChecked(checked), [checked]);
  const [checkRef, checkAnimate] = useAnimate();
  const [borderRef, borderAnimate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();
  const pathlength = useMotionValue(1);
  const checkedTheme = Styled.colorThemes.checked;
  const uncheckedTheme = Styled.colorThemes.default;

  useEffect(() => {
    if (isPresent) {
      if (isChecked) {
        checkAnimate(
          checkRef.current,
          { pathOffset: 0, stroke: checkedTheme.strokeColor },
          { duration: 0.25 }
        );
        borderAnimate(
          borderRef.current,
          { fill: checkedTheme.backgroundColor, stroke: checkedTheme.borderColor },
          { duration: 0.1 }
        );
      } else {
        checkAnimate(
          checkRef.current,
          { pathOffset: 1, stroke: uncheckedTheme.strokeColor },
          { duration: 0.1 }
        );
        borderAnimate(
          borderRef.current,
          { fill: uncheckedTheme.backgroundColor, stroke: uncheckedTheme.borderColor },
          { duration: 0.1 }
        );
      }
    } else {
      safeToRemove();
    }
  });

  const handleChecked = () => {
    if (isPresent) {
      if (isChecked) {
        checkAnimate(
          checkRef.current,
          { pathOffset: 0, stroke: checkedTheme.strokeColor },
          { duration: 0.1 }
        );
        borderAnimate(
          borderRef.current,
          { fill: checkedTheme.backgroundColor, stroke: checkedTheme.borderColor },
          { duration: 0.1 }
        );
      } else {
        checkAnimate(
          checkRef.current,
          { pathOffset: 1, stroke: uncheckedTheme.strokeColor },
          { duration: 0.1 }
        );
        borderAnimate(
          borderRef.current,
          { fill: uncheckedTheme.backgroundColor, stroke: uncheckedTheme.borderColor },
          { duration: 0.1 }
        );
      }
      setIsChecked(!isChecked);
      onChange(!isChecked);
    } else {
      safeToRemove();
    }
  };

  return (
    <Styled.Wrapper onClick={() => handleChecked()}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
        <path
          ref={borderRef}
          d="M 3 7 C 3 4.791 4.791 3 7 3 L 17 3 C 19.209 3 21 4.791 21 7 L 21 17 C 21 19.209 19.209 21 17 21 L 7 21 C 4.791 21 3 19.209 3 17 Z"
          fill={bgcolor ? bgcolor : Styled.colorThemes.default.backgroundColor}
          strokeWidth={stroke ? stroke : Styled.colorThemes.default.stroke}
          stroke={bordercolor ? bordercolor : Styled.colorThemes.default.borderColor}
        ></path>
        <motion.path
          ref={checkRef}
          d="M 15.204 10.113 L 11.202 14.113 L 8.796 11.713"
          fill="transparent"
          strokeWidth={stroke ? stroke : Styled.colorThemes.default.stroke}
          stroke={strokecolor ? strokecolor : Styled.colorThemes.default.strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray=""
          style={{ pathLength: pathlength }}
        ></motion.path>
      </svg>
    </Styled.Wrapper>
  );
}
