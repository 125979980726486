import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import imgDone from "../../assets/images/done_full@24.svg";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  height: min-content;
  gap: 16px;
  border-color: ${colors.grays400};
  width: 100%;
`;

export const QuoteInfo = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

export const Disclaimer = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextDisabled};
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
`;

export const ProductSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 16px;
  min-width: 150px;
  margin-top: 6px;
`;

export const ValueSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  height: min-content;
  justify-content: flex-start;
  padding: 0px;
  width: 100%;
  gap: 4px;
`;

export const PricingBlur = styled.div`
  filter: blur(5px);
  opacity: 0.3;
`;

export const DescriptionSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  height: min-content;
  justify-content: flex-start;
  width: 100%;
  padding: 0px;
  margin-right: 32px;
  gap: 0px;
  margin-left: 24px;
  flex: 1;
`;

export const Info = styled.div<{ $show: boolean }>`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  display: inherit;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  cursor: pointer;
`;

export const Image = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inherit" : "none")};
  margin: 0;
  padding: 0;
`;

export const Name = styled.div`
  ${type.sectionTitle};
  ${colors.graysTextPrimary}
  margin:0;
  padding: 0;
  text-align: center;
  white-space: pre-wrap;
`;

export const Qualifier = styled.div<{ $show: boolean; $strike: boolean }>`
  display: ${(p) => (p.$show ? "inherit" : "none")};
  ${type.pSmall};
  ${colors.graysTextDisabled};
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: ${(p) => (p.$strike ? "line-through" : "none")};
  filter: blur(8px);
  opcity: 0.3;
`;

export const Value = styled.div`
  ${type.h2};
  color:${colors.graysTextPrimary}
  margin:0px;
  padding: 0px;
  white-space: pre-wrap;

`;

export const ValueType = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextDisabled};
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: pre-wrap;
`;

export const Seperator = styled.div`
  height: 1px;
  background-color: ${colors.grays200};
  width: 100%;
  margin: 0;
  padding: 0;
  display: none;
`;

export const Description = styled.div`
  ${type.pSmall};
  color:${colors.graysTextPrimary}
  margin:0;
  padding: 0;
`;

export const List = styled.ul<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  ${type.pRegular}
  flex-direction:column;

  margin: 0;
  padding: 0;
  opacity: 0.8;
`;

export const ListItem = styled.li`
  ${type.pSmall}
  font-size:14px;
  color:${colors.graysTextPrimary}
  list-style-position: outside;
  list-style-type: none;
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  line-height: 1.2em;
  padding: 0;
  background-image: url(${imgDone});
  background-repeat: no-repeat;
  line-height: 24px;
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  width: 100%;
  padding-top: 16px;
  justify-content: center;
  align-items: center;
`;

export const Asterisk = styled.span`
  ${type.pBoldFace};
`;
