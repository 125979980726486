import styled from "styled-components";
import { colors, transitions, type } from "../../../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  position: relative;
  boxsizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1 0 0;
`;

export const Content = styled(motion.div)`
  position: absolute;
  boxsizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 24px 48px;
  flex: 1 0 0;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Responses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0px;
  padding: 0px;
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
`;

export const SubTitle = styled.p`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  margin-top: 40px;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -8px 0px 16px 0px;
  padding: 0px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export const LinkButton = styled.div`
  ${type.pSmall};
  color: ${colors.primaryGPBlue};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  gap: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormFieldsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const Name = styled.div`
  ${type.pRegularBold};
  color: ${colors.graysTextPrimary};
  line-height: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Compensation = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  ${type.pRegular};
  color: ${colors.graysTextPrimary};
  line-height: 1em;
`;

export const TableItem = styled.div<{ $isLast: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 16px 20px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  border: 1px solid ${colors.grays400};
  ${(p) => (p.$isLast ? "margin-bottom: 32px" : null)};
`;

export const TableItemHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  gap: 16px;
`;

export const Details = styled.div<{ $isLeft: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.$isLeft ? "flex-start" : "flex-end")};
  justify-content: center;
  gap: 0px;
  flex: 1;
`;

export const LineItem = styled.div`
  ${type.pMedium};
  color: ${colors.graysTextTertiary};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  border-top: 1;
`;

export const Pagging = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  padding: 0 16px;
`;
