import { useEffect, useState } from "react";
import * as SC from "./Styles";
import { useAnimationControls } from "framer-motion";
import { useDrawer } from "../../contexts/DrawerContext";
import { View } from "../EnumsAndTypes/EnumsAndTypes";

export default function DrawerProgres() {
  const [counter, setCounter] = useState(0);
  const [priorPage, setPriorPage] = useState(0);
  const { pageIndex, viewstate, isManualAdd } = useDrawer();
  const animate = useAnimationControls();

  useEffect(() => {
    let thispage = 0;
    if (pageIndex === 0) {
      setCounter(0);
    }
    if (pageIndex > priorPage) {
      thispage = counter + 1;
      setCounter(counter + 1);
    }
    if (pageIndex < priorPage) {
      thispage = counter - 1;
      setCounter(counter - 1);
    }
    setPriorPage(pageIndex);
    const pages = isManualAdd ? 10 : 2;
    const width = `${Math.ceil((thispage / pages) * 100)}%`;
    animate.start({
      width: width,
      opacity: 1,
      transition: { ease: "easeInOut", duration: 1 },
    });
  }, [pageIndex]);

  if (viewstate.view !== View.AddSingle) return null;
  return (
    <SC.ProgressTrack>
      <SC.ProgressBar initial={{ width: 0 }} animate={animate} />
    </SC.ProgressTrack>
  );
}
