import { createContext, useContext, useEffect, useState } from "react";
import { Country } from "../assets/flags/Flag";
import { ProStatus } from "../components/EnumsAndTypes/EnumsAndTypes";
import { useLocalStore } from "../hooks/useLocalStore";

const ProsContext = createContext(null);

type Pro = {
  name: string;
  email: string;
  salary: number;
  title: string;
  country: Country;
  phone: string;
  startDate: string;
  visa: boolean;
  jobduties: "Orchestrator";
  bonus: boolean;
  signon: boolean;
  comission: boolean;
  currency: string;
  symbol: boolean;
  morebenefits: boolean;
  allowance: boolean;
  covenants: boolean;
  status: ProStatus;
};
const initialCounts = { all: 0, editing: 0, ready: 0, sent: 0, signed: 0, active: 0, filtred: 0 };

export function ProsProvider({ children }) {
  const [allPros, setAllPros] = useState<Pro[]>([]);
  const [proCounts, setProCounts] = useState(initialCounts);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(null);
  const [filtredPros, setFiltredPros] = useState<Pro[]>([]);
  const [contractSent, setContractSent] = useLocalStore("contractSent");

  // on refresh clear the local stored values
  useEffect(() => setContractSent(null), []);

  const handleAddPros = (records: Pro[]) => {
    let newList = allPros;
    newList.push(...records);
    setAllPros(newList);
    handleFilterPros({ pros: newList });
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    handleFilterPros({ search: value });
  };

  const handleStatusChange = (value: ProStatus) => {
    setStatus(value);
    handleFilterPros({ status: value });
  };

  const handleBulkChangeStatus = (options: { status: ProStatus; pros?: number[] }) => {
    const updated = allPros;
    if (updated && !options.pros) {
      updated.forEach((pro) => {
        pro.status = options.status;
      });
    } else if (updated && options.pros) {
      options.pros.forEach((id) => {
        updated[id].status = options.status;
      });
    }
    setAllPros(updated);
    handleFilterPros({ pros: updated });
  };

  const handleChangePro = (index: number, values: [{}]) => {
    let updated = allPros;
    if (updated[index]) {
      for (const [key, value] of Object.entries(values)) {
        updated[index][key] = value;
      }
    }
    setAllPros(updated);
    handleFilterPros({ pros: updated });
  };

  const handleChangeProByEmail = (email: string, values: [{}]) => {
    const index = allPros.findIndex((pro) => {
      return pro.email === email;
    });
    if (index) {
      const updated = allPros;
      for (const [key, value] of Object.entries(values)) {
        updated[index][key] = value;
      }
      setAllPros(updated);
      const compare = (a: any, b: any) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      };
      const filtred = updated
        .filter((pro) => (search ? pro.name.toLowerCase().includes(search.toLowerCase()) : true))
        .filter((pro) => (status ? pro.status === status : true))
        .sort(compare);
      setFiltredPros(filtred);
      const all = updated.length;
      const editing = updated.filter((pro) => pro.status === ProStatus.editing).length;
      const ready = updated.filter((pro) => pro.status === ProStatus.ready).length;
      const sent = updated.filter((pro) => pro.status === ProStatus.sent).length;
      const signed = updated.filter((pro) => pro.status === ProStatus.signed).length;
      const active = updated.filter((pro) => pro.status === ProStatus.active).length;
      setProCounts({ all, editing, ready, sent, signed, active, filtred: filtred.length });
    }
  };

  const handleFilterPros = (options?: { pros?: Pro[]; search?: string; status?: ProStatus }) => {
    const prosToFilter = options.pros ? options.pros : allPros;
    const compare = (a: any, b: any) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    };
    if (allPros.length > 0) {
      const filtred = prosToFilter
        .filter((pro) =>
          options.search ? pro.name.toLowerCase().includes(options.search.toLowerCase()) : true
        )
        .filter((pro) => (options.status ? pro.status === options.status : true))
        .sort(compare);
      setFiltredPros(filtred);
      const all = prosToFilter.length;
      const editing = prosToFilter.filter((pro) => pro.status === ProStatus.editing).length;
      const ready = prosToFilter.filter((pro) => pro.status === ProStatus.ready).length;
      const sent = prosToFilter.filter((pro) => pro.status === ProStatus.sent).length;
      const signed = prosToFilter.filter((pro) => pro.status === ProStatus.signed).length;
      const active = prosToFilter.filter((pro) => pro.status === ProStatus.active).length;
      setProCounts({ all, editing, ready, sent, signed, active, filtred: filtred.length });
    }
  };

  return (
    <ProsContext.Provider
      value={{
        allPros,
        filtredPros,
        proCounts,
        search,
        status,
        handleChangePro,
        handleAddPros,
        handleSearchChange,
        handleStatusChange,
        handleBulkChangeStatus,
        handleChangeProByEmail,
      }}
    >
      {children}
    </ProsContext.Provider>
  );
}

export function usePros() {
  const context = useContext(ProsContext);
  return context;
}
