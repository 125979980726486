import * as SC from "./Styles";
import GPLogo from "../../assets/images/gplogo";
import Gia from "../../assets/images/gia";
import { motion } from "framer-motion";
import { NoMSADrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import { DrawerTalkLiveButton } from "../TalkLiveButton/TalkLiveButton";
import { usePartner } from "../../hooks/usePartner";

export default function DrawerHeader() {
  const { GPLoaded, GIA, handleDrawerAction } = useNoMSADrawer();
  const { partnerLogo, partnerName } = usePartner();

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  // place nothing is GP not loaded
  if (!GPLoaded) return null;
  return (
    <SC.Header>
      <SC.TitleArea>
        <GPLogo height={26} />+{partnerLogo}
      </SC.TitleArea>
      <SC.ActionsArea variants={variants} initial={"hide"} animate={GIA ? "hide" : "show"}>
        <DrawerTalkLiveButton
          onClick={() => {
            handleDrawerAction(NoMSADrawerAction.ConnectLiveAgent);
          }}
        />
        <Gia height={28} onClick={() => handleDrawerAction(NoMSADrawerAction.ToggleGIA)} />
      </SC.ActionsArea>
    </SC.Header>
  );
}
