import * as Styled from "./Styles.ts";
import Flag, { Country } from "../../../../assets/flags/Flag.tsx";
import { ChatMessage, MessageSender, MoreInfo } from "../../../EnumsAndTypes/EnumsAndTypes.ts";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton.tsx";
import { useDrawer } from "../../../../contexts/DrawerContext.tsx";

interface Props {
  data?: any;
}

export default function ReviewInfo(props: Props) {
  const { data = null } = props;
  const { handleTriggerChat } = useDrawer();
  const dateoptions = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  const ireland = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
  const formatSalary = (value: any) => {
    return `${ireland.format(parseInt(value))} EUR`;
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Review and confirm {data?.firstName}'s details.</Styled.Title>

      <Styled.Description>
        Please review and confirm the imported for {data?.firstName} is correct.
        <MoreInfoButton triggerChat={() => handleTriggerChat(infoMessage)} />
      </Styled.Description>

      <Styled.TableItem $isLast={true}>
        <Styled.TableItemHeader>
          <Flag country={Country.Ireland} size={32} />
          <Styled.Details $isLeft={true} style={{ flexShrink: 1, overflow: "hidden" }}>
            <Styled.Name>
              {data?.firstName} {data?.lastName}
            </Styled.Name>
          </Styled.Details>
          <Styled.Details $isLeft={false}>
            <Styled.Compensation style={{ flexShrink: 0, whiteSpace: "nowrap" }}>
              {formatSalary(data.annualSalary)}
            </Styled.Compensation>
          </Styled.Details>
        </Styled.TableItemHeader>
        <Styled.LineItem>
          Job Title:<div>{data.jobTitle}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Country:<div>Ireland</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Currency:
          <div>EURO (€)</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Target Start:
          <div>{new Date(data.startDate).toLocaleDateString("en-US", dateoptions as any)}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Email:<div>{data?.email}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Phone:<div>{data?.phone}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Sign on bonus:<div>{data?.signBonus[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Receives annual bonus:<div>{data?.annualBonus[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Earns comissions:<div>{data?.comissions[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Provide addtional medical benefits:<div>{data?.medical[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Proviode additional allowances:<div>{data?.allowance[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Add termination convenants:<div>{data?.termination[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Requires visa support:<div>{data?.visa[0] ? "Yes" : "No"}</div>
        </Styled.LineItem>
      </Styled.TableItem>
    </Styled.QuestionWrapper>
  );
}

const infoMessage: Array<ChatMessage> = [
  {
    message: {
      title: "Confirming uploaded professionals",
      text: "Before continuing, it's important to make sure the information for each professional is accurate.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "Scan through different records to make sure the information is correct. If it's note use the modify button to correct the information and the fields mapped from the upload.",
    },
    sender: MessageSender.GIA,
  },
];
