import styled from "styled-components";
import { colors, type } from "../../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const variants = {
  on: {
    borderColor: colors.graysTextPrimary,
    color: colors.graysWhite,
    backgroundColor: colors.graysTextPrimary,
    cursor: "pointer",
  },
  off: {
    borderColor: colors.grays100,
    color: colors.primaryGPBlue,
    backgroundColor: colors.graysWhite,
    cursor: "pointer",
  },
  hovered: { scale: 1.04 },
  pressed: { scale: 0.98 },
};

export const TimeSlotButton = styled(motion.div)`
  ${type.pMedium};
  color: ${colors.primaryGPBlue};
  tab-index: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  user-select: none;
  flex: 1;
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 105px;
`;
