import { Fragment, PureComponent, createContext, useContext, useState } from "react";
import { Comment, Reply, InputInfo } from "../components/EnumsAndTypes/EnumsAndTypes";
import { Editor } from "@tiptap/react";
import { Selection } from "@tiptap/pm/state";

const ContractContext = createContext(null);

export function ContractProvider({ children }) {
  const [comments, setComments] = useState(null);
  const [replies, setReplies] = useState(null);
  const [editor, setEditor] = useState<Editor>(null);
  const [currentSelection, setCurrentSelection] = useState<Selection>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showCommentPopUp, setShowCommentPopUp] = useState(false);
  const [inputInfo, setInputInfo] = useState<InputInfo>(null);
  const [viewComments, setViewComments] = useState(false);
  const [activeComment, setActiveComment] = useState(null);
  const [contractID, setContractID] = useState("default");

  const handleSetContractID = (showdefault: boolean) => {
    setContractID(showdefault ? "default" : "daniel");
  };

  const handleSetActiveComment = (index: number | null) => {
    setActiveComment(index);
  };

  const handleSetComment = (props: { info: InputInfo; text: string }) => {
    const comment: Comment = {
      fragment: props.info.selection.text,
      selection: { from: props.info.selection.from, to: props.info.selection.to },
      resolved: false,
      text: props.text,
      timestamp: new Date().toString(),
    };
    if (comments) setComments([...comments, comment]);
    if (!comments) setComments([comment]);
    handleSetViewComments(true);
    handleShowCommentPopUp(false);
    editor.commands.setTextSelection({
      to: props.info.selection.from,
      from: props.info.selection.from,
    });
  };

  const handleSetReply = (reply: Reply) => {
    if (replies) setReplies([...replies, reply]);
    if (!replies) setReplies([reply]);
  };

  const handleCancelComment = (info: InputInfo) => {
    const { to, from } = info.selection;
    if (editor) {
      editor.commands.setTextSelection({ to, from });
      editor.commands.unsetHighlight();
    }
  };

  const handleResolveComment = (index: number) => {
    // get comment info
    const { to, from } = comments[index].selection;
    // remove the mark
    editor?.commands.setTextSelection({ to, from });
    editor?.commands.unsetHighlight();
    //remove the comment from state array
    const removedComments = comments?.map((comment: Comment, i: number) => {
      return i !== index ? comment : { ...comment, resolved: true };
    });
    setComments(removedComments);
  };

  const handleSetViewComments = (value: boolean) => {
    setViewComments(value);
  };

  const handleSetInputInfo = (info: InputInfo) => {
    setInputInfo(info);
  };

  const handleShowCommentPopUp = (value: boolean) => {
    setShowCommentPopUp(value);
  };

  const handleShowMenu = (value: boolean) => {
    setShowMenu(value);
  };

  const handleSetEditor = (editor: Editor) => {
    setEditor(editor);
  };

  const handleScrollTo = (position: number) => {
    let timer = null;
    if (position >= currentSelection.from) {
      editor.commands.focus(position + 750, { scrollIntoView: true });
      timer = setTimeout(() => editor.commands.focus(position, { scrollIntoView: false }), 250);
    } else {
      editor.commands.focus(position - 200, { scrollIntoView: true });
      timer = setTimeout(() => editor.commands.focus(position, { scrollIntoView: false }), 250);
    }
    setShowMenu(false);
    return () => {
      if (timer) clearTimeout(timer);
    };
  };

  const handleSelectionChange = (selection: Selection) => {
    setCurrentSelection(selection);
  };

  const handleSetFocus = () => {
    editor.commands.focus(true, { scrollIntoView: false });
  };

  return (
    <ContractContext.Provider
      value={{
        comments,
        replies,
        editor,
        showMenu,
        showCommentPopUp,
        inputInfo,
        viewComments,
        activeComment,
        contractID,
        handleSetContractID,
        handleSetReply,
        handleSetComment,
        handleCancelComment,
        handleSetInputInfo,
        handleShowCommentPopUp,
        handleShowMenu,
        handleSetEditor,
        handleScrollTo,
        handleSetFocus,
        handleSelectionChange,
        handleSetViewComments,
        handleSetActiveComment,
        handleResolveComment,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}

export function useContract() {
  const context = useContext(ContractContext);
  return context;
}
