import { useState } from "react";
import * as SC from "./Styles";
import GPDrawerMSA from "../../components/GPDrawerMSA/GPDrawerMSA";
import Overlay from "../../components/Overlay/Overlay";
import GIADrawer from "../../components/GIADrawer/GIADrawer";
import { ChatMessage } from "../../components/EnumsAndTypes/EnumsAndTypes";
import { ADPScreens } from "../../components/EnumsAndTypes/EnumsAndTypes";
import ADPScreen from "../../components/ADPScreens/ADPScreens";
import { ADPProvider } from "../../contexts/ADPContext";
import { DrawerProvider } from "../../contexts/DrawerContext";
import { ProsProvider } from "../../contexts/ProfessionalsContext";

export function ADPWithMSA() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [adpScreen, setADPScreen] = useState(ADPScreens.MenuView);
  const [GIAmessages, setGIAMessages] = useState(null);
  const [showGIA, setShowGIA] = useState(false);
  const [connectLiveAgent, setConnectLiveAgent] = useState(false);

  const handleGIATrigger = (msgs: ChatMessage[]) => {
    setShowDrawer(true);
    setShowGIA(true);
    setGIAMessages(msgs);
  };

  return (
    <SC.ADPPage $scroll={!showDrawer}>
      <ADPProvider onChange={() => setShowDrawer(!showDrawer)} adpScreen={adpScreen}>
        <ADPScreen />
      </ADPProvider>
      <Overlay show={showDrawer} onClick={() => setShowDrawer(!showDrawer)} />
      <GIADrawer
        showDrawer={showDrawer}
        toggleGIA={() => setShowGIA(!showGIA)}
        showMessages={showGIA}
        triggerMessages={GIAmessages}
        onChangeMessages={(messages: ChatMessage[]) => setGIAMessages(messages)}
        messages={GIAmessages}
        connectLiveAgent={connectLiveAgent}
        onChangeLiveAgent={(connected: boolean) => setConnectLiveAgent(connected)}
      />
      <ProsProvider>
        <DrawerProvider
          onChangeADP={(screen: ADPScreens) => setADPScreen(screen)}
          onChangeGIA={(state: boolean) => setShowGIA(state)}
          onChangeDrawer={(state: boolean) => setShowDrawer(state)}
          connectLiveAgent={(connect: boolean) => setConnectLiveAgent(connect)}
          onTriggerChat={(messages: ChatMessage[]) => handleGIATrigger(messages)}
          showDrawer={showDrawer}
          showGIA={showGIA}
        >
          <GPDrawerMSA />
        </DrawerProvider>
      </ProsProvider>
    </SC.ADPPage>
  );
}

export default ADPWithMSA;
