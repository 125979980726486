import styled from "styled-components";

export const InfoButton = styled.span`
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
`;
