import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const ModalBox = styled(motion.div)`
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  padding: 24px;
  background-color: ${colors.grays200};
  border: 1px solid ${colors.grays300};
  border-radius: 8px;
  gap: 32px;
  z-index: 1000;
`;
