import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useRef, useState, useEffect } from "react";
import { transitions } from "../../assets/tokens/tokens";
import usePageStore from "../../callbacks/usePageStore";
import { Direction } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import DayAndTime from "./DayAndTime";
import SchedulingCall from "./SchedulingCall";
import CallConfirmed from "./CallConfirmed";
import ConfirmContacts from "./ConfirmContacts";

const dataDefault = {
  name: null,
  phone: null,
  email: null,
  accept: null,
};

export default function ScheduleCallView() {
  const { schedulePageIndex, handleDrawerAction, handlePageChange } = useNoMSADrawer();
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const [direction, setDirection] = useState(Direction.forward);
  const [page, setPage] = useState(schedulePageIndex);
  const [pageData, setPageData] = usePageStore(dataDefault);

  useEffect(() => {
    setDirection(schedulePageIndex >= page ? 1 : -1);
    setPage(schedulePageIndex);
  }, [schedulePageIndex]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  const setContent = () => {
    if (page === 0) return <DayAndTime />;
    if (page === 1) return <ConfirmContacts />;
    if (page === 2) return <SchedulingCall />;
    if (page === 3) return <CallConfirmed />;
    return <CallConfirmed />;
  };

  return (
    <SC.Wrapper ref={ref}>
      <AnimatePresence initial={false} custom={direction}>
        <SC.Content
          custom={direction}
          transition={{ ...transitions.easIn, duration: 0.85 }}
          variants={scrollVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"page_" + page}
        >
          {setContent()}
        </SC.Content>
      </AnimatePresence>
    </SC.Wrapper>
  );
}
