import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, type } from "../../assets/tokens/tokens";

export const Wrapper = styled(motion.div)<{ $width: number }>`
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: ${(p) => p.$width}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.graysWhite};
  border-left: 1px solid ${colors.grays100};
`;

//****
// Header styles
//****
export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 79px;
  padding: 0px 32px;
  width: 100%;
  border-bottom: 0px solid ${colors.grays100};
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ActionsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const HeaderTitle = styled.div`
  ${type.pSmall};
  margin-left: 8px;
`;

export const InfoGroupHead = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${colors.green500};
  border-radius: 8px;
  padding: 20px 24px;
  margin: 0px 16px 16px 16px;
  gap: 16px;
`;

export const Name = styled.div`
  ${type.pRegularBold};
  color: ${colors.graysTextPrimary};
  line-height: 1em;
`;

export const JobTitle = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextTertiary};
`;

//****
// Body styles
//****
export const Body = styled(motion.div)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0 16px 0;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  gap: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 13px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 20px;
    border: 3px solid white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
    border-radius: 20px;
    border: 3px solid white;
  }
`;

//****
// Footer styles
//****
export const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: auto;
  min-height: 79px;
  padding: 12px 12px 16px 12px;
  width: 100%;
  border-top: 0px solid ${colors.grays100};
  background-color: rgba(0, 0, 0, 0);
`;

export const PrevNext = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
`;

export const ProgressTrack = styled.div`
  width: 100%;
  height: 1.5px;
`;

export const ProgressBar = styled(motion.div)`
  background-color: ${colors.primaryGPBlue};
  height: 100%;
`;

export const Link = styled.span`
  color: ${colors.primaryGPBlue};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
