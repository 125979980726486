import * as Styled from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";
import { countries } from "./SurveyData";
import InputButtonList from "../InputButtonList/InputButtonList";
import { ButtonType } from "../InputButton/InputButton";
import { useState } from "react";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";

interface Props {
  data?: boolean[];
  onChange?: (data: boolean[]) => void;
}

export default function EnterCountry(props: Props) {
  const { data = null, onChange = () => null } = props;
  const [selectedValues, setSelectedValues] = useState(data);
  const handleChanges = (values: any) => {
    setSelectedValues(values);
    onChange(values);
  };
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>What markets or countries are you looking to expand into?</Styled.Title>
      <Styled.Description>
        The country you hire in determines employee options like available benefits as well as legal
        and compliance requirements.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <InputButtonList
        type={ButtonType.country}
        multiselect={true}
        options={countries}
        selected={selectedValues instanceof Array ? selectedValues : null}
        onChange={(values) => handleChanges(values)}
      />
      <Styled.LinkButton>
        More Countries
        <Icon name={IconNames.carretDown} size={16} theme={"blueButton"} stroke={2} />
      </Styled.LinkButton>
    </Styled.QuestionWrapper>
  );
}
