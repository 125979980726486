import { useMemo } from "react";

export enum Product {
  Prime = "prime",
  Contractor = "contractor",
  Recruit = "recruit",
  Core = "core",
  IQ = "iq",
  Marketplace = "marketplace",
  Advicor = "advisor",
}

interface Props {
  product?: Product;
  alt?: string;
  size?: number;
  onClick?: () => void;
}

export default function Icon(props: Props) {
  const { product = Product.Core, size = 64, onClick = () => null } = props;

  const renderIcon = useMemo(() => {
    const icons = [
      {
        name: "recruit",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54.286"
            height={size}
            viewBox="0 0 54.286 50"
          >
            <g transform="translate(0 -0.005)">
              <g>
                <defs>
                  <linearGradient
                    id="idz8yzC9C7Tg-2131213149"
                    x1="0.7762171780128686"
                    x2="0.22378282198713137"
                    y1="1"
                    y2="0"
                  >
                    <stop offset="0" stopColor="rgb(86,201,0)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(0,237,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 5.929 34.048 L 16.329 28.377 C 16.329 28.377 25.857 22.891 26.471 8.505 L 26.829 0.005 C 16.371 -0.223 6.657 6.72 3.871 17.334 C 2.443 23.191 3.271 29.077 5.929 34.034 Z"
                  fill="url(#idz8yzC9C7Tg-2131213149)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="idYzq2siVSEg2131213081"
                    x1="0"
                    x2="1"
                    y1="0.504357216405259"
                    y2="0.4956427835947411"
                  >
                    <stop offset="0" stopColor="rgb(86,201,0)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(0,237,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 26.371 0.005 L 26.4 11.863 C 26.4 11.863 26.657 22.863 38.7 30.263 L 45.8 34.634 C 50.871 25.548 49.443 13.705 41.643 6.191 C 37.271 2.12 31.857 0.048 26.371 0.005 Z"
                  fill="url(#idYzq2siVSEg2131213081)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="idI9no2lckZg-1412112422"
                    x1="0.49751243781094523"
                    x2="0.5024875621890548"
                    y1="0"
                    y2="1"
                  >
                    <stop offset="0" stopColor="rgb(86,201,0)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(0,0,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 46.043 34.077 L 35.3 27.677 C 35.3 27.677 25.743 22.305 13.043 29.291 L 5.714 33.305 C 6.857 35.375 8.291 37.271 9.971 38.934 C 19.186 47.805 33.843 47.548 42.714 38.334 C 44.071 37.048 45.186 35.605 46.043 34.077 Z"
                  fill="url(#idI9no2lckZg-1412112422)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="ideCaqyt9jOg-431765044"
                    x1="0.49751243781094523"
                    x2="0.5024875621890548"
                    y1="0"
                    y2="1"
                  >
                    <stop offset="0" stopColor="rgb(156,248,32)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(64,132,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 46.3 18.548 C 50.442 18.548 53.8 15.19 53.8 11.048 C 53.8 6.906 50.442 3.548 46.3 3.548 C 42.158 3.548 38.8 6.906 38.8 11.048 C 38.8 15.19 42.158 18.548 46.3 18.548 Z"
                  fill="url(#ideCaqyt9jOg-431765044)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="idfZcv0et_Og2131213300"
                    x1="0.49751243781094523"
                    x2="0.5024875621890548"
                    y1="0"
                    y2="1"
                  >
                    <stop offset="0" stopColor="rgb(0,237,255)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(86,201,0)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 7.5 17.963 C 11.642 17.963 15 14.605 15 10.463 C 15 6.32 11.642 2.963 7.5 2.963 C 3.357 2.963 0 6.305 0 10.448 C 0 14.591 3.357 17.948 7.5 17.948 Z"
                  fill="url(#idfZcv0et_Og2131213300)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="idAwyHD6JITg864905720"
                    x1="0.5310744989675518"
                    x2="0.4689255010324483"
                    y1="0"
                    y2="1"
                  >
                    <stop offset="0" stopColor="rgb(195,255,115)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(64,132,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 25.5 49.834 C 29.629 50.12 33.214 46.991 33.5 42.848 C 33.786 38.705 30.657 35.134 26.514 34.848 C 22.371 34.563 18.8 37.691 18.529 41.834 C 18.257 45.977 21.371 49.548 25.514 49.82 Z"
                  fill="url(#idAwyHD6JITg864905720)"
                ></path>
              </g>
            </g>
          </svg>
        ),
      },
      {
        name: "advisor",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 60.204 50">
            <path
              d="M 34.837 1.748 C 36.12 2.877 37.326 4.326 38.429 6.074 C 39.736 8.145 40.834 10.538 41.699 13.151 C 43.395 13.587 45.051 14.17 46.646 14.893 C 48.907 15.932 50.757 17.179 52.153 18.587 C 49.832 10.24 43.137 3.818 34.844 1.748 L 34.838 1.748 Z"
              fill="rgb(0,173,238)"
            ></path>
            <g>
              <defs>
                <linearGradient
                  id="idOMINqgfFrg-358945987"
                  x1="0.023039107212549956"
                  x2="0.97696089278745"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,234,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,70,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 15.984 35.688 C 16.891 38.779 18.119 41.594 19.63 43.987 C 20.734 45.736 21.939 47.185 23.228 48.313 C 18.849 47.213 14.869 44.903 11.741 41.648 C 11.855 39.495 13.436 37.4 15.99 35.682 L 15.984 35.687 Z M 40.841 42.586 C 39.976 44.458 38.967 46.057 37.846 47.346 C 42.041 45.681 45.682 42.869 48.353 39.232 C 47.959 37.107 46.115 35.246 43.359 33.88 C 42.869 37.065 42.016 40.019 40.841 42.585 Z M 45.698 16.928 C 44.639 16.442 43.549 16.028 42.435 15.687 C 43.132 18.428 43.574 21.229 43.753 24.052 C 43.92 26.531 43.886 29.019 43.651 31.493 C 46.599 32.817 48.801 34.661 49.893 36.85 C 51.935 33.267 53.01 29.215 53.013 25.091 L 52.99 25.091 C 52.781 21.898 50.185 18.999 45.686 16.928 Z M 15.376 33.36 C 14.818 30.939 14.456 28.476 14.295 25.997 C 14.104 23.175 14.172 20.341 14.498 17.531 C 13.442 18.018 12.417 18.572 11.431 19.189 C 7.243 21.832 5.053 25.049 5.267 28.241 L 5.25 28.241 C 5.797 32.329 7.396 36.204 9.892 39.488 C 10.685 37.172 12.624 35.06 15.376 33.355 Z M 41.222 32.979 C 37.941 31.82 33.824 31.26 29.457 31.546 C 25.09 31.833 21.08 32.937 17.977 34.517 C 18.836 37.62 20.035 40.437 21.52 42.788 C 24.176 46.988 27.404 49.184 30.608 48.976 C 33.812 48.766 36.724 46.159 38.8 41.649 C 39.963 39.118 40.782 36.17 41.222 32.979 Z"
                fill="url(#idOMINqgfFrg-358945987)"
              ></path>
            </g>
            <g transform="translate(5.26 2.709)">
              <g>
                <defs>
                  <linearGradient
                    id="idtQFwobiR1g228647661"
                    x1="0"
                    x2="1"
                    y1="0.9700919496020758"
                    y2="0.02990805039792422"
                  >
                    <stop offset="0" stopColor="rgb(0,255,255)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(0,176,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 4.978 14.589 C 6.461 13.662 8.022 12.868 9.644 12.213 C 10.156 9.51 10.933 6.993 11.953 4.761 C 12.818 2.882 13.827 1.289 14.943 0 C 6.993 3.144 1.205 10.393 0 18.974 C 1.2 17.399 2.87 15.919 4.97 14.589 L 4.977 14.589 Z"
                  fill="url(#idtQFwobiR1g228647661)"
                ></path>
              </g>
              <g>
                <defs>
                  <linearGradient
                    id="idRyq9SXgEUg228647661"
                    x1="0"
                    x2="1"
                    y1="0.9701218034266602"
                    y2="0.029878196573339552"
                  >
                    <stop offset="0" stopColor="rgb(0,255,255)" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="rgb(0,176,255)" stopOpacity="1"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 23.051 11.521 C 18.958 11.79 15.061 12.589 11.643 13.842 C 11.195 16.771 11.064 19.922 11.279 23.144 C 11.421 25.346 11.727 27.488 12.162 29.535 C 15.432 27.959 19.524 26.892 24.048 26.593 C 28.57 26.295 32.771 26.807 36.219 27.942 C 36.387 25.853 36.399 23.687 36.255 21.486 C 36.041 18.263 35.498 15.161 34.668 12.314 C 31.112 11.521 27.144 11.241 23.051 11.515 Z"
                  fill="url(#idRyq9SXgEUg228647661)"
                ></path>
              </g>
            </g>
            <path
              d="M 36.526 7.267 C 33.87 3.067 30.648 0.871 27.439 1.086 C 24.235 1.295 21.322 3.902 19.246 8.413 C 18.465 10.114 17.838 11.999 17.379 14.015 C 20.685 12.936 24.348 12.244 28.154 11.988 C 31.967 11.737 35.685 11.94 39.103 12.579 C 38.382 10.64 37.516 8.849 36.514 7.267 Z"
              fill="rgb(0,173,238)"
            ></path>
            <g>
              <defs>
                <linearGradient
                  id="iddKf3wTNtJg-1215451024"
                  x1="0"
                  x2="1"
                  y1="0.9701372996697909"
                  y2="0.029862700330208897"
                >
                  <stop offset="0" stopColor="rgb(0,236,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(220,255,97)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 28.168 11.988 C 24.355 12.238 20.698 12.937 17.393 14.016 C 17.852 11.999 18.478 10.113 19.26 8.413 C 21.336 3.903 24.248 1.301 27.452 1.087 C 30.656 0.871 33.884 3.073 36.54 7.267 C 37.536 8.849 38.407 10.639 39.129 12.578 C 35.71 11.945 31.986 11.737 28.18 11.988 Z M 38.431 6.068 C 39.737 8.139 40.836 10.532 41.701 13.145 C 43.397 13.581 45.052 14.164 46.647 14.887 C 48.908 15.926 50.758 17.172 52.154 18.581 C 49.834 10.234 43.139 3.813 34.845 1.743 C 36.128 2.87 37.333 4.32 38.437 6.068 Z M 39.929 15.031 C 36.372 14.237 32.404 13.956 28.311 14.231 C 24.254 14.499 20.399 15.287 17.005 16.522 C 20.315 18.867 24.372 21.021 28.913 22.721 C 33.317 24.374 37.655 25.4 41.593 25.831 C 41.576 25.289 41.55 24.748 41.515 24.207 C 41.301 20.985 40.758 17.883 39.929 15.037 Z M 10.224 10.138 C 11.412 11.743 12.994 13.359 14.903 14.923 C 15.41 12.219 16.186 9.702 17.212 7.47 C 18.078 5.591 19.086 3.998 20.202 2.709 C 16.28 4.267 12.837 6.829 10.219 10.138 Z"
                fill="url(#iddKf3wTNtJg-1215451024)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idl7cRtRFzCg1395405203"
                  x1="0.0230290063045671"
                  x2="0.9769709936954328"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(93,232,65)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(1,249,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 45.699 16.934 C 44.639 16.448 43.548 16.034 42.434 15.693 C 43.131 18.434 43.573 21.235 43.753 24.058 C 43.795 24.714 43.824 25.364 43.843 26.015 C 47.387 26.206 50.519 25.872 52.99 25.036 C 52.751 21.868 50.161 18.993 45.699 16.934 Z"
                fill="url(#idl7cRtRFzCg1395405203)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idaZMWsPiOHg-1215451022"
                  x1="0.005131756455129278"
                  x2="0.9948682435448707"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(0,236,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(220,255,97)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 48.269 23.843 C 54.854 23.843 60.191 18.506 60.191 11.921 C 60.191 5.338 54.854 0 48.269 0 C 41.686 0 36.348 5.337 36.348 11.921 C 36.348 18.506 41.685 23.843 48.269 23.843 Z"
                fill="url(#idaZMWsPiOHg-1215451022)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idBbiYed2Wzg228647582"
                  x1="0"
                  x2="1"
                  y1="0.4685592910798417"
                  y2="0.5314407089201583"
                >
                  <stop offset="0" stopColor="rgb(0,255,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,176,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 11.921 48.594 C 18.506 48.594 23.843 43.256 23.843 36.671 C 23.843 30.088 18.506 24.75 11.921 24.75 C 5.338 24.75 0 30.088 0 36.671 C 0 43.256 5.337 48.594 11.921 48.594 Z"
                fill="url(#idBbiYed2Wzg228647582)"
              ></path>
            </g>
          </svg>
        ),
      },
      {
        name: "prime",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 50 50">
            <g>
              <defs>
                <linearGradient
                  id="iddJlE7RKpDg-1462937319"
                  x1="0.6167316349776772"
                  x2="0.3832683650223227"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(124,1,216)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgba(255,0,178,0.3)" stopOpacity="0.3"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 49.262 19.069 C 46.522 7.683 36.306 0.05 25.062 0.099 L 25.062 49.605 C 27.042 49.612 29.016 49.382 30.942 48.92 C 44.259 45.727 52.462 32.362 49.262 19.069 Z"
                fill="url(#iddJlE7RKpDg-1462937319)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idm7d1ygNsQg153701254"
                  x1="0.49748743718592964"
                  x2="0.5025125628140703"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(255,0,178)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgba(126,1,216,0.3)" stopOpacity="0.3"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 0.91 30.634 C 3.637 41.961 13.762 49.574 24.937 49.604 L 24.937 0.1 C 23.015 0.107 21.1 0.336 19.231 0.783 C 5.913 3.975 -2.29 17.34 0.91 30.634 Z"
                fill="url(#idm7d1ygNsQg153701254)"
              ></path>
            </g>
            <g opacity="0.85">
              <defs>
                <linearGradient
                  id="idKbeyU1Ujmg-1699201744"
                  x1="0"
                  x2="1"
                  y1="0.4975124378109453"
                  y2="0.5024875621890547"
                >
                  <stop offset="0" stopColor="rgb(255,6,207)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(154,0,194)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 25.023 49.549 C 36.27 49.549 45.386 38.492 45.386 24.851 C 45.386 11.211 36.269 0.154 25.023 0.154 C 13.777 0.154 4.66 11.21 4.66 24.85 C 4.66 38.49 13.777 49.548 25.023 49.548 Z"
                fill="url(#idKbeyU1Ujmg-1699201744)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idRK4Thj9Tbg-1137194860"
                  x1="0"
                  x2="1"
                  y1="0.4975124378109453"
                  y2="0.5024875621890547"
                >
                  <stop offset="0" stopColor="rgb(255,66,214)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(128,0,166)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 24.924 0.236 L 23.327 0.234 C 12.872 1.267 4.64 11.872 4.617 24.824 C 4.596 37.776 12.794 48.404 23.247 49.467 L 24.843 49.469 Z"
                fill="url(#idRK4Thj9Tbg-1137194860)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idwbALQt9Q_g1934963264"
                  x1="0"
                  x2="1"
                  y1="0.4975124378109453"
                  y2="0.5024875621890547"
                >
                  <stop offset="0" stopColor="rgb(138,8,229)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,25,197)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 25.016 49.54 L 26.021 49.54 C 32.546 48.49 37.674 37.853 37.674 24.877 C 37.674 11.901 32.546 1.264 26.022 0.215 L 25.016 0.215 Z"
                fill="url(#idwbALQt9Q_g1934963264)"
              ></path>
            </g>
            <g opacity="0.77">
              <defs>
                <linearGradient
                  id="idEPeMPrZiig1934963476"
                  x1="1"
                  x2="0"
                  y1="0.4974874371859295"
                  y2="0.5025125628140704"
                >
                  <stop offset="0" stopColor="rgb(138,8,229)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,25,197)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 25.033 49.487 L 24.028 49.487 C 17.503 48.437 12.375 37.801 12.375 24.825 C 12.375 11.85 17.503 1.213 24.028 0.163 L 25.033 0.163 Z"
                fill="url(#idEPeMPrZiig1934963476)"
              ></path>
            </g>
          </svg>
        ),
      },
      {
        name: "iq",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 61 50">
            <g>
              <defs>
                <linearGradient
                  id="idtjSq8I71Ug798607829"
                  x1="1"
                  x2="0"
                  y1="0.20791441017881465"
                  y2="0.7920855898211854"
                >
                  <stop offset="0" stopColor="rgba(215,202,255,0.8)" stopOpacity="0.8"></stop>
                  <stop offset="1" stopColor="rgb(253,64,199)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 25.682 49.864 C 39.452 49.864 50.614 38.702 50.614 24.932 C 50.614 11.162 39.452 0 25.682 0 C 11.912 0 0.75 11.162 0.75 24.932 C 0.75 38.702 11.912 49.864 25.682 49.864 Z"
                fill="url(#idtjSq8I71Ug798607829)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idOKgMMQP1Wg-28252546"
                  x1="0.4863458816525169"
                  x2="0.5136541183474831"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(204,181,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(212,52,220)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 50.444 24.726 C 50.025 20.604 39.041 17.288 25.548 17.264 C 11.776 17.24 0.604 20.652 0.598 24.89 C 0.598 24.944 0.61 25.005 0.61 25.06 L 0.598 25.06 C 0.664 38.752 11.769 49.845 25.48 49.87 C 39.253 49.894 50.43 38.753 50.455 24.987 L 50.455 24.738 L 50.442 24.738 L 50.442 24.726 Z"
                fill="url(#idOKgMMQP1Wg-28252546)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idkDv37AUX_g-427297101"
                  x1="0"
                  x2="1"
                  y1="0.4864101580564885"
                  y2="0.5135898419435114"
                >
                  <stop offset="0" stopColor="rgb(218,210,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(240,96,215)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 50.49 24.682 C 50.498 20.447 39.342 16.994 25.572 16.97 C 11.802 16.946 0.634 20.36 0.627 24.595 C 0.619 28.83 11.776 32.282 25.545 32.306 C 39.315 32.33 50.483 28.916 50.491 24.682 Z"
                fill="url(#idkDv37AUX_g-427297101)"
              ></path>
            </g>
            <g opacity="0.73">
              <defs>
                <linearGradient
                  id="idYXNHixMlMg1459302417"
                  x1="0.5085858629134957"
                  x2="0.4914141370865042"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(181,149,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(253,64,199)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 15.559 2.122 C 6.849 5.995 0.769 14.712 0.75 24.859 C 0.732 35.007 6.781 43.748 15.48 47.651 L 15.559 2.121 Z"
                fill="url(#idYXNHixMlMg1459302417)"
              ></path>
            </g>
            <g opacity="0.57">
              <defs>
                <linearGradient
                  id="idZqA6Gb1yTg1459302423"
                  x1="0.4944418661423757"
                  x2="0.5055581338576243"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(181,149,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(253,64,199)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 35.883 2.158 L 35.805 47.688 C 44.515 43.815 50.595 35.098 50.614 24.95 C 50.632 14.803 44.582 6.062 35.884 2.158 Z"
                fill="url(#idZqA6Gb1yTg1459302423)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idpRWLZrm5pg1402384303"
                  x1="0.7123574778116349"
                  x2="0.28764252218836517"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(204,181,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(253,64,199)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 59.836 31.564 L 56.145 25.648 C 58.409 23.177 59.764 20.008 59.988 16.664 C 60.546 8.493 54.368 1.413 46.198 0.861 C 38.026 0.303 30.946 6.481 30.394 14.651 C 29.837 22.823 36.014 29.897 44.184 30.455 C 45.894 30.574 47.611 30.394 49.259 29.921 L 52.956 35.85 L 59.836 31.558 Z"
                fill="url(#idpRWLZrm5pg1402384303)"
              ></path>
            </g>
            <path
              d="M 50.07 4.65 C 43.948 2 36.807 4.83 34.158 10.954 C 31.508 17.076 34.34 24.217 40.462 26.866 C 43.675 28.254 47.154 28.126 50.131 26.817 L 53.307 32.207 L 56.241 30.479 L 53.028 25.023 C 54.478 23.817 55.624 22.287 56.374 20.556 C 59.024 14.433 56.192 7.292 50.07 4.643 Z M 54.253 19.64 C 52.113 24.593 46.336 26.878 41.383 24.738 C 36.431 22.598 34.146 16.822 36.285 11.869 C 38.425 6.917 44.202 4.631 49.155 6.771 C 54.107 8.911 56.398 14.688 54.253 19.641 Z"
              fill="rgb(255,255,255)"
            ></path>
          </svg>
        ),
      },
      {
        name: "marketplace",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 49.153 50">
            <g>
              <defs>
                <linearGradient
                  id="idFHG0uewbzg1605801496"
                  x1="0.9929785811171902"
                  x2="0.007021418882809814"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,146,46)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 6.981 25.347 C 6.981 20.636 8.781 15.924 12.375 12.325 C 15.968 8.731 20.68 6.932 25.397 6.932 C 22.241 6.932 19.544 11.655 18.414 18.364 C 11.711 19.495 6.982 22.192 6.982 25.347 Z"
                fill="url(#idFHG0uewbzg1605801496)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idT_Rap0KUIg1186290220"
                  x1="0.49762692949247983"
                  x2="0.5023730705075202"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,103,15)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 32.374 32.331 C 39.078 31.2 43.808 28.504 43.808 25.347 C 43.808 30.059 42.008 34.771 38.414 38.364 C 34.966 41.822 30.281 43.763 25.397 43.758 C 28.553 43.758 31.25 39.035 32.381 32.325"
                fill="url(#idT_Rap0KUIg1186290220)"
              ></path>
            </g>
            <path
              d="M 25.397 6.932 C 30.109 6.932 34.821 8.731 38.42 12.325 C 42.014 15.918 43.813 20.63 43.813 25.347 C 43.813 22.192 39.09 19.495 32.381 18.364 C 31.25 11.661 28.553 6.932 25.397 6.932 Z"
              fill="rgb(255,218,0)"
            ></path>
            <path
              d="M 18.413 32.331 C 19.542 39.034 22.238 43.763 25.395 43.763 C 20.683 43.763 15.971 41.964 12.378 38.37 C 8.92 34.922 6.979 30.237 6.985 25.353 C 6.985 28.504 11.714 31.206 18.418 32.331 Z"
              fill="rgb(255,127,0)"
            ></path>
            <g>
              <defs>
                <linearGradient
                  id="idHqgpa8cPXg46671568"
                  x1="0.992999566224265"
                  x2="0.007000433775735235"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,255,8)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,160,47)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 6.993 25.336 C 6.993 22.197 11.597 19.488 18.279 18.37 C 18.279 18.314 18.358 18.365 18.358 18.37 C 20.652 17.985 22.974 17.789 25.301 17.786 C 26.845 17.786 28.343 17.865 29.774 18.008 C 30.155 18.047 30.495 18.075 30.864 18.126 C 30.955 18.138 31.102 18.172 31.193 18.183 C 31.54 18.229 31.852 18.263 32.186 18.319 C 32.198 18.319 32.288 18.336 32.392 18.353 C 32.419 18.353 32.447 18.365 32.47 18.37 C 32.555 18.387 32.624 18.399 32.636 18.399 C 39.209 19.552 43.819 22.219 43.819 25.336 C 43.819 28.419 39.3 31.064 32.828 32.233 C 32.652 32.282 32.471 32.314 32.289 32.33 C 30.143 32.693 27.776 32.875 25.312 32.875 C 22.848 32.875 20.504 32.676 18.358 32.313 C 18.358 32.302 18.29 32.302 18.284 32.29 C 11.614 31.166 6.981 28.469 6.981 25.331 Z"
                fill="url(#idHqgpa8cPXg46671568)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idI4PtIhEJ7g1368492665"
                  x1="0.49748743718592964"
                  x2="0.5025125628140703"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,159,37)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 25.395 6.932 C 28.534 6.932 31.242 11.536 32.361 18.217 C 32.417 18.217 32.366 18.297 32.361 18.297 C 32.718 20.437 32.946 22.781 32.946 25.24 C 32.946 26.784 32.866 28.282 32.724 29.713 C 32.684 30.093 32.656 30.433 32.605 30.803 C 32.593 30.894 32.559 31.042 32.548 31.132 C 32.503 31.478 32.469 31.791 32.412 32.125 C 32.412 32.137 32.395 32.227 32.378 32.33 C 32.378 32.358 32.366 32.386 32.361 32.409 C 32.344 32.494 32.332 32.562 32.332 32.574 C 31.18 39.147 28.512 43.758 25.395 43.758 C 22.313 43.758 19.667 39.238 18.497 32.767 C 18.449 32.591 18.416 32.41 18.401 32.228 C 18.038 30.082 17.856 27.714 17.856 25.251 C 17.856 22.787 18.055 20.442 18.418 18.297 C 18.43 18.297 18.43 18.229 18.441 18.223 C 19.565 11.553 22.261 6.92 25.401 6.92 L 25.395 6.931 Z"
                fill="url(#idI4PtIhEJ7g1368492665)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idwp4gl6TSxg1605801685"
                  x1="0"
                  x2="1"
                  y1="0.9929785811171906"
                  y2="0.007021418882809617"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,146,46)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 32.375 18.376 C 32.727 20.494 32.948 22.81 32.948 25.234 C 32.948 26.778 32.869 28.276 32.727 29.708 C 32.687 30.088 32.659 30.428 32.608 30.797 C 32.597 30.888 32.562 31.036 32.551 31.126 C 32.506 31.473 32.471 31.785 32.415 32.12 C 32.415 32.131 32.398 32.222 32.381 32.324 C 32.381 32.353 32.369 32.381 32.364 32.404 C 32.347 32.489 32.336 32.557 32.336 32.569 C 31.183 39.142 28.514 43.752 25.398 43.752 C 22.315 43.752 19.669 39.233 18.5 32.762 C 18.462 32.618 18.434 32.472 18.415 32.325 L 32.381 18.359 L 32.375 18.376 Z"
                fill="url(#idwp4gl6TSxg1605801685)"
              ></path>
            </g>
            <g opacity="0.59">
              <defs>
                <linearGradient
                  id="idxIJOMrY3xg46671467"
                  x1="0"
                  x2="1"
                  y1="0.4975124378109453"
                  y2="0.5024875621890547"
                >
                  <stop offset="0" stopColor="rgb(255,255,8)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,160,47)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 18.355 18.37 C 20.649 17.985 22.971 17.789 25.297 17.786 C 26.842 17.786 28.34 17.865 29.771 18.008 C 30.151 18.047 30.492 18.075 30.861 18.126 C 30.952 18.138 31.099 18.172 31.19 18.183 C 31.536 18.229 31.848 18.263 32.183 18.319 C 32.189 18.319 32.268 18.336 32.353 18.348 L 18.383 32.319 L 18.354 32.319 C 18.354 32.307 18.286 32.307 18.281 32.296 C 11.611 31.172 6.978 28.475 6.978 25.336 C 6.978 22.197 11.582 19.488 18.264 18.37 C 18.264 18.314 18.343 18.365 18.343 18.37 Z"
                fill="url(#idxIJOMrY3xg46671467)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idEx1fA1TVkg1387612838"
                  x1="0.49751243781094523"
                  x2="0.5024875621890548"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,174,48)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 38.636 6.784 C 38.635 8.056 39.14 9.276 40.04 10.176 C 40.94 11.076 42.16 11.581 43.432 11.581 C 44.704 11.581 45.925 11.076 46.824 10.176 C 47.724 9.276 48.229 8.056 48.229 6.784 C 48.229 5.512 47.724 4.291 46.824 3.392 C 45.925 2.492 44.704 1.987 43.432 1.987 C 42.16 1.987 40.94 2.492 40.04 3.392 C 39.14 4.291 38.635 5.512 38.636 6.784 Z"
                fill="url(#idEx1fA1TVkg1387612838)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idn6P_iKISag1605801523"
                  x1="0.6987465333170354"
                  x2="0.3012534666829645"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,146,46)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 10.59 10.175 C 12.463 8.302 12.463 5.264 10.589 3.391 C 8.716 1.518 5.678 1.518 3.805 3.392 C 1.932 5.265 1.932 8.302 3.805 10.175 C 5.678 12.049 8.716 12.049 10.589 10.175 Z"
                fill="url(#idn6P_iKISag1605801523)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idRKnmFLTPjg1186290328"
                  x1="0.49748743718592964"
                  x2="0.5025125628140703"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,103,15)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 7.197 48.805 C 9.846 48.805 11.994 46.657 11.994 44.008 C 11.994 41.358 9.846 39.21 7.197 39.21 C 4.547 39.21 2.4 41.358 2.4 44.007 C 2.4 46.657 4.547 48.804 7.197 48.804 Z"
                fill="url(#idRKnmFLTPjg1186290328)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idCtUMn2aj7g1605801549"
                  x1="0.49751243781094523"
                  x2="0.5024875621890548"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(255,238,53)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(255,146,46)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 44.199 48.742 C 46.814 48.318 48.59 45.854 48.166 43.239 C 47.741 40.623 45.277 38.848 42.662 39.272 C 40.047 39.697 38.271 42.161 38.695 44.776 C 39.12 47.391 41.584 49.167 44.199 48.742 Z"
                fill="url(#idCtUMn2aj7g1605801549)"
              ></path>
            </g>
          </svg>
        ),
      },
      {
        name: "contractor",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 53.061 50">
            <g>
              <defs>
                <linearGradient
                  id="idhSU7iok6Ng1816918246"
                  x1="0.49748743718592964"
                  x2="0.5025125628140703"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(1,237,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(206,255,251)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 7.826 42.282 C 2.857 37.466 0.374 31.106 0.374 24.747 L 24.619 24.747 L 24.619 49.117 C 18.355 49.062 12.348 46.617 7.826 42.281 Z"
                fill="url(#idhSU7iok6Ng1816918246)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idiEmSRokgfg29011396"
                  x1="0.49748743718592964"
                  x2="0.5025125628140703"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(0,234,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,107,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 13.561 17.732 C 18.429 17.732 22.372 13.766 22.372 8.874 C 22.372 3.984 18.428 0.017 13.562 0.017 C 8.696 0.017 4.751 3.984 4.751 8.874 C 4.751 13.766 8.696 17.732 13.562 17.732 Z"
                fill="url(#idiEmSRokgfg29011396)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idGHsrqUe7Hg680671516"
                  x1="0.49751243781094523"
                  x2="0.5024875621890548"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,236,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,88,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 45.494 41.628 C 40.763 46.561 34.436 49.058 28.109 49.117 L 28.109 24.747 L 52.353 24.747 C 52.294 30.809 50.047 36.872 45.494 41.628 Z"
                fill="url(#idGHsrqUe7Hg680671516)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idgei3tvY3bg680671516"
                  x1="0.49751243781094523"
                  x2="0.5024875621890548"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,236,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,88,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 40.053 17.732 C 44.918 17.732 48.863 13.766 48.863 8.874 C 48.863 3.984 44.918 0.017 40.053 0.017 C 35.187 0.017 31.242 3.984 31.242 8.874 C 31.242 13.766 35.187 17.732 40.053 17.732 Z"
                fill="url(#idgei3tvY3bg680671516)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idrPExrIEtvg-508393881"
                  x1="0.015400704290180109"
                  x2="0.98459929570982"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,234,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,28,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 4.396 34.851 C 4.041 35.327 3.628 35.743 3.272 36.218 C 4.468 38.462 6.004 40.507 7.826 42.282 C 12.497 46.858 18.588 49.117 24.618 49.117 L 24.618 24.747 C 16.771 25.178 9.452 28.835 4.396 34.851 Z"
                fill="url(#idrPExrIEtvg-508393881)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="iduCJnReDkNg-508393881"
                  x1="0.015400734672934546"
                  x2="0.9845992653270655"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,234,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,28,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 45.02 31.582 C 40.054 27.361 34.141 25.102 28.109 24.747 L 28.109 49.117 C 34.436 49.058 40.762 46.561 45.494 41.628 C 47.09 39.963 48.332 38.18 49.396 36.278 C 48.155 34.554 46.677 33.009 45.02 31.582 Z"
                fill="url(#iduCJnReDkNg-508393881)"
              ></path>
            </g>
          </svg>
        ),
      },
      {
        name: "core",
        svg: (
          <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 56 55">
            <g>
              <defs>
                <linearGradient
                  id="idX689Pz0qhg1955535094"
                  x1="0.8078257932289742"
                  x2="0.1921742067710258"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(220,255,97)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,141,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 31.245 0.965 C 31.061 0.922 30.878 0.89 30.695 0.85 L 29.262 6.923 L 29.269 6.89 C 42.132 9.769 50.281 22.48 47.488 35.371 C 47.364 35.934 47.219 36.492 47.055 37.045 L 54.235 38.735 C 54.272 38.586 54.316 38.439 54.352 38.289 C 58.288 21.607 47.942 4.897 31.245 0.965 Z"
                fill="url(#idX689Pz0qhg1955535094)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idLMbGzwfGfg-95382335"
                  x1="0.3887601662446207"
                  x2="0.6112398337553793"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(0,255,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(206,255,251)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 24.124 54.307 C 37.386 54.307 48.138 43.566 48.138 30.316 C 48.138 17.066 37.386 6.326 24.124 6.326 C 10.86 6.325 0.109 17.065 0.109 30.315 C 0.109 43.565 10.861 54.305 24.124 54.305 Z"
                fill="url(#idLMbGzwfGfg-95382335)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idHtJSZYh6Ag680671548"
                  x1="0.18992930804570743"
                  x2="0.8100706919542926"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stopColor="rgb(0,236,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,88,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 47.518 34.98 C 47.954 31.019 38.285 25.68 25.578 22.932 C 12.616 20.129 1.408 21.086 0.545 25.069 C 0.533 25.122 0.532 25.177 0.523 25.229 L 0.515 25.228 C -2.19 38.123 6.027 50.8 18.936 53.59 C 31.9 56.394 44.681 48.17 47.486 35.22 L 47.486 35.218 C 47.503 35.139 47.516 35.061 47.532 34.983 L 47.518 34.981 Z"
                fill="url(#idHtJSZYh6Ag680671548)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idlka7oIdHkg-928037319"
                  x1="0.3887747861429206"
                  x2="0.6112252138570794"
                  y1="1"
                  y2="0"
                >
                  <stop offset="0" stopColor="rgb(81,200,255)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(65,129,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 47.596 35.391 C 48.459 31.408 38.65 25.907 25.688 23.104 C 12.725 20.302 1.518 21.26 0.654 25.242 C -0.209 29.225 9.6 34.726 22.563 37.529 C 35.525 40.332 46.733 39.375 47.596 35.391 Z"
                fill="url(#idlka7oIdHkg-928037319)"
              ></path>
            </g>
            <g>
              <defs>
                <linearGradient
                  id="idKmgWEwPf6g-1955534855"
                  x1="1"
                  x2="0"
                  y1="0.18566281905135318"
                  y2="0.8143371809486467"
                >
                  <stop offset="0" stopColor="rgb(220,255,97)" stopOpacity="1"></stop>
                  <stop offset="1" stopColor="rgb(0,141,255)" stopOpacity="1"></stop>
                </linearGradient>
              </defs>
              <path
                d="M 29.375 6.911 L 23.57 31.511 L 47.16 37.066 C 47.32 36.516 47.47 35.96 47.593 35.392 C 50.386 22.5 42.237 9.79 29.374 6.912 Z"
                fill="url(#idKmgWEwPf6g-1955534855)"
              ></path>
            </g>
          </svg>
        ),
      },
    ];

    if (!product) return null;
    let item = icons.filter((data) => product.toString() === data.name);
    return item ? item[0].svg : null;
  }, [size, product]);

  const wrapperStyle = {
    display: "inline",
    verticalAlign: "middle",
    width: size,
    height: size,
  };

  const renderImage = () => {
    return (
      <div style={wrapperStyle} onClick={() => onClick()}>
        {renderIcon}
      </div>
    );
  };

  return product ? renderImage() : null;
}
