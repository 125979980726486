import * as SC from "./Styles";
import { useAnimationControls } from "framer-motion";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import { NoMSAView } from "../EnumsAndTypes/EnumsAndTypes";
import { useEffect } from "react";

export default function DrawerProgres() {
  const { view, pageStates } = useNoMSADrawer();
  const animate = useAnimationControls();
  const current = () => {
    if (view === NoMSAView.CallbBack) return "callback";
    if (view === NoMSAView.Schedule) return "schedule";
    if (view === NoMSAView.Quote) return "quote";
    return "quote";
  };
  const pageIndex = pageStates[current()].pageIndex;
  const pageCount = pageStates[current()].pageCount;
  const show =
    view === NoMSAView.CallbBack || view === NoMSAView.Quote || view == NoMSAView.Schedule;

  useEffect(() => {
    if (!show) return;
    animate.start({
      width: width(),
      opacity: 1,
      transition: { ease: "easeInOut", duration: 0.5 },
    });
  }, [pageIndex, pageCount]);

  const width = () => {
    if (view === NoMSAView.Quote) {
      if (pageIndex === pageCount) return 0;
      return `${Math.ceil((pageIndex / (pageCount - 1)) * 100)}%`;
    }
  };

  // place nothing is GP not loaded
  if (!show) return null;
  return (
    <SC.ProgressTrack>
      <SC.ProgressBar initial={{ width: 0 }} animate={animate} />
    </SC.ProgressTrack>
  );
}
