import { useState } from "react";
import * as Styled from "./Styles";

interface ButtonProps {
  label?: string;
}
export default function TimeSlotButton(props: ButtonProps) {
  const { label = "label" } = props;
  const [buttonState, setButtonState] = useState(false);

  return (
    <Styled.TimeSlotButton
      initial={buttonState}
      variants={Styled.variants}
      animate={buttonState ? "on" : "off"}
      onClick={() => setButtonState(!buttonState)}
      whileHover={Styled.variants.hovered}
      whileTap={Styled.variants.pressed}
    >
      {label}
    </Styled.TimeSlotButton>
  );
}
