import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const parentvariants = {
  blurred: { borderColor: colors.grays400 },
  focused: { borderColor: colors.primaryGPBlue },
  disabled: { borderColor: colors.grays200 },
  invalid: { borderColor: colors.error },
};

export const childvariants = {
  blurred: { color: colors.graysTextPrimary },
  focused: { color: colors.graysTextPrimary },
  disabled: { color: colors.graysTextDisabled },
  invalid: { color: colors.graysTextPrimary },
};

export const ErrorIcon = styled.div<{ $show: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const setValue = (value: any) => {
  if (isNaN(+value)) return value;
  return value + "px";
};

export const Wrapper = styled(motion.div)<{
  $size: { height?: number | string; width?: number | string };
}>`
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.grays400};
  width: ${(p) => (p.$size?.width ? setValue(p.$size.width) : "100%")};
  height: ${(p) => (p.$size?.height ? setValue(p.$size.height) + "px" : "100%")};
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

export const TextArea = styled(motion.textarea)`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  box-sizing: border-box;
  padding: 8px 16px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  color: black;
  background-color: ${colors.graysWhite};
  resize: none;
  &::-webkit-input-placeholder {
    color: ${colors.graysTextDisabled};
  }
`;
