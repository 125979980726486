import * as SC from "./Styles";
import InputMessage from "../InputMessage/InputMessage";
import { useGIA } from "../../contexts/GIAContext";
import { ChatMessage } from "../EnumsAndTypes/EnumsAndTypes";

export default function GIAFooter() {
  const { handleSendMessage } = useGIA();

  return (
    <SC.Footer>
      <InputMessage onSendMessage={(message: ChatMessage) => handleSendMessage(message)} />
    </SC.Footer>
  );
}
