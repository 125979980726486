import * as Styled from "../Styles";
import InputButtonList from "../../../InputButtonList/InputButtonList";
import { ButtonType } from "../../../InputButton/InputButton";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import { useDrawer } from "../../../../contexts/DrawerContext";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";

const buttonOptions = [{ text: "Yes" }, { text: "No thanks" }];

interface Props {
  data?: boolean[];
  firstName?: string;
  onChange?: (data: boolean[]) => void;
}

export default function DraftContract(props: Props) {
  const { data = null, firstName = "Daniel", onChange = () => null } = props;
  const { handleTriggerChat, handleCreateContract } = useDrawer();

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>{`Create a draft employment contract for ${firstName}?`}</Styled.Title>
      <Styled.Description>
        {`With a few more details related to compensation and benefits benefits, we can automatically create a draft contract for ${firstName}`}
        <MoreInfoButton triggerChat={() => handleTriggerChat(contactInfo)} />
        <Styled.SubTitle style={{ marginTop: 16 }}>
          Note: You can always create and/or edit the contract before submitting it to G-P for
          review.
        </Styled.SubTitle>
      </Styled.Description>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data}
        onChange={(values) => {
          handleCreateContract(values[0] === true);
          onChange(values);
        }}
      />
    </Styled.QuestionWrapper>
  );
}

const contactInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Contacting professionals",
      text: "Once you've reached a tentative agreement with each individual on the job details and terms, we can contact each individual on your behalf and share/discuss those details to help move the process along.",
    },
    sender: MessageSender.GIA,
  },
];

const contractInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Draft Contracts",
      text: "We can help accelerate the hiring process by automatically generating draft contracts for each individual. Don't worry, you'll have the opportunity to review these before hand.",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "You can also opt to send the contracts to G-P for immediate review and we'll make sure every element is accounted for.",
    },
    sender: MessageSender.GIA,
  },
];

const sendInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Instantly sending contracts to G-P",
      text: "Our team is ready to handle the contact work for you - from creating the employee agreement all the way through to secufing their signature.",
    },
    sender: MessageSender.GIA,
  },
];
