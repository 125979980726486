import { Country } from "../../assets/flags/Flag";

export const singleProDaniel = [
  {
    name: "Daniel Smith",
    firstname: "Daniel",
    title: "Front-End Engineer",
    salary: "85000",
    phone: "+353 3600 658473",
    email: "danielsmith22@gmail.com",
    startDate: "2023-12-15T06:44:16.314Z",
    country: Country.Ireland,
    currency: "EUR",
    symbol: "€",
    status: "ready",
    visa: false,
    jobduties: "UI development, React",
    bonus: false,
    signon: false,
    comission: false,
    morebenefits: false,
    allowance: false,
    covenants: false,
  },
];
