import * as Styled from "./Styles.ts";
import Icon, { IconNames } from "../../assets/icons/Icons";
import Flag, { Country } from "../../assets/flags/Flag.tsx";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton.tsx";
import InputField from "../InputField/InputField.tsx";
import InputCheckBox from "../InputCheckbox/InputCheckbox.tsx";
import { useEffect, useState } from "react";
import InputPillBar from "../InputPillBar/InputPillBar.tsx";
import { useDrawer } from "../../contexts/DrawerContext.tsx";
import { TeamIcon } from "../../assets/icons/team.tsx";
import { colors } from "../../assets/tokens/tokens.ts";
import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes.ts";
import video_poster from "../../assets/images/add_video@2x.png";
import { usePros } from "../../contexts/ProfessionalsContext.tsx";
import { usePartner } from "../../hooks/usePartner.tsx";

const nodata = [];

interface Props {
  //
}

export default function ListProfessionals(props: Props) {
  const { proCounts, search, filtredPros, handleSearchChange } = usePros();
  const { bulkEdit, handleViewDetails } = useDrawer();
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => setSelectAll(!bulkEdit ? false : selectAll), [bulkEdit]);

  const renderProfessionals = () => {
    return filtredPros.map((item: any, i: number) => {
      return (
        <ItemCard
          key={item.name + "_" + i}
          last={i === proCounts.all - 1}
          flag={item.country}
          name={item.name}
          currency={item.currency}
          salary={item.salary}
          title={item.title}
          checked={selectAll}
          select={bulkEdit}
          state={"added"}
          onClick={() => handleViewDetails(1, i)}
        />
      );
    });
  };

  return (
    <Styled.QuestionWrapper style={{ height: "100%" }}>
      <Styled.Title>International professionals.</Styled.Title>
      <Styled.Description>
        <StatusMessage isHeader={true} />
      </Styled.Description>
      <NoDataImage />
      <div style={{ display: proCounts.all === 0 ? "none" : "block" }}>
        <InputField
          theme={"framed"}
          value={search}
          leftIcon={true}
          leftIconName={IconNames.search}
          placeholder={"search professionals"}
          iconTheme={"system"}
          onChange={(value: string) => handleSearchChange(value)}
        />
        <InputPillBar />
        <TitleSection
          count={proCounts.filtred}
          bulkEdit={bulkEdit}
          selectAll={selectAll}
          onChange={(state: boolean) => setSelectAll(state)}
        />
        {renderProfessionals()}
        <StatusMessage isHeader={false} />
      </div>
    </Styled.QuestionWrapper>
  );
}

interface Props {
  flag?: Country;
  name?: string;
  title?: string;
  last?: boolean;
  salary?: string | number;
  checked?: boolean;
  state?: string;
  currency?: string;
  select?: boolean;
  onClick?: () => void;
}
const ItemCard = (props: Props) => {
  const {
    flag,
    name,
    title,
    last,
    salary,
    currency,
    checked = false,
    state = "added",
    select = false,
    onClick = () => null,
  } = props;
  const CADDollar = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
  const setSalary = (value: any) => {
    if (isNaN(value)) return 0;
    return value;
  };
  const [edit, setEdit] = useState(select);
  const [selected, setSelected] = useState(checked);
  useEffect(() => setEdit(select), [select]);
  useEffect(() => setSelected(checked), [checked]);
  const setCheckBox = () => {
    if (state === "added" && select) {
      return (
        <div style={{ paddingRight: 16 }}>
          <InputCheckBox checked={selected} />
        </div>
      );
    }
  };
  return (
    <Styled.TableItem $isLast={last} onClick={() => onClick()}>
      {setCheckBox()}
      <Flag country={flag} size={32} />
      <Styled.Details $isLeft={true}>
        <Styled.Name>{name}</Styled.Name>
        <Styled.JobTitle>{title}</Styled.JobTitle>
      </Styled.Details>
      <Styled.Compensation>
        {CADDollar.format(Math.round(setSalary(salary)))} {currency}
        <Icon name={IconNames.caretRight} theme={"system"} />
      </Styled.Compensation>
    </Styled.TableItem>
  );
};

interface TitleProps {
  title?: { list?: string; edit: "string" };
  count?: number;
  bulkEdit?: boolean;
  selectAll?: boolean;
  onChange?: (state: boolean) => void;
}

const TitleSection = (props: TitleProps) => {
  const {
    title = { list: "Professionals", edit: "Select all" },
    bulkEdit = false,
    selectAll = false,
    count = 0,
    onChange = () => null,
  } = props;
  const [newCount, setNewCount] = useState(count);
  useEffect(() => setNewCount(count), [count]);

  const setSelectAll = () => {
    if (!bulkEdit) return;
    return (
      <InputCheckBox
        checked={selectAll}
        onChange={(state: boolean) => {
          onChange(state);
          setNewCount(state ? 500 : count);
        }}
      />
    );
  };
  const setCount = () => {
    if (bulkEdit && !selectAll) return;
    return <Styled.Count>{newCount}</Styled.Count>;
  };

  return (
    <Styled.TableTitle $bulkEdit={bulkEdit}>
      {setSelectAll()}
      {bulkEdit ? title.edit : title.list}
      {setCount()}
    </Styled.TableTitle>
  );
};

const StatusMessage = (props: { isHeader: boolean }) => {
  const { handleTriggerChat } = useDrawer();
  const { proCounts } = usePros();
  const { partnerName } = usePartner();
  const message = () => {
    if (proCounts.all === 0)
      return "You currently have no international professionals, managed through G-P's EOR, on your roster.";
    if (proCounts.filtred === 0) return "No professionals match your filter criteria.";
    return null;
  };

  const introMessage: Array<ChatMessage> = [
    {
      message: {
        title: "Adding international professionals",
        text: `With ${partnerName} and G-P, hiring international professionals in countries where you have no legal entity is effortless and quick.`,
      },
      sender: MessageSender.GIA,
    },
    {
      message: {
        text: 'If you already have a professional(s) in mind, simply select "Add hires" and we\'ll guide you through each step.',
      },
      sender: MessageSender.GIA,
    },
    {
      message: {
        text: "This 1 minute video walks you through the entire process.",
      },
      files: [
        { name: "Introducing EOR by G-P", type: "video", size: "1:05 mins", image: video_poster },
      ],
      sender: MessageSender.GIA,
    },
  ];

  if (proCounts.filtred > 0 || (proCounts.all > 0 && props.isHeader)) return null;
  return (
    <Styled.StatusMessage>
      {message()}
      <MoreInfoButton triggerChat={() => handleTriggerChat(introMessage)} />
    </Styled.StatusMessage>
  );
};

const NoDataImage = () => {
  const { proCounts } = usePros();
  if (proCounts.all !== 0) return null;
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
        display: "flex",
        alignSelf: "stretch",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 60,
      }}
    >
      <TeamIcon size={216} color={colors.grays200} />
    </div>
  );
};
