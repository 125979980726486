import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 24px 48px;
  flex: 1 0 0;
`;

export const Status = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  gap: 8px;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
`;

export const SubTitle = styled.div`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -8px 0px 16px 0px;
  padding: 0px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;
