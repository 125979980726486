import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import WeeklyCalendar from "./WeeklyCalendar/WeeklyCalendar";

export default function DayAndTime() {
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Select a date and time to talk to a G-P expert.</Styled.Title>
      <Styled.Description>
        We'll schedule a meeting and send you an invitation.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>
      <WeeklyCalendar navigate={(i) => null} />
    </Styled.QuestionWrapper>
  );
}
