import { useDrawer } from "../../contexts/DrawerContext";
import { DrawerAction } from "../EnumsAndTypes/EnumsAndTypes";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";

export default function Connecting() {
  const { visible, handleDrawerAction } = useDrawer();

  return (
    <ProgressIndicator
      status={{ working: "Connecting", done: "Connected" }}
      loops={5}
      size={125}
      stroke={0.75}
      playing={visible}
      showGP={true}
      progressEndDelay={1000}
      progressEnd={() => handleDrawerAction(DrawerAction.GPLoaded)}
    />
  );
}
