import * as Styled from "./Styles";
import { useAnimate } from "framer-motion";
import { useState, useEffect } from "react";
import { colors } from "../../assets/tokens/tokens";

interface Props {
  status?: { working: string; done: string };
  hascaption?: boolean;
  playing?: boolean;
  width?: number | string;
  thikness?: number;
  duration?: number;
  delay?: number;
  trackcolor?: string;
  progresscolor?: string;
  progressEndDelay?: "none" | number;
  progressStart?: () => void;
  progressStop?: () => void;
  progressEnd?: () => void;
}

export default function ProgressBar(props: Props) {
  const {
    hascaption = false,
    status = { working: "working", done: "done" },
    playing = false,
    width = "100%",
    thikness = 4,
    duration = 5,
    delay = 0.5,
    trackcolor = colors.grays100,
    progresscolor = colors.graysTextPrimary,
    progressEndDelay = "none",
    progressStart = () => null,
    progressStop = () => null,
    progressEnd = () => null,
  } = props;
  const [progress, animateProgress] = useAnimate();
  const [caption, setCaption] = useState(status.working);
  const [working, setWorking] = useState(playing);
  useEffect(() => setWorking(playing), [playing]);

  useEffect(() => {
    let timer = null;
    if (!working) return;
    const animation = async () => {
      const trans = { ease: "easeInOut", duration: duration, delay: delay } as any;
      progressStart();
      await animateProgress(progress.current, { width: "100%" }, trans);
      progressStop();
      setCaption(status.done);
      setWorking(false);
      if (progressEndDelay !== "none") {
        timer = setTimeout(() => {
          progressEnd();
        }, progressEndDelay);
      } else {
        progressEnd();
      }
    };
    animation();
    return () => (timer ? clearTimeout(timer) : null);
    // eslint-disable-next-line
  }, [working]);

  const captionVariants = {
    playing: { color: "rgba(0,0,0,0.2)" },
    done: { color: "rgba(0,0,0,1)" },
  };

  const showCaption = () => {
    if (!hascaption) return null;
    return (
      <Styled.Caption
        variants={captionVariants}
        initial={"playing"}
        animate={working ? "playing" : "done"}
      >
        {caption}
      </Styled.Caption>
    );
  };

  return (
    <Styled.Wrapper $width={width} onClick={() => setWorking(true)}>
      <Styled.Track $height={thikness} $color={trackcolor}>
        <Styled.ProgressBar ref={progress} $color={progresscolor} />
      </Styled.Track>
      {showCaption()}
    </Styled.Wrapper>
  );
}
