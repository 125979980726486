import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import profilepic from "../../assets/images/profilepic2.png";
import NavButton from "../NavButton/NavButton";
import { IconNames } from "../../assets/icons/Icons";

export default function CallConfirmed() {
  const setDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date.toLocaleDateString("en-US", { day: "2-digit", month: "long" });
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>You're all set Janice.</Styled.Title>
      <Styled.Description>
        We look forward to talking with you Monday, the 15th of Dec. at 1pm. We've also sent you an
        email with meeting details and the dial-in information.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>

      <Styled.TableItem $isLast={true}>
        <Styled.TableItemHeader>
          <img src={profilepic} width={40} alt={"Janice's profile"} />
          <Styled.Details $isLeft={true}>
            <Styled.Name>Janice Jenkins</Styled.Name>
          </Styled.Details>
          <Styled.Details $isLeft={false}>
            <Styled.Compensation>Acme Inc.</Styled.Compensation>
          </Styled.Details>
        </Styled.TableItemHeader>
        <Styled.LineItem>
          Email:<div>janicej@acme.com</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Phone:<div>+1 (617) 671-5587</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Date:<div>{setDate()}</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Time:<div>1:00 PM Pacific</div>
        </Styled.LineItem>
        <Styled.LineItem>
          <div>
            Zoom Link:{" "}
            <Styled.TextLink>
              https://globalization-partners.zoom.us/j/7697960400?from=addon
            </Styled.TextLink>
          </div>
        </Styled.LineItem>
        <Styled.LineItem>
          <div>
            Dial-in: <Styled.TextLink>833 928 4609</Styled.TextLink> (US Toll-free)
          </div>
        </Styled.LineItem>
        <Styled.LineItem>
          <Styled.List>
            <Styled.ListItem>Scheduled call confirmed</Styled.ListItem>
          </Styled.List>
        </Styled.LineItem>
      </Styled.TableItem>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <NavButton
          icon={IconNames.plusCircle}
          iconpos={"left"}
          iconstyle={{ size: 24, stroke: 1.5 }}
          label={"Add to calendar"}
          theme={"link"}
        />
      </div>
    </Styled.QuestionWrapper>
  );
}
