import styled from "styled-components";
import { colors, transitions, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  position: relative;
  boxsizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1 0 0;
`;

export const Content = styled(motion.div)`
  position: absolute;
  boxsizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 24px 48px;
  width: 100%;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Responses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0px;
  padding: 0px;
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
`;

export const SubTitle = styled.p`
  ${type.pMedium};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  margin-top: 40px;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -8px 0px 16px 0px;
  padding: 0px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

export const LinkButton = styled.div`
  ${type.pSmall};
  color: ${colors.primaryGPBlue};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  gap: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const FormFieldsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const TableTitle = styled.div<{ $bulkEdit: boolean }>`
  ${type.sectionTitle};
  color: ${colors.graysTextPrimary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 0px;
  border-bottom: 1px solid ${colors.grays400};
  box-sizing: border-box;
  margin-top: 24px;
`;

export const Count = styled.div`
  ${type.caption};
  color: ${colors.graysTextDisabled};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${colors.grays200};
`;

export const Notification = styled.div`
  ${type.pSmall};
  color: ${colors.warning300};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
  gap: 8px;
  background-color: ${colors.warning300a10};
  cursor: pointer;
`;

export const StatusMessage = styled.div`
  padding: 0;
  margin: 16px 0;
  line-height: 1.5em;
`;

export const TableItem = styled.div<{ $isLast: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0px 20px 0px;
  gap: 16px;
  border-radius: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
  border-bottom: 1px solid ${colors.grays400};
  ${(p) => (p.$isLast ? "margin-bottom: 32px" : null)};
`;

export const Details = styled.div<{ $isLeft: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.$isLeft ? "flex-start" : "flex-end")};
  justify-content: center;
  gap: 0px;
  overflow: hidden;
`;

export const Name = styled.div`
  ${type.pRegularBold};
  color: ${colors.graysTextPrimary};
  line-height: 1.2em;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const JobTitle = styled.div`
  ${type.pSmall};
  color: ${colors.graysTextTertiary};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Compensation = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  ${type.pRegular};
  color: ${colors.graysTextPrimary};
  line-height: 1em;
  flex-grow: 1;
  white-space: nowrap;
`;

export const BenefitType = styled.div`
  ${type.pSmall}
  color: ${colors.graysTextTertiary};
  text-transform: uppercase;
`;

export const Link = styled.a`
  color: ${colors.primaryGPBlue};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
