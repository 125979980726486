import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { color, motion } from "framer-motion";

export const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const Header = styled.div`
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: ${colors.graysWhite};
  color: ${colors.graysTextPrimary};
  padding: 16px 16px;
  width: 100%;
  gap: 8px;
  border-bottom: 1px solid ${colors.grays300};
`;

export const Logo = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const Sections = styled.div`
  position: abosolute;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.grays100};
`;

export const Contract = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 48px 120px;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grays400};
    border-radius: 20px;
    border: 4px solid ${colors.grays100};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.grays500};
    border-radius: 20px;
    border: 4px solid ${colors.grays100};
    cursor: default;
  }
`;

export const Warning = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: ${colors.green100};
  text-transform: uppercase;
  color: ${colors.green500};
  padding: 10px 0px;
  width: 100%;
  gap: 8px;
`;

export const BottomBar = styled.div`
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${colors.graysWhite};
  gap: 32px;
  border-top: 1px solid ${colors.grays300};
`;

export const ProfessionalID = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  fontsize: 18px;
`;

export const ModalTitle = styled(motion.div)`
  flex: 0;
  width: 100%;
  text-align: center;
  ${type.pRegularBold};
  line-height: 1.2em;
  margin: 0;
  padding: 0;
`;

export const ModalText = styled(motion.div)`
  flex: 1;
  width: 100%;
  text-align: center;
  ${type.pMedium};
  margin: 0;
  padding: 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Legal = styled.div`
  ${type.pSmall};
  box-sizing: border-box;
  padding: 16px;
  font-weight: normal;
  font-style: normal;
  background-color: transparent;
  font-size: 12px;
  color: rgb(148; 148; 148);
  line-height: 2em;
  letter-spacing: 0.01em;
  margin: 0 0 32px 0;
  width: 100%;
  max-width: 1024px;
  border: 1px dashed #d8d8d8;
  outline: none;
  border-radius: 8px;
`;

export const CommentsBar = styled(motion.div)`
  box-sizing: border-box;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  background-color: ${colors.grays200};
  border-left: 1px solid ${colors.grays300};
  font-size: 12px;
  color: rgb(148; 148; 148);
  line-height: 2em;
  letter-spacing: 0.01em;
  margin: 0;
  width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grays400};
    border-radius: 20px;
    border: 4px solid ${colors.grays200};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.grays500};
    border-radius: 20px;
    border: 4px solid ${colors.grays200};
    cursor: default;
  }
`;
