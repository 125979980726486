import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 16px;
  margin: 0;
  border: 1px solid ${colors.grays300};
  gap: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  cursor: move;
  cursor: grab;
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0;
  maring: 0;
  cursor: move;
  cursor: grab;
`;

export const Text = styled.div`
  box-sixing: border-box;
  font-size: 13px;
  text-overflow: ellipsis;
  line-height: 1.4em;
  letter-spacing: 0.015em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0;
  margin: 0;
  cursor: move;
  cursor: grab;
`;

export const Author = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  maring: 0;
  gap: 8px;
`;

export const Input = styled.div<{ $visible: boolean }>`
  box-sizing: border-box;
  padding: 16px 0 8px 0;
  width: 100%;
  border-top: 1px solid ${colors.grays200};
`;
