import * as Styled from "./Styles";
import profilepic from "../../assets/images/camila@2x.png";
import profilepic2 from "../../assets/images/profilepic.png";
import InputMessage from "../InputMessage/InputMessage";
import { motion, AnimatePresence } from "framer-motion";
import { colors } from "../../assets/tokens/tokens";
import { useContract } from "../../contexts/ContractContext";
import { useEffect, useState } from "react";
import { ChatMessage, Comment, Reply } from "../EnumsAndTypes/EnumsAndTypes";

interface Props {
  comment: Comment;
  index: number;
  replies?: Reply[];
}

export function CommentBubble(props: Props) {
  const { comment = null, replies = null, index = null } = props;
  const {
    handleScrollTo,
    handleSetReply,
    activeComment,
    handleSetActiveComment,
    handleResolveComment,
  } = useContract();
  const [elapsedMinutes, setElapsedMinutes] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setElapsedMinutes(addMinute(elapsedMinutes)), 1000 * 60);
    return () => clearTimeout(timer);
  }, [elapsedMinutes]);

  function addMinute(elapsedMinutes: number) {
    return elapsedMinutes + 1;
  }

  const setDateTime = (mins: number) => {
    const hours = mins / 60;
    if (mins < 1) return `just now`;
    if (mins < 60) return `${Math.floor(mins)} min`;
    return `${hours} hr`;
  };

  const setReplies = () => {
    if (!replies || replies.length === 0) return "reply";
    return `${replies.length} replies`;
  };

  const renderReplies = () => {
    if (!replies || replies.length < 1 || activeComment !== index) return;
    return replies.map((reply: Reply, i: number) => {
      return <CommentReply key={"reply_" + i + "_grpoup" + index} info={reply} />;
    });
  };

  return (
    <AnimatePresence initial={true}>
      {!comment.resolved && (
        <Styled.Container
          $active={activeComment === index ? true : false}
          initial={{ opacity: 1, height: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
        >
          <Styled.Comment $isParent={true}>
            <Styled.Header onClick={() => handleScrollTo(comment.selection.from)}>
              <img src={profilepic} height={32} />
              <CheckMark
                stroke={1.5}
                size={24}
                color={colors.grays500}
                colorOn={colors.green500}
                onClick={() => handleResolveComment(index)}
              />
            </Styled.Header>
            <Styled.Author onClick={() => handleScrollTo(comment.selection.from)}>
              Camila Martinez <Styled.SmallText>{setDateTime(elapsedMinutes)}</Styled.SmallText>
            </Styled.Author>
            <Styled.Fragment onClick={() => handleScrollTo(comment.selection.from)}>
              ...{comment.fragment}
            </Styled.Fragment>
            <div style={{ height: 8 }} />
            <Styled.Text onClick={() => handleScrollTo(comment.selection.from)}>
              {comment.text}
            </Styled.Text>
            <Styled.Link onClick={() => handleScrollTo(comment.selection.from)}>
              <span
                className="link"
                onClick={() => handleSetActiveComment(activeComment === index ? null : index)}
              >
                {setReplies()}
              </span>
            </Styled.Link>
          </Styled.Comment>

          {renderReplies()}

          <Styled.Input $visible={activeComment === index ? true : false}>
            <InputMessage
              size={"small"}
              placeholder={"Enter commnet"}
              height={"21px"}
              onSendMessage={(message: ChatMessage) => {
                const reply: Reply = {
                  author: "Jennifer Nolan",
                  text: message.message.text,
                  datetime: new Date().toString(),
                  parent: index,
                };
                handleSetReply(reply);
              }}
            />
          </Styled.Input>
        </Styled.Container>
      )}
    </AnimatePresence>
  );
}

const CheckMark = (props: {
  size: number;
  stroke: number;
  color: string;
  colorOn: string;
  onClick: () => void;
}) => {
  const [hover, setHover] = useState(false);
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{ cursor: "pointer" }}
      onClick={() => props.onClick()}
    >
      <motion.path
        d="M 8.44 12.339 L 10.607 14.506 L 10.587 14.486 L 15.467 9.596"
        fill="transparent"
        strokeWidth={props.stroke}
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
        animate={{ stroke: hover ? props.colorOn : props.color }}
      ></motion.path>
      <motion.path
        d="M 12 3 C 7.029 3 3 7.029 3 12 C 3 16.971 7.029 21 12 21 C 16.971 21 21 16.971 21 12 C 21 7.029 16.971 3 12 3 Z"
        fill="transparent"
        strokeWidth={props.stroke}
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
        animate={{ stroke: hover ? props.colorOn : props.color }}
      ></motion.path>
    </motion.svg>
  );
};

interface RepliesProps {
  info?: Reply;
  isActive?: boolean;
}
const CommentReply = (props: RepliesProps) => {
  const { info, isActive = false } = props;
  const [elapsedMinutes, setElapsedMinutes] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setElapsedMinutes(addMinute(elapsedMinutes)), 1000 * 60);
    return () => clearTimeout(timer);
  }, [elapsedMinutes]);

  function addMinute(elapsedMinutes: number) {
    return elapsedMinutes + 1;
  }

  const setDateTime = (mins: number) => {
    const hours = mins / 60;
    if (mins < 1) return `just now`;
    if (mins < 60) return `${Math.floor(mins)} min`;
    return `${hours} hr`;
  };

  return (
    <Styled.Comment $isParent={false}>
      <Styled.Author>
        <img src={profilepic2} height={24} />
        {info.author} <Styled.SmallText>{setDateTime(elapsedMinutes)}</Styled.SmallText>
      </Styled.Author>
      <Styled.Text>{info.text}</Styled.Text>
    </Styled.Comment>
  );
};
