import { useDrawer } from "../../../../contexts/DrawerContext";
import { DrawerAction } from "../../../EnumsAndTypes/EnumsAndTypes";
import ProgressIndicator from "../../../ProgressIndicator/ProgressIndicator";

export default function AddComplete() {
  const { handleDrawerAction } = useDrawer();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 0 0",
      }}
    >
      <ProgressIndicator
        status={{ working: "Adding professsional(s)", done: "Professional(s) Added" }}
        type={"check"}
        loops={10}
        stroke={0.75}
        size={125}
        playing={true}
        fill={true}
        progressEnd={() => handleDrawerAction(DrawerAction.DidAddProfessional)}
      />
    </div>
  );
}
