import { useEffect, useState } from "react";
import * as SC from "./Styles";

interface Props {
  show?: boolean;
  opacity?: number;
  color?: string;
  onClick?: () => void;
}

export function Overlay(props: Props) {
  const { show = false, opacity = 0.8, color = "rgb(0,0,0)", onClick = () => null } = props;
  const [visible, setVisible] = useState(show);
  useEffect(() => setVisible(show), [show]);
  const variants = {
    show: { opacity: opacity },
    hide: { opacity: 0 },
  };

  return (
    <SC.Overlay
      $bgcolor={color}
      $visible={visible}
      variants={variants}
      initial={"hide"}
      transition={{ ease: "easeInOut", duration: 0.25 }}
      animate={visible ? "show" : "hide"}
      onClick={() => onClick()}
    />
  );
}

export default Overlay;
