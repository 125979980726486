import { useEffect } from "react";
import * as Styled from "./Styles";
import { useAnimate } from "framer-motion";
import { useContract } from "../../contexts/ContractContext";

export function ContractMenu() {
  const { handleScrollTo, showMenu } = useContract();
  const [ref, animateContent] = useAnimate();

  const sections = [
    { title: "Contract of Employment", scrollto: 1 },
    { title: "Commencement of Employment", scrollto: 895 },
    { title: "Policies and Proceedures", scrollto: 1975 },
    { title: "Job Title and Place of Work", scrollto: 2857 },
    { title: "Remuneration", scrollto: 4474 },
    { title: "Expenses", scrollto: 5086 },
    { title: "Hours of Work and Overtime Payments", scrollto: 5305 },
    { title: "Vacation", scrollto: 5874 },
    { title: "Termination of Employment", scrollto: 6846 },
    { title: "Confidentiality", scrollto: 9451 },
    { title: "Data Protection", scrollto: 9810 },
    { title: "Full-Time and Attention", scrollto: 10175 },
    { title: "Change of Terms of Employment", scrollto: 10590 },
    { title: "Remedies", scrollto: 10855 },
    { title: "General", scrollto: 11790 },
    { title: "Schedule “A”", scrollto: 14920 },
  ];
  useEffect(() => {
    if (ref && ref.current) {
      const height = ref.current.offsetHeight;
      animateContent(
        ref.current,
        { top: showMenu ? 60 : -height },
        { ease: "easeInOut", duration: 0.25 }
      );
    }
  }, [showMenu]);

  const handleClick = (scrollto: string) => {
    if (!scrollto) return;
    handleScrollTo(scrollto);
    return;
  };

  const renderSections = () => {
    return sections.map((section: any, i: number) => {
      return (
        <Styled.Option
          key={"navto_" + section.title + "_" + i}
          onClick={() => handleClick(section.scrollto)}
        >
          <span className="link">{`${i + 1}. ${section.title}`}</span>
        </Styled.Option>
      );
    });
  };

  return <Styled.Wrapper ref={ref}>{renderSections()}</Styled.Wrapper>;
}
