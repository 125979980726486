import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useEffect, useRef, useState } from "react";
import { transitions } from "../../assets/tokens/tokens";
import ListProfessionals from "./ListProfessionals";
import ProfessionalDetails from "./ProfessionalDetails/ProfessionalDetails";
import { Direction } from "../EnumsAndTypes/EnumsAndTypes";
import { useDrawer } from "../../contexts/DrawerContext";
import { usePros } from "../../contexts/ProfessionalsContext";

export default function ViewProfessionals() {
  const { pageIndex, employeeID } = useDrawer();
  const [direction, setDirection] = useState(Direction.forward);
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  useEffect(() => {
    setDirection(pageIndex === 1 ? -1 : 1);
  }, [pageIndex]);

  // values for the pagging animation
  const amt = () => {
    return size?.width ? size.width : 500;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let x = direction === Direction.forward ? amt() : -amt();
      return { x: x, opacity: 0, overflow: "hidden" };
    },
    animate: { x: 0, opacity: 1, overflow: "auto" },
    exit: (direction: Direction) => {
      let x = direction === Direction.forward ? -amt() : amt();
      return { x: x, opacity: 1, overflow: "hidden" };
    },
  };

  const setContent = () => {
    if (pageIndex === 0) {
      return <ListProfessionals />;
    }
    return <ProfessionalDetails employeeID={employeeID} />;
  };

  return (
    <SC.Wrapper ref={ref}>
      <AnimatePresence initial={false} custom={direction}>
        <SC.Content
          custom={direction}
          transition={{ ...transitions.easIn, duration: 0.85 }}
          variants={scrollVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"pageIndex_" + pageIndex}
        >
          {setContent()}
        </SC.Content>
      </AnimatePresence>
    </SC.Wrapper>
  );
}
