interface Props {
  height?: number;
  stroke?: number;
  symbolColor?: string;
  doccolor?: string;
  creasecolor?: string;
}

export default function PDFDoc(props: Props) {
  const {
    stroke = 1.5,
    height = 48,
    symbolColor = "rgb(204, 75, 75)",
    doccolor = "rgb(233, 233, 224)",
    creasecolor = "rgb(217, 215, 202)",
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox={"0,0,31,39"}>
      <path
        d="M 1 36 L 1 3 C 1 1.895 1.895 1 3 1 L 17.384 1 C 19.041 1 20.384 2.343 20.384 4 L 20.384 8.361 C 20.384 9.466 21.279 10.361 22.384 10.361 L 27 10.361 C 28.657 10.361 30 11.704 30 13.361 L 30 36 C 30 37.105 29.105 38 28 38 L 3 38 C 1.895 38 1 37.105 1 36 Z"
        fill={doccolor}
        stroke={doccolor}
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 20 9 L 20 2.5 L 28.5 11 L 22 11 C 20.895 11 20 10.105 20 9 Z"
        fill={creasecolor}
        stroke={creasecolor}
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 14.389 11.988 C 14.389 11.988 15.632 12.075 15.712 14.204 C 15.759 15.391 15.506 17.005 13.21 22.133 C 10.906 27.26 8.966 30.347 7.794 30.845 C 6.622 31.344 6.171 30.299 6.171 30.299 C 6.171 30.299 5.482 29.405 6.828 28.02 C 7.66 27.173 9.338 25.488 14.833 24.404 C 20.328 23.328 23.17 24.024 24.255 24.697 C 25.339 25.369 24.912 26.39 24.912 26.39 C 24.912 26.39 24.5 27.522 22.782 27.063 C 21.729 26.778 20.993 26.366 17.461 22.489 C 13.392 18.018 13.004 16.024 13.059 13.753 C 13.099 11.972 14.381 11.98 14.381 11.98 Z"
        fill="transparent"
        strokeWidth={stroke}
        stroke={symbolColor}
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
    </svg>
  );
}
