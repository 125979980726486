interface Props {
  width?: number;
  height?: number;
}

export function IpeLogo(props: Props) {
  const { width = 120, height = null } = props;
  const dimensions = () => {
    if (height) return { height: height };
    return { width: width };
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...dimensions()} viewBox={"0 0 24 24"}>
      <rect width="24" height="24" rx="4" fill="#53389E" />
      <circle cx="12" cy="12" r="3.5" fill="white" />
    </svg>
  );
}

export default IpeLogo;
