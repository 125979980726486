export function TeamIcon(props: { size?: number; color?: string }) {
  return (
    <svg
      height={props.size}
      width={props.size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m41.714 46.745c-.336 0-.671-.004-1.007-.012-.553-.013-.989-.471-.977-1.023.013-.552.473-1.03 1.023-.976 4.405.104 8.753-.508 12.948-1.818.523-.164 1.088.129 1.252.657.165.527-.129 1.088-.656 1.252-4.082 1.275-8.311 1.92-12.583 1.92z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m34.253 33.878c-.362 0-.711-.197-.89-.541-.253-.491-.062-1.094.43-1.348 1.327-.687 2.744-1.183 4.21-1.476.547-.11 1.069.244 1.177.785.108.542-.243 1.068-.784 1.176-1.283.256-2.523.691-3.685 1.292-.147.077-.304.112-.458.112z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m41.66 34.01c-.715 0-1.425-.146-2.108-.432-3.684-1.541-5.004-5.89-5.017-9.146 0-3.892 3.285-7.177 7.173-7.177s7.173 3.285 7.173 7.173c-.015 2.07-.483 4.013-1.354 5.626-1.318 2.44-3.566 3.956-5.867 3.956zm.048-14.755c-2.804 0-5.173 2.369-5.173 5.173.011 2.85 1.187 6.216 3.788 7.305.439.184.889.276 1.337.277 1.541 0 3.153-1.141 4.106-2.906.717-1.326 1.102-2.946 1.114-4.683.001-2.797-2.368-5.166-5.172-5.166z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m54 44.87c-.553 0-1-.448-1-1 0-.471.012-.944.024-1.418.057-2.217.11-4.312-1.116-6.019-1.748-2.432-4.717-3.496-6.899-3.961-.54-.115-.885-.646-.771-1.186.115-.541.646-.888 1.187-.77 2.528.538 5.986 1.799 8.107 4.75 1.619 2.252 1.554 4.787 1.491 7.237-.011.457-.023.914-.023 1.367 0 .552-.447 1-1 1z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m25.29 34.253c-.968 0-1.922-.218-2.834-.649-4.231-1.998-5.773-7.024-5.806-11.062 0-4.732 3.991-8.724 8.716-8.724s8.716 3.991 8.716 8.716c-.021 2.709-.665 5.221-1.862 7.271-1.602 2.744-4.258 4.448-6.93 4.448zm.075-18.434c-3.64 0-6.716 3.076-6.716 6.716.026 3.265 1.263 7.656 4.66 9.261.643.304 1.309.458 1.98.458 1.964 0 3.958-1.324 5.202-3.456 1.021-1.749 1.571-3.917 1.589-6.27.001-3.633-3.075-6.709-6.715-6.709z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m40.73 47.838c-.553 0-1-.448-1-1 0-.56.012-1.12.023-1.677.063-3.051.123-5.933-1.834-8.28-1.823-2.187-4.644-3.713-8.384-4.537-.539-.119-.88-.652-.761-1.192s.649-.884 1.192-.761c4.183.921 7.375 2.674 9.488 5.208 2.437 2.922 2.366 6.318 2.298 9.603-.011.543-.022 1.089-.022 1.635 0 .553-.447 1.001-1 1.001z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m10 47.838c-.552 0-1-.448-1-1 0-.545-.011-1.09-.022-1.633-.068-3.285-.138-6.681 2.298-9.604 2.112-2.535 5.304-4.288 9.489-5.209.535-.123 1.072.222 1.191.762.119.539-.222 1.073-.762 1.191-3.741.824-6.562 2.35-8.382 4.536-1.958 2.35-1.898 5.231-1.835 8.283.011.556.023 1.114.023 1.674 0 .552-.448 1-1 1z"
          fill={props.color}
        />
      </g>
      <g>
        <path
          d="m25.365 50.192c-5.27 0-10.54-.8-15.663-2.399-.527-.165-.821-.726-.657-1.252.165-.528.728-.82 1.252-.657 9.857 3.078 20.277 3.078 30.135 0 .523-.165 1.089.129 1.252.657.165.527-.129 1.088-.656 1.252-5.123 1.599-10.393 2.399-15.663 2.399z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}
