import * as SC from "./Styles";
import DrawerHeader from "./DrawerHeader";
import DrawerFooter from "./DrawerFooter";
import DrawerProgres from "./DrawerProgress";
import { View } from "../EnumsAndTypes/EnumsAndTypes";
import { useDrawer } from "../../contexts/DrawerContext";
import Connecting from "../Connecting/Connecting";
import AddProfessional from "../AddProfessional/AddProfessional";
import ViewProfessionals from "../ListProfessionals/ViewProfessionals";
import SideView from "../SideView/SideView";
import { SubmitContracts } from "../SubmitContracts/SubmitContracts";
import WhatHappensNext from "../WhatHappensNext/WhatHappensNext";

export function GPDrawerMSA() {
  const { visible, GPLoaded, GIA, viewstate, footerHeight } = useDrawer();

  const drawerWidth = 500;

  const DrawerVariants = {
    hidden: { right: -drawerWidth, opacity: 1, width: drawerWidth },
    visible: { right: 0, opacity: 1, width: drawerWidth },
    showgia: { right: 400, opacity: 1, width: drawerWidth },
  };

  const setVariant = () => {
    if (GIA) return visible ? "showgia" : "hidden";
    return visible ? "visible" : "hidden";
  };

  return (
    <SC.Wrapper
      $width={drawerWidth}
      variants={DrawerVariants}
      initial={"hidden"}
      transition={{ ease: "easeInOut", duration: 0.25 }}
      animate={setVariant()}
    >
      {GPLoaded && <DrawerHeader />}
      {View.AddSingle && <DrawerProgres />}
      <SideView footerHeight={footerHeight} viewstate={viewstate}>
        <Connecting key={View.Connecting} />
        <SubmitContracts key={View.SubmitContracts} />
        <ViewProfessionals key={View.ListPros} />
        <AddProfessional key={View.AddSingle} />
        <WhatHappensNext key={View.WhatHappensNext} />
      </SideView>

      {GPLoaded && <DrawerFooter />}
    </SC.Wrapper>
  );
}

export default GPDrawerMSA;
