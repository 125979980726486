import adp_home from "../../assets/images/adp_home.png";
import adp_menu from "../../assets/images/adp_menu.png";
import adp_list from "../../assets/images/adp_list.png";
import adp_list_menu from "../../assets/images/adp_list_menu.png";
import trinet_home from "../../assets/images/trinet_home.png";
import trinet_list from "../../assets/images/trinet_list.png";
import ipe_home from "../../assets/images/ipe_home.png";
import ipe_list from "../../assets/images/ipe_list.png";
import { ADPScreens } from "../EnumsAndTypes/EnumsAndTypes";
import { useADP } from "../../contexts/ADPContext";
import { usePartner } from "../../hooks/usePartner";

export default function ADPScreen() {
  const { screenView, handleClick } = useADP();
  const { partnerName } = usePartner();

  const home = () => {
    const image =
      partnerName === "ADP" ? adp_home : partnerName === "Trinet" ? trinet_home : ipe_home;
    const action = partnerName === "ADP" ? ADPScreens.HomeView : ADPScreens.MenuView;
    return <img src={image} style={{ width: "100%" }} onClick={() => handleClick(action)} />;
  };

  const menu = () => {
    const image =
      partnerName === "ADP" ? adp_menu : partnerName === "Trinet" ? trinet_home : ipe_home;
    const action = ADPScreens.MenuView;
    return <img src={image} style={{ width: "100%" }} onClick={() => handleClick(action)} />;
  };

  const list = () => {
    const image =
      partnerName === "ADP" ? adp_list : partnerName === "Trinet" ? trinet_list : ipe_list;
    const action = partnerName === "ADP" ? ADPScreens.ListView : ADPScreens.ListMenu;
    return <img src={image} style={{ width: "100%" }} onClick={() => handleClick(action)} />;
  };

  const listmenu = () => {
    const image =
      partnerName === "ADP" ? adp_list_menu : partnerName === "Trinet" ? trinet_list : ipe_list;
    const action = ADPScreens.ListMenu;
    return (
      <img src={image} style={{ width: "100%" }} onClick={() => handleClick(ADPScreens.ListView)} />
    );
  };

  switch (screenView) {
    case ADPScreens.HomeView:
      return home();
    case ADPScreens.MenuView:
      return menu();
    case ADPScreens.ListView:
      return list();
    case ADPScreens.ListMenu:
      return listmenu();
    default:
      return home();
  }
}
