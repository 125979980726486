import { useEffect, useState } from "react";
import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";

interface Props {
  show?: boolean;
  opacity?: number;
  color?: string;
  onClick?: () => void;
  zIndex?: number;
}

export function AnimatedOverlay(props: Props) {
  const {
    zIndex = 1,
    show = false,
    opacity = 0.8,
    color = "rgb(0,0,0)",
    onClick = () => null,
  } = props;
  const [visible, setVisible] = useState(show);
  useEffect(() => setVisible(show), [show]);
  const variants = {
    show: { opacity: opacity, zIndex: zIndex },
    hide: { opacity: 0, zIndex: 0 },
  };

  return (
    <AnimatePresence initial={true}>
      {show && (
        <SC.Overlay
          $bgcolor={color}
          $visible={visible}
          variants={variants}
          initial={"hide"}
          animate={"show"}
          exit={"hide"}
          transition={{ ease: "easeInOut", duration: 0.25 }}
          onClick={() => onClick()}
        />
      )}
    </AnimatePresence>
  );
}
