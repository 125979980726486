import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, type } from "../../assets/tokens/tokens";

export const Wrapper = styled(motion.div)<{ $width: number }>`
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: ${(p) => p.$width}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${colors.graysWhite};
`;

//****
// animate presensce container
//****
export const ContainerView = styled(motion.div)<{ $isFooter: boolean }>`
  position: absolute;
  box-sizing: border-box;
  ${(p) => (p.$isFooter ? null : "top:0;")}
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

//****
// Header styles
//****
export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 79px;
  padding: 0px 32px;
  width: 100%;
  border-bottom: 1px solid ${colors.grays100};
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ActionsArea = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

//****
// Body styles
//****
export const Body = styled.div<{ $footerHeight: number }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  overflow: hidden;
  margin-bottom: ${(p) => p.$footerHeight}px;
`;

//****
// Footer styles
//****
export const AcceptWrapper = styled.div`
  ${type.pMedium};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: auto;
  padding: 16px 0px;
  width: 100%;
  gap: 16px;
  border-top: 1px solid ${colors.grays100};
  background-color: ${colors.graysWhite};
`;

export const AcceptActions = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
`;

export const Legal = styled.div`
  color: ${colors.graysTextDisabled};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 32px;
  width: 100%;
`;

export const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 79px;
  padding: 0px 32px;
  width: 100%;
  border-top: 1px solid ${colors.grays100};
  background-color: ${colors.graysWhite};
`;

export const PrevNext = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
`;

export const ProgressTrack = styled.div`
  width: 100%;
  height: 1.5px;
`;

export const ProgressBar = styled(motion.div)`
  background-color: ${colors.primaryGPBlue};
  height: 100%;
`;
