import styled from "styled-components";
import { colors, type } from "../../assets/tokens/tokens";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 24px 48px 40px 48px;
  flex: 1 0 0;
  width: 100%;
  overflow-y: auto;
`;

export const Description = styled.div`
  ${type.pRegular};
  color: ${colors.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  margin-top: 24px;
  max-width: 550px;
`;

export const Title = styled.p`
  ${type.h2};
  color: ${colors.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const SubTitle = styled.p`
  ${type.h3};
  color: ${colors.graysTextPrimary};
  display: flex;
  line-height: 1.5em;
  align-items: flex-start;
  justify-content: flex-start;
`;
