import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import Icon, { IconNames } from "../../assets/icons/Icons";
import { usePros } from "../../contexts/ProfessionalsContext";
import { usePartner } from "../../hooks/usePartner";

interface Props {}

export default function WhatHappensNext(props: Props) {
  const {} = props;
  const { partnerName } = usePartner();
  const { proCounts } = usePros();

  return (
    <Styled.QuestionWrapper>
      <Styled.Status>
        <Icon name={IconNames.checkInCircle} theme={"ok"} size={24} />
        <Styled.SubTitle>{proCounts.sent} contracts sent to professionals.</Styled.SubTitle>
      </Styled.Status>
      <Styled.Title>What happens next?</Styled.Title>
      <Styled.Description>
        We'll make sure everything keeps moving forward and work with the professional(s) to secure
        their signature:
      </Styled.Description>
      <Styled.SubTitle style={{ marginTop: 24 }}>
        <b>1.</b> We'll contact the professional(s) and invite them onto the platform so they can
        provide any additional personal details and documentation required.
      </Styled.SubTitle>
      <Styled.SubTitle style={{ marginTop: 24 }}>
        <b>2.</b> We'll then share the contract with them for signature - they can sign directly in
        the platform.
      </Styled.SubTitle>
      <Styled.SubTitle style={{ marginTop: 40 }}>
        Note: you can monitor progress directly from your {partnerName} dashboard.
      </Styled.SubTitle>
    </Styled.QuestionWrapper>
  );
}

const contactInfo: Array<ChatMessage> = [
  {
    message: {
      title: "Contacting professionals",
      text: "Once you've reached a tentative agreement with each individual on the job details and terms, we can contact each individual on your behalf and share/discuss those details to help move the process along.",
    },
    sender: MessageSender.GIA,
  },
];
