import * as Styled from "./Styles";
import Icon, { IconNames } from "../../../assets/icons/Icons";
import { useState, useEffect } from "react";
import Flag from "../../../assets/flags/Flag.tsx";
import profilepic from "../../../assets/images/profilepic.png";
import profilepic2 from "../../../assets/images/profilepic3.png";
import Doc from "../../../assets/images/doc.tsx";
import { useDrawer } from "../../../contexts/DrawerContext.tsx";
import { usePros } from "../../../contexts/ProfessionalsContext.tsx";
import { ProStatus } from "../../EnumsAndTypes/EnumsAndTypes.ts";
import { useLocalStore } from "../../../hooks/useLocalStore.ts";

interface Props {
  employeeID?: number;
  clickContract?: () => void;
}

export default function ProfessionalDetails(props: Props) {
  const { employeeID = 1 } = props;
  const { filtredPros } = usePros();
  const { handleViewDetails, isManualAdd } = useDrawer();
  const { handleChangeProByEmail, handleStatusChange } = usePros();
  const [data, setData] = useState(filtredPros[employeeID]);
  const [contractSent, setContractSent] = useState(null);
  useEffect(() => setData(filtredPros[employeeID]), [employeeID]);
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent, false);
    return () => {
      window.removeEventListener("storage", handleStorageEvent, false);
    };
  }, []);

  useEffect(() => {
    if (contractSent && contractSent !== undefined) {
      handleChangeProByEmail(contractSent.email, { status: ProStatus.sent });
    }
  }, [contractSent]);

  const handleStorageEvent = (e: StorageEvent) => {
    if (e.storageArea !== localStorage) return;
    if (e.key === "contractSent") {
      setContractSent(JSON.parse(e.newValue));
    }
  };

  const dateoptions = { weekday: "short", year: "numeric", month: "short", day: "numeric" };

  const CanadaDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const Euro = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });

  const contractLink = (
    name: string,
    email: string,
    edit: boolean,
    role: string,
    salary: string
  ) => {
    const state = edit ? "enabled" : "disabled";
    const formatsalary = isManualAdd
      ? `${Euro.format(parseInt(salary))}+Euro`
      : `${CanadaDollar.format(parseInt(salary))}+CAD`;
    return (
      "/contract?name=" + name + "&email=" + email + "&role=" + role + "&salary=" + formatsalary
    );
  };

  const setStatus = (status: ProStatus) => {
    if (contractSent) {
      if (contractSent.email === data?.email) return "Awaiting signature";
    }
    if (status === ProStatus.editing) return "Editing contract";
    if (status === ProStatus.ready) return "Ready for review";
    if (status === ProStatus.signed) return "Contract Signed";
    if (status === ProStatus.sent) return "Awaiting signature";
    return "Ready for reivew";
  };

  return (
    <Styled.Wrapper>
      <Styled.NavSection>
        <Styled.NavButton
          onClick={() => {
            if (contractSent && contractSent.email === data?.email) {
              handleStatusChange(ProStatus.sent);
            }
            handleViewDetails(0, 0);
          }}
        >
          <Icon name={IconNames.caretLeft} size={20} stroke={2} pointer={true} />
        </Styled.NavButton>
        <Styled.Title>{data?.name.split(" ")[0]}'s details.</Styled.Title>
      </Styled.NavSection>
      <Styled.InfoGroupHead>
        {!isManualAdd && <img src={profilepic2} width={40} alt={`${data?.firstname}'s profile`} />}
        {isManualAdd && <img src={profilepic2} width={40} alt={`${data?.firstname}'s profile`} />}
        <div style={{ flex: 1 }}>
          <Styled.Name>{data?.name}</Styled.Name>
          <Styled.JobTitle>{data?.title}</Styled.JobTitle>
        </div>
        <Flag country={data?.country} size={40} />
      </Styled.InfoGroupHead>

      <Styled.GroupTitle>Payroll Details</Styled.GroupTitle>
      <Styled.InfoGroup>
        <Styled.InfoItem $last={false} $first={true}>
          <Styled.ItemSectionTitle>Salary</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>
            {!isManualAdd && `${CanadaDollar.format(Math.round(data?.salary))}`}
            {isManualAdd && `${Euro.format(Math.round(data?.salary))}`}
          </Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Location</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.country}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Currency</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>
            {data?.currency} ({data?.symbol})
          </Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={true} $first={false}>
          <Styled.ItemSectionTitle>Payroll</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>5th day of each month</Styled.ItemSecondaryText>
        </Styled.InfoItem>
      </Styled.InfoGroup>

      <Styled.GroupTitle>
        Status
        <Styled.Status
          $sent={
            (contractSent && contractSent.email === data?.email) || data?.status === ProStatus.sent
          }
        >
          {setStatus(data?.status)}
        </Styled.Status>
      </Styled.GroupTitle>
      <Styled.InfoGroup>
        <Styled.InfoItem $last={false} $first={true}>
          <Styled.ItemComments>
            {(contractSent && contractSent.email === data?.email) || data?.status === ProStatus.sent
              ? "Contract has been sent and is pending signature by the professional."
              : `Job details have not yet been agreed to by ${
                  data?.name.split(" ")[0]
                }. You can begin
            drafting the employment contract using the template provided below.`}
          </Styled.ItemComments>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={true}>
          <Styled.ItemSectionTitle>Contract</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>
            <Doc />
            <Styled.ContractLink
              to={contractLink(data?.name, data?.email, true, data?.title, data?.salary)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data?.country}: Permanent Employment Contract
            </Styled.ContractLink>
            <Icon name={IconNames.caretRight} theme={"system"} />
          </Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={true} $first={false}>
          <Styled.ItemSectionTitle>Target start date</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>
            {new Date(data?.startDate).toLocaleDateString("en-US", dateoptions as any)}
          </Styled.ItemSecondaryText>
        </Styled.InfoItem>
      </Styled.InfoGroup>

      <Styled.GroupTitle>Contacts</Styled.GroupTitle>
      <Styled.InfoGroup>
        <Styled.InfoItem $last={false} $first={true}>
          <Styled.ItemSectionTitle>Email</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>
            <Styled.LinkButton href="mailto:fakeemail@me.com">{data?.email}</Styled.LinkButton>
          </Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={true} $first={false}>
          <Styled.ItemSectionTitle>Mobile Phone</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.phone}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
      </Styled.InfoGroup>

      <Styled.GroupTitle>Other Details</Styled.GroupTitle>
      <Styled.InfoGroup>
        <Styled.InfoItem $last={false} $first={true}>
          <Styled.ItemSectionTitle>Receives bonus</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.bonus ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Sign on bonus</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.signon ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Earns comissions</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.comission ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Addtional Benefits</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.morebenefits ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Has Allowances</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.allowance ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Added Convenants</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.covenants ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
        <Styled.InfoItem $last={false} $first={false}>
          <Styled.ItemSectionTitle>Visa support</Styled.ItemSectionTitle>
          <Styled.ItemSecondaryText>{data?.visa ? "Yes" : "No"}</Styled.ItemSecondaryText>
        </Styled.InfoItem>
      </Styled.InfoGroup>

      <Styled.ShareDetailsButton>
        <Icon name={IconNames.share} size={24} stroke={1.25} theme={"blue"} />
        Share job details with {data?.firstname} ...
      </Styled.ShareDetailsButton>
      <Styled.AllDetailsButton>Edit {data?.firstname}'s details</Styled.AllDetailsButton>
    </Styled.Wrapper>
  );
}
