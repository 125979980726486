import * as Styled from "../Styles";
import MoreInfoButton from "../../../MoreInfoButton/MoreInfoButton";
import InputButtonList from "../../../InputButtonList/InputButtonList";
import { ButtonType } from "../../../InputButton/InputButton";

const buttonOptions = [{ text: "Yes" }, { text: "No" }];

interface Props {
  firstName?: string;
  data?: any;
  onChange?: (fieldname: string, data: any) => void;
}

export default function BenefitDetails(props: Props) {
  const { firstName = "Daniel", data = null, onChange = () => null } = props;

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Tell us more details about {firstName}'s benefits.</Styled.Title>
      <Styled.Description>
        We'll use this information to generate {firstName}'s employment contract.
      </Styled.Description>

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Would you like to provide supplemental medical insurance?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.medical}
        onChange={(values) => onChange("medical", values)}
      />

      <Styled.SubTitle style={{ marginTop: 24 }}>
        Would you like to provide monthly allowances?
        <MoreInfoButton triggerChat={() => null} />
      </Styled.SubTitle>
      <InputButtonList
        type={ButtonType.default}
        multiselect={false}
        options={buttonOptions}
        selected={data.allowance}
        onChange={(values) => onChange("allowance", values)}
      />
    </Styled.QuestionWrapper>
  );
}
