import * as SC from "./Styles";
import Icon, { IconNames } from "../../assets/icons/Icons";
import Gia from "../../assets/images/gia";
import { AnimatePresence, motion } from "framer-motion";
import { useGIA } from "../../contexts/GIAContext";
import Flag, { Country } from "../../assets/flags/Flag";
import profilepic from "../../assets/images/camila@2x.png";
import { LiveConnection } from "../EnumsAndTypes/EnumsAndTypes";
import { useState } from "react";
import TalkLiveButton from "../TalkLiveButton/TalkLiveButton";

export default function GIAHeader() {
  const { visible, clickGIA, connected } = useGIA();

  const showLiveAgent = () => {
    if (connected === LiveConnection.Connected || connected === LiveConnection.Disconnecting)
      return true;
    return false;
  };

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  return (
    <div
      style={{ width: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column" }}
    >
      <SC.Header>
        <SC.TitleArea>
          <motion.div variants={variants} initial={"hide"} animate={visible ? "show" : "hide"}>
            <Gia height={28} onClick={() => clickGIA()} />
          </motion.div>
        </SC.TitleArea>
        <TalkLiveButton />
        <SC.ActionsArea>
          <Icon
            name={IconNames.x}
            size={24}
            stroke={1.5}
            pointer={true}
            onClick={() => clickGIA()}
          />
        </SC.ActionsArea>
      </SC.Header>
      <AnimatePresence initial={false}>
        {showLiveAgent() && (
          <SC.InfoGroupHead
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
          >
            <img src={profilepic} width={40} alt={"profile"} />
            <div style={{ flex: 1 }}>
              <SC.Name>Camila Martinez</SC.Name>
              <SC.JobTitle>G-P Expert • Boston</SC.JobTitle>
            </div>
            <Flag country={Country.USA} size={36} />
          </SC.InfoGroupHead>
        )}
      </AnimatePresence>
    </div>
  );
}
