import * as SC from "./Styles";
import { AnimatePresence } from "framer-motion";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useRef, useState, useEffect } from "react";
import { transitions } from "../../assets/tokens/tokens";
import usePageStore from "../../callbacks/usePageStore";
import { Direction } from "../EnumsAndTypes/EnumsAndTypes";
import { useNoMSADrawer } from "../../contexts/NoMSADrawerContext";
import EnterCountry from "./Country";
import Scenarios from "./Scenarios";
import EmployeeCount from "./EmployeeCount";
import GeneratingQuote from "./GeneratingQuote";
import QuoteResult from "./QuoteResult/QuoteResult";
import TimeLine from "./Timeline";
import ConfirmContacts from "./ConfirmContact";

const dataDefault = {
  scenarios: null,
  country: null,
  employees: null,
  timeline: null,
};

export default function AddProfessional() {
  const { quotePageIndex, handleDrawerAction, handlePageChange } = useNoMSADrawer();
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const [direction, setDirection] = useState(Direction.forward);
  const [page, setPage] = useState(quotePageIndex);
  const [pageData, setPageData] = usePageStore(dataDefault);

  useEffect(() => {
    if (quotePageIndex === 5) {
      setDirection(1);
    } else {
      setDirection(quotePageIndex >= page ? 1 : -1); // quick hack cont.
    }
    setPage(quotePageIndex);
  }, [quotePageIndex]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  const setContent = () => {
    if (page === 0)
      return (
        <Scenarios
          data={pageData.scenarios}
          onChange={(data: any) => setPageData({ scenarios: data })}
        />
      );
    if (page === 1)
      return (
        <EnterCountry
          data={pageData.country}
          onChange={(data: any) => setPageData({ country: data })}
        />
      );
    if (page === 2)
      return (
        <EmployeeCount
          data={pageData.employees}
          onChange={(data: any) => setPageData({ employees: data })}
        />
      );
    if (page === 3)
      return (
        <TimeLine
          data={pageData.timeline}
          onChange={(data: any) => setPageData({ timeline: data })}
        />
      );
    if (page === 4) return <GeneratingQuote />;
    if (page === 5) return <ConfirmContacts />;
    if (page === 6) return <QuoteResult />;
    return <div>quote view</div>;
  };

  return (
    <SC.Wrapper ref={ref}>
      <AnimatePresence initial={false} custom={direction}>
        <SC.Content
          custom={direction}
          transition={{ ...transitions.easIn, duration: 0.85 }}
          variants={scrollVariants}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"page_" + page}
        >
          {setContent()}
        </SC.Content>
      </AnimatePresence>
    </SC.Wrapper>
  );
}
