import { useEffect, useRef } from "react";
import useResizeObserver from "../../callbacks/useResizeObserver";
import * as SC from "./Styles";
import GIAHeader from "./GIAHeader";
import GIAFooter from "./GIAFooter";
import { ChatMessage } from "../EnumsAndTypes/EnumsAndTypes";
import GIAMessages from "./GIAMessages";
import { GIAProvider } from "../../contexts/GIAContext";

interface Props {
  width?: number;
  showDrawer?: boolean;
  triggerMessages?: ChatMessage[];
  showMessages?: boolean;
  messages?: ChatMessage[];
  connectLiveAgent?: boolean;
  toggleGIA?: () => void;
  onChangeMessages?: (messages: ChatMessage[]) => void;
  onChangeLiveAgent?: (connected: boolean) => void;
}

export function GIADrawer(props: Props) {
  const {
    width = 400,
    showDrawer = false,
    toggleGIA = () => null,
    showMessages = false,
    triggerMessages = null,
    messages = null,
    connectLiveAgent = false,
    onChangeMessages = () => null,
    onChangeLiveAgent = () => null,
  } = props;

  const ref = useRef(null);
  const size = useResizeObserver(ref);

  useEffect(() => {
    const timer = setTimeout(() => scrollToBottom(), 200);
    return () => clearTimeout(timer);
  }, [size, messages]);

  const GIAVariants = {
    hidden: { right: -width, opacity: 1, width: width },
    visible: { right: 0, opacity: 1, width: width },
  };

  const scrollToBottom = () => {
    if (ref && ref.current) {
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  };

  return (
    <GIAProvider
      showDrawer={showDrawer}
      showMessages={showMessages}
      triggerMessages={triggerMessages}
      toggleGIA={() => toggleGIA()}
      onChangeMessages={(messages: ChatMessage[]) => onChangeMessages(messages)}
      connectLiveAgent={connectLiveAgent}
      onChangeLiveAgent={(connected: boolean) => onChangeLiveAgent(connected)}
    >
      <SC.Wrapper
        $width={width}
        variants={GIAVariants}
        initial={"hidden"}
        transition={{ ease: "easeInOut", duration: 0.25 }}
        animate={showDrawer ? "visible" : "hidden"}
      >
        <GIAHeader />
        <SC.Body ref={ref}>
          <GIAMessages onChange={() => scrollToBottom()} />
        </SC.Body>
        <GIAFooter />
      </SC.Wrapper>
    </GIAProvider>
  );
}

export default GIADrawer;
