import InputField from "../InputField/InputField";
import InputTextArea from "../InputTextArea/InputTextArea";
import * as Styled from "./Styles";
import { useState } from "react";
import InputButton, { ButtonType } from "../InputButton/InputButton";
import { Country } from "../../assets/flags/Flag";
import { IconNames } from "../../assets/icons/Icons";

type Size = {
  height?: number;
  width?: number;
};

interface Props {
  label?: string;
  value?: string;
  errormessage?: string;
  blurred?: boolean;
  control?: "input" | "textarea";
  type?: "text" | "phone" | "email";
  isValid?: boolean;
  fieldname?: string;
  required?: boolean;
  autocomplete?: "on" | "off" | string;
  theme?: "unframed" | "framed" | "white";
  button?: "left" | "right" | "none";
  buttontype?: "area code" | "currency" | "datepicker";
  flag?: Country;
  buttontext?: string;
  onChange?: (value: any) => void;
  didValidate?: (valid: boolean) => void;
  didFocus?: () => void;
  placeholder?: string;
  size?: Size;
}
export default function InputFormField(props: Props) {
  const {
    label = "Label",
    value = "",
    isValid = true,
    control = "input",
    errormessage = "This is an error message",
    blurred = true,
    theme = "framed",
    type = "text",
    fieldname = label,
    required = true,
    placeholder = "",
    flag = Country.Ireland,
    onChange = () => null,
    didValidate = () => null,
    didFocus = () => null,
    autocomplete = "on",
    size = null,
    button = "none",
    buttontype = null,
    buttontext = "+353",
  } = props;
  const [text, setText] = useState(value);
  const [valid, setValid] = useState(isValid);
  const [isBlurred, setIsBlurred] = useState(blurred);

  const handleChange = (value: string) => {
    setText(value);
    onChange(value);
  };

  const handleValidation = (state: boolean) => {
    setValid(state);
    didValidate(state);
  };

  const showButton = (pos: string) => {
    if (button === "none" && !buttontype) return null;
    if (buttontype === "area code" && button === pos)
      return <AreaCodeBtn areacode={buttontext} country={flag} />;
    if (buttontype === "currency" && button === pos)
      return <CurrencyBtn text={"EUR (£)"} country={flag} />;
    if (buttontype === "datepicker" && button === pos) return <DatePicker />;
    return null;
  };

  const renderInput = () => {
    return (
      <Styled.InputWrapper>
        {showButton("left")}
        <InputField
          theme={theme}
          value={value}
          type={type}
          name={fieldname}
          isValid={isValid}
          state={"blurred"}
          required={required}
          autocomplete={autocomplete}
          onChange={(value: string) => handleChange(value)}
          didValidate={(isvalid) => handleValidation(isvalid)}
          didBlur={(state) => setIsBlurred(state)}
          didFocus={() => didFocus()}
        />
        {showButton("right")}
      </Styled.InputWrapper>
    );
  };

  const renderTextArea = () => {
    return (
      <InputTextArea
        placeholder={placeholder}
        size={size}
        value={value}
        name={fieldname}
        isValid={isValid}
        state={"blurred"}
        required={required}
        didValidate={(isvalid) => handleValidation(isvalid)}
        onChange={(value: string) => handleChange(value)}
      />
    );
  };

  return (
    <Styled.FieldContainer>
      <Styled.FieldName>{label}</Styled.FieldName>
      {control === "input" ? renderInput() : renderTextArea()}
      <Styled.ErrorMessage $show={isBlurred && !valid && text !== ""}>
        {errormessage}
      </Styled.ErrorMessage>
    </Styled.FieldContainer>
  );
}

interface CurrencyBtnProps {
  text: string;
  country: Country;
}
const CurrencyBtn = (props: CurrencyBtnProps) => {
  const { text = "EUR", country = Country.Ireland } = props;
  return (
    <InputButton
      type={ButtonType.country}
      country={country}
      labelOverride={true}
      text={text}
      isInput={false}
    />
  );
};

interface AreaCodeBtnProps {
  areacode: string;
  country: Country;
}
const AreaCodeBtn = (props: AreaCodeBtnProps) => {
  const { areacode = "+353", country = Country.Ireland } = props;
  return (
    <InputButton
      type={ButtonType.country}
      icon={IconNames.carretDown}
      country={country}
      labelOverride={true}
      text={areacode}
      isInput={false}
    />
  );
};
const DatePicker = () => {
  return (
    <InputButton
      type={ButtonType.default}
      icon={IconNames.calendar}
      labelOverride={true}
      text={""}
      isInput={false}
    />
  );
};
