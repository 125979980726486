import { AnimatePresence } from "framer-motion";
import * as Styled from "./Styles";

interface Props {
  size?: { height?: number | "auto" | string; width: number | "auto" | string };
  children?: any;
  visible?: boolean;
}

export function Modal(props: Props) {
  const { size = { height: "auto", width: "auto" }, children = null, visible = true } = props;

  return (
    <AnimatePresence initial={true}>
      {visible && (
        <Styled.ModalBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: size.width, height: size.height }}
        >
          {children}
        </Styled.ModalBox>
      )}
    </AnimatePresence>
  );
}
