import * as Styled from "./Styles";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import Flag, { Country } from "../../assets/flags/Flag";
import AcceptLegal from "../AcceptLegal/AcceptLegal";
import NavButton from "../NavButton/NavButton";
import profilepic from "../../assets/images/profilepic2.png";
import { IconNames } from "../../assets/icons/Icons";
import { usePartner } from "../../hooks/usePartner";

export default function ConfirmContacts() {
  const { partnerName } = usePartner();
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Is this contact information current?</Styled.Title>
      <Styled.Description>
        We'll use your {partnerName} user profile for the contact information we need to schedule a
        meeting and/or phone call with a G-P expert.
        <MoreInfoButton triggerChat={() => null} />
      </Styled.Description>

      <Styled.TableItem $isLast={true}>
        <Styled.TableItemHeader>
          <img src={profilepic} width={40} alt={"Janice's profile"} />
          <Styled.Details $isLeft={true}>
            <Styled.Name>Janice Jenkins</Styled.Name>
          </Styled.Details>
          <Styled.Details $isLeft={false}>
            <Styled.Compensation>Acme Inc.</Styled.Compensation>
          </Styled.Details>
        </Styled.TableItemHeader>
        <Styled.LineItem>
          Role:<div>HR</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Email:<div>janicej@acme.com</div>
        </Styled.LineItem>
        <Styled.LineItem>
          Phone:<div>+1 (617) 671-5587</div>
        </Styled.LineItem>
        <Styled.Legal>
          <AcceptLegal variant={"privacy"} />
        </Styled.Legal>
      </Styled.TableItem>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <NavButton
          icon={IconNames.editCard}
          iconpos={"left"}
          iconstyle={{ size: 24, stroke: 1.5 }}
          label={"Modify Contact Info."}
          theme={"link"}
        />
      </div>
    </Styled.QuestionWrapper>
  );
}
